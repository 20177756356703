import { toast, type ToastOptions } from 'react-toastify';

const toastOptions: ToastOptions = {
  theme: 'colored',
};

export const showSuccessMessage = (message: string): void => {
  toast.success(message, toastOptions);
};

export const showErrorMessage = (message: string): void => {
  toast.error(message, toastOptions);
};

export const showInfoMessage = (message: string): void => {
  toast.info(message, toastOptions);
};
