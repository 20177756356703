import { DBButton } from '@components/shared/button/button';
import { GoBackHeader } from '@components/shared/GoBackHeader/GoBackHeader';
import { Application as ApplicationEnum } from '@core/services/models/application';
import { useAuth } from '@hooks/useAuth/useAuth';
import { process, type DataResult, type State } from '@progress/kendo-data-query';
import { type GridDataStateChangeEvent, type GridRowClickEvent } from '@progress/kendo-react-grid';
import { type Application } from '@state/permissionsManagement/models/applicacion';
import { type UserGrid } from '@state/permissionsManagement/models/userGrid';
import { usePermissionsManagement } from '@state/permissionsManagement/usePermissionsManagement';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UsersAssigmentSearch } from './UsersAssignment/UsersAssignment';
import './UserSelector.scss';
import { UsersTable, UsersTableArena, UsersTableJourney } from './UsersTable';

type UserSelectorParams = 'appId';

const userTable = {
  [ApplicationEnum.arena().name.toLowerCase()]: UsersTableArena,
  [ApplicationEnum.journey().name.toLowerCase()]: UsersTableJourney,
};

export const UserSelector: FunctionComponent = observer((): JSX.Element => {
  const { appId } = useParams<UserSelectorParams>();
  const { getUsersGrid, getApp } = usePermissionsManagement();
  const auth = useAuth();
  const navigate = useNavigate();

  const [app, setApp] = useState<Application>();
  const [assignUserOpen, setAssignUserOpen] = useState<boolean>(false);
  const [sub, setSub] = useState<string>();
  const [users, setUsers] = useState<UserGrid[]>([]);
  const [usersResult, setUsersResult] = useState<DataResult>({ data: [], total: 0 });
  const [gridOptions, setGridOptions] = useState<State>({});

  const onGridOptionsChange = (e: GridDataStateChangeEvent): void => {
    setUsersResult(process(users, e.dataState));
    setGridOptions(e.dataState);
  };

  useEffect(() => {
    auth.getUser().then((user) => {
      setSub(user?.profile.sub);
    }, auth.loginRedirect);
  }, []);

  useEffect(() => {
    if (appId) {
      getApp(+appId).then(setApp);
      getUsersGrid(+appId).then((users) => {
        setUsers(users);
        setUsersResult(process(users, {}));
      });
    }
  }, [appId]);

  const userClick = (e: GridRowClickEvent): void => {
    const selectedSub: string = e.dataItem.sub;
    if (appId && sub !== selectedSub) {
      navigate(`/permissionsManagement/apps/${appId}/users/${selectedSub}/permissions`);
    }
  };

  const getUsersTable = (): JSX.Element => {
    const appName = app ? app.name.toLowerCase() : '';
    const Table = userTable[appName] ?? UsersTable;
    return (
      <Table
        users={usersResult}
        userClick={userClick}
        onGridOptionsChange={onGridOptionsChange}
        gridOptions={gridOptions}
      ></Table>
    );
  };

  return (
    <>
      <GoBackHeader title={'All applications'} goBackLink={true} />
      {app && (
        <div className="app-selected">
          <div className="app-selected__row">
            <div className="app-selected__cell">
              <div className="app-selected__cell-img-container">
                {app.imageUrl ? (
                  <div
                    className="app-selected__cell-img-uploaded"
                    style={{ backgroundImage: 'url(' + app.imageUrl + ')' }}
                  ></div>
                ) : (
                  <div className="app-selected__cell-img"></div>
                )}
              </div>
              <p>{app.name}</p>
            </div>
            <div className="app-selected__cell">
              <p className="app-selected__cell-text">{app.description}</p>
            </div>
          </div>
        </div>
      )}
      <div className="app-selected__actions">
        <DBButton
          type="button"
          name="withIcon"
          iconName="add"
          text="Assign new User"
          onClick={() => {
            setAssignUserOpen(true);
          }}
        ></DBButton>
      </div>
      {getUsersTable()}
      {assignUserOpen && appId ? (
        <UsersAssigmentSearch
          appId={+appId}
          onClose={() => {
            setAssignUserOpen(false);
          }}
        />
      ) : null}
    </>
  );
});
