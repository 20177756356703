import { useEffect, useState } from 'react';
import i18n, { resources } from '../../I18n';

export const useLanguage = () => {
  const languages: Array<keyof typeof resources> = Object.keys(resources) as any;
  const [language, setLanguage] = useState<string>(i18n.resolvedLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return {
    currentLanguage: language,
    languages,
    setLanguage,
  };
};
