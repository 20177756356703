import { ai, getAppInsights } from '@core/services/telemetryService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Component, Fragment, type PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  instrumentationKey?: string;
}

class TelemetryProvider extends Component<IProps> {
  state = {
    initialized: false,
    hasError: false,
  };

  componentDidMount() {
    const { initialized } = this.state;

    if (this.props.instrumentationKey) {
      const AppInsightsInstrumentationKey = this.props.instrumentationKey;
      if (!initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
        ai.initialize(AppInsightsInstrumentationKey);
        this.setState({ initialized: true });
      }
    }
  }

  componentDidCatch(error: Error) {
    const appInsights = getAppInsights();
    appInsights.trackException({ error });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please, reload the page.</h1>;
    } else {
      const { children } = this.props;
      return <Fragment>{children}</Fragment>;
    }
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);
