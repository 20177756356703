import arcaBox from '@assets/images/arca.png';
import arenaBox from '@assets/images/arena.png';
import datacampusBox from '@assets/images/datacampus.png';
import journeyBox from '@assets/images/journey.png';
import { AppConfiguration } from '@core/config/appConfiguration';
import { type Application as ApplicationModel } from '@state/permissionsManagement/models/applicacion';
import { type resources } from 'I18n';

export enum ApplicationIds {
  DataCampus = 1,
  Journey = 2,
  Arca = 3,
  Arena = 4,
}

export type ApplicationName = keyof (typeof resources)['en_US']['translation']['Home']['Applications'];

export class Application {
  id: ApplicationIds;
  name: ApplicationName;
  image: string;
  acronym: string;
  url: string;

  constructor(id: number, name: ApplicationName, acronym: string, image: string, url: string) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.acronym = acronym;
    this.url = url;
  }

  toApplicationModel(): ApplicationModel {
    return {
      id: this.id,
      name: this.name,
      description: '',
      imageUrl: this.image,
    };
  }

  static dataCampus(): Application {
    return new Application(ApplicationIds.DataCampus, 'DataCampus', 'DC', datacampusBox, '');
  }

  static journey(): Application {
    return new Application(
      ApplicationIds.Journey,
      'Journey',
      'JR',
      journeyBox,
      AppConfiguration.getInstance().settings.apps.journeyUrl,
    );
  }

  static arca(): Application {
    return new Application(
      ApplicationIds.Arca,
      'ArcaWeb',
      'AC',
      arcaBox,
      AppConfiguration.getInstance().settings.apps.arcaUrl,
    );
  }

  static arena(): Application {
    return new Application(
      ApplicationIds.Arena,
      'Arena',
      'AR',
      arenaBox,
      AppConfiguration.getInstance().settings.apps.arenaUrl,
    );
  }

  get formattedName(): string {
    return this.name.split(' ').join('').toLowerCase();
  }

  static getList(): Application[] {
    return [this.dataCampus(), this.journey(), this.arca(), this.arena()];
  }

  static getById(id: number): Application | undefined {
    return this.getList().find((c) => c.id === id);
  }

  static getByName(name: string): Application | undefined {
    return this.getList().find((c) => c.name === name);
  }
}
