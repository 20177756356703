import { type BlockMetadata } from '@state/block/models/blockMetadata';
import { type MapHeatData } from '@state/block/models/mapHeatData';
import { type TreeMapData } from '@state/block/models/treeMapData';
import { mapBrowsersListMapper } from './mappers/browserListMapper';
import { mapPageViewToTreeMap } from './mappers/pageViewsMapper';
import { mapSessionsByCountryToHeatMap } from './mappers/sessionsByCountryToHeatMapper';
import { mapTotalSessions } from './mappers/totalSessionsMapper';
import { type TotalSessionsModel } from './models/totalSessionsModel';
import { TelemetryArenaClient } from './services';

class AnalyticsArenaService {
  private readonly analyticsClient: TelemetryArenaClient;
  constructor() {
    this.analyticsClient = new TelemetryArenaClient();
  }

  async getTotalSessions(): Promise<TotalSessionsModel> {
    const response = await this.analyticsClient.getsessionsbytime();
    return mapTotalSessions(response);
  }

  async getTotalSessionsArena(): Promise<TotalSessionsModel> {
    const response = await this.analyticsClient.getsessionsbytime();
    return mapTotalSessions(response);
  }

  async getCountByCountry(): Promise<MapHeatData[]> {
    const response = await this.analyticsClient.getcountbycountry();
    return mapSessionsByCountryToHeatMap(response);
  }

  async getTopPageViews(): Promise<TreeMapData[]> {
    const response = await this.analyticsClient.gettoppageviews();
    return mapPageViewToTreeMap(response, 2);
  }

  async getListbrowsers(): Promise<BlockMetadata[]> {
    const response = await this.analyticsClient.gettopbrowsers();
    return mapBrowsersListMapper(response);
  }

  async getAverageSessionTime(): Promise<string> {
    const response = await this.analyticsClient.getaveragetimebysessions();
    return response;
  }
}
export const analyticsService = new AnalyticsArenaService();
