import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import './complexChart.scss';
import { type FunctionComponent, useEffect, useState } from 'react';
import { formatNumber } from '../../../utils/numberFormatter';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export interface ComplexItem {
  name: string;
  value: number;
  color: string;
}
export interface IComplexChartProps {
  data: ComplexItem[];
  isModal?: boolean;
}

const ComplexChart: FunctionComponent<IComplexChartProps> = ({ data, isModal }: IComplexChartProps) => {
  const [color, setColor] = useState(data?.length > 0 ? data[0].color : '');
  const [name, setName] = useState(data?.length > 0 ? data[0].name : '');
  const [value, setValue] = useState(data?.length > 0 ? data[0].value : undefined);

  useEffect(() => {
    setColor(data?.length > 0 ? data[0].color : '');
    setName(data?.length > 0 ? data[0].name : '');
    setValue(data?.length > 0 ? data[0].value : undefined);
  }, [data]);

  const onPointSelected = (name: string, color: string, value: number) => {
    setName(name);
    setColor(color);
    setValue(value);
  };

  const ringsWidth = 15;
  const ringsSpace = 2;
  const options = {
    exporting: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
      height: isModal ? 500 : 325,
      renderTo: 'container',
      type: 'solidgauge',
    },
    credits: {
      enabled: false,
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: data.map((_, index) => {
        return {
          outerRadius: `${112 - index * ringsWidth}%`,
          innerRadius: `${112 - ((index + 1) * ringsWidth - ringsSpace)}%`,
          backgroundColor: 'transparent',
          borderWidth: 0,
        };
      }),
    },
    title: null,
    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        events: {
          click: (events: Any) => {
            onPointSelected(events.point.series.name, events.point.color, events.point.series.yData[0]);
          },
          mouseOver: (events: Any) => {
            onPointSelected(events.target.getName(), events.target.points[0].color, events.target.yData[0]);
          },
        },
        size: '100%',
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
    series: data.map((item, index) => {
      return {
        name: item.name,
        data: [
          {
            color: item.color,
            radius: `${112 - index * ringsWidth}%`,
            innerRadius: `${112 - ((index + 1) * ringsWidth - ringsSpace)}%`,
            y: item.value * 100,
          },
        ],
      };
    }),
  };

  return (
    <div className="global-chart-wrapper">
      {name && (
        <h4 className="globalChartLabel">
          <span style={{ color }}>{name}: </span>
          {value && `${formatNumber(+value.toFixed(2))}%`}
        </h4>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export { ComplexChart };
