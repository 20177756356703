import { type FunctionComponent } from 'react';
import './AlphanumericChart.scss';

interface AlphanumericChartProps {
  data: string;
}

export const AlphanumericChart: FunctionComponent<AlphanumericChartProps> = ({ data }) => (
  <div className="alphanumeric-chart">
    <pre className="alphanumeric-chart__value">{data}</pre>
  </div>
);
