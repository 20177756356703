import React from 'react';
import './WorldMap.scss';

interface IWorldMapProps {
  foreignerTravelers: number;
  nationalTravelers: number;
}

export const WorldMap: React.FC = () => {
  const travellers: IWorldMapProps = {
    foreignerTravelers: 78,
    nationalTravelers: 49,
  };
  return (
    <div className="worldMap-container">
      <div className="worldMap__wrapper"></div>
      <div className="worldMap__info">
        <div className="worldMap__info--title">
          <p>
            <span>{travellers.foreignerTravelers}</span> Foreigner travelers /{' '}
            <span>{travellers.nationalTravelers}</span> National travelers
          </p>
        </div>
        <div className="worldMap__info--description">Traveling From/To risky countries</div>
        <a className="worldMap__info--detail">View detail</a>
      </div>
    </div>
  );
};
