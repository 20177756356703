import {
  type DataModel,
  FilterOptions,
  type Filters,
  type FilterKey,
} from '@components/dashboard/shared/filterOptions/FilterOptions';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  type SeriesClickEvent,
} from '@progress/kendo-react-charts';
import { type BlockMetadata, type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import { ChartType } from '@state/block/models/chartType';
import { type Widget } from '@state/block/models/widget';
import { type FunctionComponent, useEffect, useState } from 'react';
import './BarChartWithOptions.scss';

const color = '#0166FF';

const valueAxisLabels = {
  font: '11px Telefonica Web Regular, Arial, sans-serif',
};

const valueLegendLabels = {
  font: '13px Telefonica Web Regular, Arial, sans-serif',
  margin: 5,
};
export interface Props {
  onSeriesClick?: (event: SeriesClickEvent) => void;
  data: MultipleBlockMetadata[];
  widget: Widget<Any> | undefined;
}

export const BarChartWithOptions: FunctionComponent<Props> = ({ onSeriesClick, data, widget }) => {
  const [selectedFilter, setSelectedFilter] = useState<Filters>({ filter: '', subfilter: '' });
  const [selectedData, setSelectedData] = useState<BlockMetadata>();
  const [filterData, setFilterData] = useState<DataModel[]>([]);

  useEffect(() => {
    setFilterData(
      data.map((d) => ({
        name: d.option,
        value: d.option,
        subfilter: d.data.map((bd) => ({ name: bd.name as FilterKey, value: bd.name })),
      })),
    );
    setSelectedFilter({ filter: data[0].option, subfilter: data[0].data[0].name });
  }, [data]);

  useEffect(() => {
    const dataGroupSelected = data.find((Item) => Item.option === selectedFilter.filter);

    if (!dataGroupSelected) return;
    const dataSelected = dataGroupSelected.data.find((Item) => Item.name === selectedFilter.subfilter);
    if (!dataSelected) return;
    setSelectedData(dataSelected);
  }, [selectedFilter]);

  const onChange = (event: Filters): void => {
    setSelectedFilter({ filter: event.filter, subfilter: event.subfilter });
  };

  return selectedData ? (
    <div className="barChartWithOptions-container">
      <FilterOptions data={filterData} value={selectedFilter} onChange={onChange} />
      <Chart
        zoomable={false}
        onSeriesClick={onSeriesClick}
        style={{ width: '100%', height: 'calc(100% - 50px)', background: 'transparent' }}
      >
        <ChartLegend align="start" position="left" orientation="vertical" labels={valueLegendLabels} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={selectedData.data.map((item) => item.name)}
            labels={{
              rotation:
                widget?.chartType.id === ChartType.verticalBarChart().id && selectedData.data.length > 8 ? -35 : 0,
            }}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem labels={valueAxisLabels} />
        </ChartValueAxis>
        <ChartTooltip shared={true} background='#000000' color='#FFFFFF'/>
        <ChartSeries>
          <ChartSeriesItem
            gap={2}
            color={color}
            stack={{ type: 'normal' }}
            type={widget?.chartType.id === ChartType.verticalBarChart().id ? 'column' : 'bar'}
            data={selectedData.data.map((d: Any) => d.value)}
          ></ChartSeriesItem>
        </ChartSeries>
      </Chart>
    </div>
  ) : null;
};
