import { type CountryModel } from './models/countryModel';
import { JourneyClient } from './services';
export class JourneyService {
  async getCountries(): Promise<CountryModel[]> {
    return (await journeyClient.countries()).map((r) => ({ id: r.id, name: r.name, code: r.iso }));
  }
}

const journeyClient = new JourneyClient();
export const journeyService = new JourneyService();
