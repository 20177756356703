import { DropdownMenu } from '@components/shared/dropdown-menu/dropdown-menu';
import { Icon } from '@components/shared/Icon/Icon';
import { LoaderSmall } from '@components/shared/LoaderSmall/LoaderSmall';
import { Modal } from '@components/shared/Modal/Modal';
import { ModalDialog } from '@components/shared/ModalDialog/ModalDialog';
import { Application } from '@core/services/models/application';
import { useStores } from '@hooks/useStores/useStores';
import { type Block } from '@state/block/models/block';
import { ChartType } from '@state/block/models/chartType';
import { Widget } from '@state/block/models/widget';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './BlockContent.scss';
import { ComplexChart } from './complexChart/complexChart';
import { FeaturedRoute } from './featuredRoute/featuredRoute';
import { GeneralRow } from './generalRowValues/generalRowValues';
import { MapChart } from './mapChart/MapChart';
import { WorldMap } from './mapChart/worldMap/WorldMap';
import { AlphanumericChart } from './simpleChart/alphanumericChart/AlphanumericChart';
import { BarChart } from './simpleChart/barChart/BarChart';
import { BarChartPercentage } from './simpleChart/barChartPercentage/BarChartPercentage';
import { BarChartWithOptions } from './simpleChart/barChartWithOptions/BarChartWithOptions';
import { DonutChart } from './simpleChart/donutChart/DonutChart';
import { Sparkline } from './simpleChart/sparkLine/Sparkline';
import { TableReports } from './tableReports/tableReports';
import { TableRisks } from './tableRisks/tableRisks';

const chartGroupTypes = {
  [ChartType.sparkline().id]: Sparkline,
  [ChartType.multipleBar().id]: BarChart,
  [ChartType.bubble().id]: MapChart,
  [ChartType.heat().id]: MapChart,
  [ChartType.generalRow().id]: GeneralRow,
  [ChartType.tableReport().id]: TableReports,
  [ChartType.tableRisks().id]: TableRisks,
  [ChartType.barChart().id]: BarChartWithOptions,
  [ChartType.featuredRoute().id]: FeaturedRoute,
  [ChartType.donutChart().id]: DonutChart,
  [ChartType.worldMap().id]: WorldMap,
  [ChartType.barPercentage().id]: BarChartPercentage,
  [ChartType.verticalBarChart().id]: BarChartWithOptions,
  [ChartType.complexChart().id]: ComplexChart,
  [ChartType.alphanumericChart().id]: AlphanumericChart,
};

interface Props {
  block: Block;
  onUpdate: () => void;
}

const generateYearsBetween = (startDate: Date, endDate: Date): number[] => {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

export const BlockContent: FunctionComponent<Props> = ({ block, onUpdate }) => {
  const { t } = useTranslation('translation');
  const [itemIdToDelete, setItemIdToDelete] = useState<number>();
  const [itemIdToExpand, setItemIdToExpand] = useState<number>();
  const [widget, setWidget] = useState<Widget<Any>>();
  const [data, setData] = useState<Any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { dashboardStore } = useStores();

  useEffect(() => {
    setWidget(Widget.getById(block.widgetId));
  }, []);

  useEffect(() => {
    loadData();
  }, [widget, block?.countryIds, block?.startDate, block?.endDate]);

  const loadData = async (): Promise<void> => {
    setIsLoading(true);
    if (widget?.dataType?.apiCall) {
      widget.dataType
        .apiCall({ body: getRequestParameters() })
        .then((result: Any) => {
          if (widget.mapCall) {
            setData(widget.mapCall(result));
          } else {
            setData(result);
          }
        })
        .catch(console.log)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const getRequestParameters = (): unknown => {
    if (widget?.dataType.application.id === Application.arena().id) {
      return { countries: block.countryIds, startDate: block.startDate, endDate: block.endDate };
    } else if (widget?.dataType.application.id === Application.journey().id) {
      return { countries: block.countryIds, years: generateYearsBetween(block.startDate, block.endDate) };
    } else if (widget?.dataType.application.id === Application.arca().id) {
      return { workstreamId: block.workstreamId };
    }
  };

  const getChart = (): JSX.Element => {
    const Chart = chartGroupTypes[widget ? widget.chartType.id : 1];
    return <Chart data={data} widget={widget} chartType={widget?.chartType as ChartType} />;
  };
  const showDeleteDialog = (): void => {
    setItemIdToDelete(block.id);
  };
  const closeDeleteDialog = (): void => {
    setItemIdToDelete(undefined);
  };
  const showExpandDialog = (): void => {
    setItemIdToExpand(block.id);
  };
  const closeExpandDialog = (): void => {
    setItemIdToExpand(undefined);
  };
  const onDelete = (): void => {
    void dashboardStore.deleteBlock(block);
  };

  return (
    <div className="block__container">
      <header className={`block__header ${widget ? widget.dataType.application.name.toLowerCase() : ''}`.trim()}>
        <div className="block__title-container">
          <span
            className={`block__source-title ${widget ? widget.dataType.application.name.toLowerCase() : ''}`.trim()}
          >
            {widget?.dataType.application.name}
          </span>
          <span className="separator">-</span>
          <span className="block__title">{block.title}</span>
        </div>

        <div className="block__icons">
          {!isLoading && <Icon iconName="expand" onClick={showExpandDialog}></Icon>}
          <DropdownMenu
            classes={{
              menuClassName: 'data-insured__dropdown',
            }}
            renderToggle={() => <Icon iconName="dots"></Icon>}
          >
            <ul className="dropdown-menu__list">
              <li onClick={onUpdate} className="dropdown-menu__list-item">
                <Icon iconName="edit"></Icon>
                <span className="dropdown-menu__list-item-text">{t('DropdownMenu.Edit')}</span>
              </li>
              <li onClick={showDeleteDialog} className="dropdown-menu__list-item">
                <Icon iconName="trash"></Icon>
                <span className="dropdown-menu__list-item-text">{t('DropdownMenu.Delete')}</span>
              </li>
            </ul>
          </DropdownMenu>
        </div>
      </header>
      <div className="block__body">{isLoading ? <LoaderSmall></LoaderSmall> : data ? getChart() : null}</div>
      <ModalDialog
        acceptText={t('ModalDialog.YesDelete')}
        cancelText={t('ModalDialog.Cancel')}
        onAccept={onDelete}
        onCancel={closeDeleteDialog}
        visible={itemIdToDelete !== undefined}
        title={t('Dashboard.DeleteWidgetTitle')}
        message={t('Dashboard.DeleteWidgetMessage')}
        icon="delete"
      ></ModalDialog>
      <Modal visible={itemIdToExpand !== undefined} onCancel={closeExpandDialog} block={block} data={data}></Modal>
    </div>
  );
};
