import { type MapHeatData } from '@state/block/models/mapHeatData';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts/highmaps';
import { type FunctionComponent } from 'react';

interface Props {
  options: Highcharts.Options;
  data: MapHeatData[];
}

export const HeatMap: FunctionComponent<Props> = ({ options, data }) => {
  const generateOptions = (): Any => {
    return {
      ...options,
      series: [
        {
          name: 'Countries',
          joinBy: ['iso-a2', 'code'],
          data,
        },
      ],
      tooltip: {
        pointFormat: '{point.name}: {point.text}<br/>',
      },
      colorAxis: {
        min: Math.min.apply(
          null,
          data.map((d) => d.value),
        ),
        max: Math.max.apply(
          null,
          data.map((d) => d.value),
        ),
        type: 'linear',
        stops: [
          [0.2, '#a7d9e8'],
          [0.4, '#90cfa5'],
          [0.6, '#e4b974'],
          [0.8, '#e68c64'],
          [1, '#e86262'],
        ],
      },
      legend: {
        enabled: true,
        title: {
          text: '',
          style: {
            color: 'white',
          },
        },
      },
    };
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} constructorType={'mapChart'} options={generateOptions()} />
    </>
  );
};
