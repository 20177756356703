import { type FunctionComponent, type PropsWithChildren } from 'react';
import { Icon } from '@components/shared/Icon/Icon';
import './AnalyticsChartModal.scss';
import { Dialog } from '@progress/kendo-react-dialogs';

export interface Props {
  visible: boolean;
  applicationName: string;
  title: string;
  onCancel?: () => void;
}

export const AnalyticsChartModal: FunctionComponent<PropsWithChildren<Props>> = ({
  visible,
  children,
  applicationName,
  title,
  onCancel,
}) => {
  return visible ? (
    <Dialog className="db-dialog-charts">
      <div className="db-modal-dialog__header">
        <h2 className="db-modal-title">
          {applicationName} - {title}
        </h2>
        <Icon iconName="close" onClick={onCancel}></Icon>
      </div>
      <div className="db-modal__body">{children}</div>
    </Dialog>
  ) : null;
};
