import { Icon } from '@components/shared/Icon/Icon';
import { type Application } from '@core/services/models/application';
import { useState, type FunctionComponent, type PropsWithChildren } from 'react';
import { AnalyticsChartModal } from '../AnalyticsChartModal/AnalyticsChartModal';
import './AnalyticsChart.scss';

interface Props {
  title: string;
  application: Application;
}

export const AnalyticsChart: FunctionComponent<PropsWithChildren<Props>> = ({ application, title, children }) => {
  const [expandedItem, setExpandedItem] = useState<boolean>();

  const showExpandDialog = (): void => {
    setExpandedItem(true);
  };

  const closeExpandDialog = (): void => {
    setExpandedItem(undefined);
  };

  return (
    <div className={'chart'}>
      <header className={'header ' + application.name.toLowerCase()}>
        <div className="title">
          <span className={'source ' + application.name.toLowerCase()}>{application.name}</span>
          <span className="separator">-</span>
          <span className="block__title">{title}</span>
        </div>
        <Icon iconName="expand" onClick={showExpandDialog}></Icon>
      </header>
      <div className="chart__data">{children}</div>
      <AnalyticsChartModal
        visible={!!expandedItem}
        onCancel={closeExpandDialog}
        applicationName={application.name}
        title={title}
      >
        {children}
      </AnalyticsChartModal>
    </div>
  );
};
