import { showSuccessMessage } from './../../utils/toastMessage';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { type DashboardsService } from './../../core/services/dashboardService';
import { type Block } from './models/block';
import { type Dashboard } from './models/dashboard';

export class DashboardStore {
  dashboard: Dashboard = {} as Dashboard;

  get getBlocks(): Block[] {
    return this.dashboard.blocks;
  }

  constructor(private readonly dashboardClient: DashboardsService) {
    makeObservable(this, {
      dashboard: observable,
      getBlocks: computed,
      loadDashboard: action,
      deleteDashboard: action,
      addBlock: action,
      updateBlock: action,
      deleteBlock: action,
    });
  }

  async loadDashboard(id: number): Promise<void> {
    this.dashboard = {} as Dashboard;
    await this.dashboardClient.getById(id).then((data) => {
      runInAction(() => {
        this.dashboard = data;
      });
    });
  }

  async deleteDashboard(): Promise<void> {
    await this.dashboardClient.deleteDashboard(this.dashboard.id);
    showSuccessMessage('Dashboard has been deleted succesfully');
    runInAction(() => {
      this.dashboard = {} as Dashboard;
    });
  }

  async addBlock(block: Block): Promise<void> {
    await this.dashboardClient.addBlock(block).then((data) => {
      showSuccessMessage('Block has been added succesfully');
      runInAction(() => {
        this.dashboard.blocks.push(data);
        this.sortBlocksOnCreate(data);
      });
    });
  }

  async updateBlock(block: Block): Promise<void> {
    await this.dashboardClient.updateBlock(block).then((data) => {
      showSuccessMessage('Block has been updated succesfully');
      runInAction(() => {
        this.sortBlocksOnUpdate(data);
      });
    });
  }

  async deleteBlock(block: Block): Promise<void> {
    await this.dashboardClient.deleteBlock(this.dashboard, block).then(() => {
      showSuccessMessage('Block has been deleted succesfully');
      runInAction(() => {
        let blocksCopy = this.dashboard.blocks.filter((b) => b.id !== block.id);

        blocksCopy = blocksCopy.map((b) => {
          if (b.order > block.order) {
            b.order = b.order - 1;
          }

          return b;
        });

        this.dashboard.blocks = [...blocksCopy];
      });
    });
  }

  private sortBlocksOnUpdate(blockToUpdate: Block): void {
    const blocksCopy = [...this.dashboard.blocks];
    const block: Block = blocksCopy.find((b) => b.id === blockToUpdate.id) as Block;
    if (block.order > blockToUpdate.order) {
      let nextOrder = blockToUpdate.order;

      blocksCopy.forEach((b) => {
        if (b.order >= blockToUpdate.order && b.id !== block?.id) {
          nextOrder++;
          b.order = nextOrder;
        }
        return b;
      });
    } else if (block.order < blockToUpdate.order && block.id) {
      blocksCopy.forEach((b) => {
        if (b.order > block.order && b.order <= blockToUpdate.order) {
          b.order = b.order - 1;
        }

        return b;
      });
    }
    const blockIndex = blocksCopy.findIndex((b) => b.id === blockToUpdate.id);
    blocksCopy[blockIndex] = blockToUpdate;
    blocksCopy.sort((a, b) => a.order - b.order);
    this.dashboard.blocks = [...blocksCopy];
  }

  private sortBlocksOnCreate(block: Block): void {
    const blocksCopy = [...this.dashboard.blocks];
    let nextOrder = block.order;

    blocksCopy.forEach((b) => {
      if (b.order >= block.order && b.id !== block?.id) {
        nextOrder++;
        b.order = nextOrder;
      }
      return b;
    });

    blocksCopy.sort((a, b) => a.order - b.order);
    this.dashboard.blocks = [...blocksCopy];
  }
}
