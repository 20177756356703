import { observer } from 'mobx-react-lite';
import { type FunctionComponent, type MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../Icon/Icon';
import './GoBackHeader.scss';

interface GoBackHeaderProps {
  goBackLink?: boolean;
  goBackCustomUrl?: string;
  title: string;
}

export const GoBackHeader: FunctionComponent<GoBackHeaderProps> = observer((props: GoBackHeaderProps) => {
  const navigate = useNavigate();
  const onClickFunction: MouseEventHandler<HTMLDivElement> = () => {
    if (props.goBackLink) {
      props.goBackCustomUrl ? navigate(props.goBackCustomUrl) : navigate(-1);
    }
  };

  return (
    <div className="go-back-header" onClick={onClickFunction}>
      {props.goBackLink ? <Icon iconName="arrow-left"></Icon> : null}
      <span className="go-back-header__title">{props.title}</span>
    </div>
  );
});
