import { type UpdateRssModel } from '../models/updateRssModel';
import { type Telefonica_DGSI_Dashboard_Api_UseCases_Rss_Update_UpdateRssModel } from '../services';

export const mapUpdateRssModel = (
  response: UpdateRssModel,
): Telefonica_DGSI_Dashboard_Api_UseCases_Rss_Update_UpdateRssModel => {
  return {
    rssSizeId: response.rssSizeId,
    rssTypeId: response.rssTypeId,
    order: response.order,
    autoPlayInterval: response.autoPlayInterval,
  };
};
