import { DashboardsService } from '@core/services';
import { DashboardsClient } from '@core/services/services';
import { DashboardStore } from './block/dashboardStore';
import { TasksStore } from './tasks/tasksStore';

export const createStores = () => ({
  dashboardStore: new DashboardStore(new DashboardsService(new DashboardsClient())),
  tasksStore: new TasksStore(),
});

export type TStore = ReturnType<typeof createStores>;
