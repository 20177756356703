export interface Settings {
  apiUrl: string;
  baleaApiUrl: string;
  appInsightKey: string;
  oidc: {
    authority: string;
    clientId: string;
    clientSecret: string;
    clientRoot: string;
    clientScope: string;
    activityTime: number;
  };
  apps: {
    arcaUrl: string;
    arenaUrl: string;
    journeyUrl: string;
    dataCampusUrl: string;
  };
  social: {
    twitter: string;
  };
}

export class AppConfiguration {
  private static instance: AppConfiguration;
  settings: Settings = {
    apiUrl: '',
    baleaApiUrl: '',
    appInsightKey: '',
    oidc: { authority: '', clientId: '', clientSecret: '', clientRoot: '', clientScope: '', activityTime: 0 },
    apps: { arcaUrl: '', arenaUrl: '', journeyUrl: '', dataCampusUrl: '' },
    social:{twitter: ''}
  };

  use(settings: Settings): void {
    this.settings = settings;
  }

  static getInstance(): AppConfiguration {
    this.instance = this.instance || new AppConfiguration();
    return this.instance;
  }
}
