import { Icon } from '@components/shared/Icon/Icon';
import { useTranslation } from 'react-i18next';

export interface FlightWidgetProps {
  link?: string;
  description: string;
  route: string;
  passengers: number;
}

export const FlightWidget = ({ link, description, passengers, route }: FlightWidgetProps): JSX.Element => {
  const { t } = useTranslation('translation');

  return (
    <div className="metric-route">
      <p className="metric-route__description">{description}</p>
      {link && (
        <a href={link} className="metric-route__link">
          {t('Home.View')}
        </a>
      )}
      <div className="metric-route__info">
        <Icon iconName="plane"></Icon>
        <p className="metric-route__info--value">{route}</p>
      </div>
      <div className="metric-route__info">
        <Icon iconName="users"></Icon>
        <p className="metric-route__info--value">({passengers})</p>
      </div>
    </div>
  );
};
