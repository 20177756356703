import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { type FunctionComponent } from 'react';

interface Props {
  options: Highcharts.Options;
}

export const BubbleMap: FunctionComponent<Props> = ({ options }) => {
  const generateOptions = (): any => {
    // const data = value.map((element: any) => {
    //   return {
    //     code: element.code,
    //     z: parseInt(element.value),
    //     tagValue: element.valueFmt,
    //     countryName: element.reference,
    //   };
    // });
    const data = [
      { code: 'BR', z: 0, tagValue: '0.67', countryName: 'Brasil' },
      { code: 'AR', z: 0, tagValue: '0.11', countryName: 'Argentina' },
      { code: 'CL', z: 0, tagValue: '0.44', countryName: 'Chile' },
      { code: 'ES', z: 0, tagValue: '0.26', countryName: 'España' },
    ];

    return {
      ...options,
      colors: ['#12a592', '#f6a821'],
      series: [
        {
          name: 'Countries',
          nullColor: '#747c85',
          enableMouseTracking: false,
          borderColor: 'transparent',
          joinBy: ['iso-a2', 'code'],
          data,
        },
        {
          type: 'mapbubble',
          joinBy: ['iso-a2', 'code'],
          data,
          minSize: 8,
          maxSize: '8%',
        },
      ],
    };
  };

  return <HighchartsReact highcharts={Highcharts} constructorType={'mapChart'} options={generateOptions()} />;
};
