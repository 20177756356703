import { orderBy } from 'lodash';
import { type AlertModel } from '../models/alertModel';
import { type Telefonica_DGSI_Dashboard_Domain_Queries_Alerts_Response_AlertResponse } from '../services';
export const AlertMapper = {
  map(response: Telefonica_DGSI_Dashboard_Domain_Queries_Alerts_Response_AlertResponse[]): AlertModel[] {
    const alerts: AlertModel[] = response.map((item) => ({
      id: item.id,
      title: item.title,
      message: item.message,
      section: item.section,
      showDate: item.showDate,
      url: item.url,
      platform: item.platform,
      read: item.read,
      taskType: item.taskType,
      metaData: item.metaData,
      metaDataJson: item.metaDataJson,
    }));

    return orderBy(alerts, (item) => item.read);
  },
};
