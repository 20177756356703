import React from 'react';
import { storesContext } from '../../contexts/storesContext';

export const useStores = () => {
  const stores = React.useContext(storesContext);
  if (stores == null) {
    throw new Error('useStores must be used within a StoreProvider.');
  }
  return stores;
};
