import { Application } from '../../../core/services/models/application';
import i18n from '../../../I18n';
import { ArcaClient, ArenaClient, JourneyClient } from './../../../core/services/services';

const UseDate = {
  withDate: true,
  noDate: false,
};

export class DataType {
  id: number;
  name: string;
  application: Application;
  useDate: boolean;
  apiCall: (parameters: Any | null) => Promise<Any>;
  constructor(
    id: number,
    name: string,
    application: Application,
    useDate: boolean,
    apiCall: (parameters: Any | null) => Any,
  ) {
    this.id = id;
    this.name = name;
    this.application = application;
    this.useDate = useDate;
    this.apiCall = apiCall;
  }

  static passengersToMonthsByYear(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      1,
      i18n.t('Dashboard.Form.PassengersByMonthAndYear'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.totalpassengerstomonthsbyyear,
    );
  }

  static internationalDepartureFlights(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      2,
      i18n.t('Dashboard.Form.InternationalDepartureFlights'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.internationaldepartureflights,
    );
  }

  static internationalDeparturesByCountry(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      3,
      i18n.t('Dashboard.Form.InternationalDeparturesFlightsByCountry'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.internationaldeparturesbycountry,
    );
  }

  static internationalArrivalsByCountry(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      17,
      i18n.t('Dashboard.Form.InternationalArrivalsFlightsByCountry'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.internationalarrivalsbycountry,
    );
  }

  static nationalAndInternationalFlights(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      4,
      i18n.t('Dashboard.Form.NationalAndInternationalFlights'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.nationalandinternationalflights,
    );
  }

  static nationalAndInternationalPassengers(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      5,
      i18n.t('Dashboard.Form.PassengersInNationalAndInternationalFlights'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.nationalandinternationalpassengers,
    );
  }

  static passengersByflightType(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      6,
      i18n.t('Dashboard.Form.PassengersByFlightType'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.passengersbyflighttype,
    );
  }

  static domesticFlightsAndPassengers(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      7,
      i18n.t('Dashboard.Form.DomesticFlightsAndPassengers'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.domesticflightsandpassengers,
    );
  }

  static domesticFlights(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      8,
      i18n.t('Dashboard.Form.DomesticFlights'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.domesticflights,
    );
  }

  static domesticFlightsComparison(): DataType {
    const jorneyClient = new JourneyClient();

    return new DataType(
      9,
      i18n.t('Dashboard.Form.DomesticFlightsComparison'),
      Application.journey(),
      UseDate.withDate,
      jorneyClient.domesticflightscomparison,
    );
  }

  static postsByCountry(): DataType {
    const arenaClient = new ArenaClient();

    return new DataType(
      10,
      i18n.t('Dashboard.Form.PostsByCountry'),
      Application.arena(),
      UseDate.withDate,
      arenaClient.postsbycountry,
    );
  }

  static postsByProvider(): DataType {
    const arenaClient = new ArenaClient();

    return new DataType(
      11,
      i18n.t('Dashboard.Form.PostsByProvider'),
      Application.arena(),
      UseDate.withDate,
      arenaClient.postsbyprovider,
    );
  }

  static postsByPostProfile(): DataType {
    const client = new ArenaClient();

    return new DataType(
      12,
      i18n.t('Dashboard.Form.PostsByPostProfile'),
      Application.arena(),
      UseDate.withDate,
      client.postsbypostprofile,
    );
  }

  static postsByModule(): DataType {
    const client = new ArenaClient();

    return new DataType(
      13,
      i18n.t('Dashboard.Form.PostsByModule'),
      Application.arena(),
      UseDate.withDate,
      client.postsbymodule,
    );
  }

  static facilitiesByCriticity(): DataType {
    const arenaClient = new ArenaClient();

    return new DataType(
      14,
      i18n.t('Dashboard.Form.FacilitiesByCriticity'),
      Application.arena(),
      UseDate.noDate,
      arenaClient.facilitiesbycriticity,
    );
  }

  static facilitiesByType(): DataType {
    const arenaClient = new ArenaClient();
    return new DataType(
      15,
      i18n.t('Dashboard.Form.FacilitiesByType'),
      Application.arena(),
      UseDate.noDate,
      arenaClient.facilitiesbytype,
    );
  }

  static globalSupervanity(): DataType {
    const arcaClient = new ArcaClient();
    return new DataType(
      16,
      i18n.t('Dashboard.Form.GlobalSupervanity'),
      Application.arca(),
      UseDate.noDate,
      arcaClient.globalsupervanity,
    );
  }

  static optimizationsByCountryAndState(): DataType {
    const arenaClient = new ArenaClient();
    return new DataType(
      18,
      i18n.t('Dashboard.Form.OptimizationsByCountryAndState'),
      Application.arena(),
      UseDate.noDate,
      arenaClient.optimizationbycountryandstate,
    );
  }

  static optimizedHours(): DataType {
    const arenaClient = new ArenaClient();
    return new DataType(
      19,
      i18n.t('Dashboard.Form.OptimizedHours'),
      Application.arena(),
      UseDate.noDate,
      arenaClient.optimizedhours,
    );
  }

  static getList(): DataType[] {
    return [
      this.passengersToMonthsByYear(),
      this.internationalDepartureFlights(),
      this.internationalDeparturesByCountry(),
      this.internationalArrivalsByCountry(),
      this.nationalAndInternationalFlights(),
      this.nationalAndInternationalPassengers(),
      this.passengersByflightType(),
      this.domesticFlightsAndPassengers(),
      this.domesticFlights(),
      this.domesticFlightsComparison(),
      this.postsByCountry(),
      this.postsByProvider(),
      this.postsByPostProfile(),
      this.postsByModule(),
      this.facilitiesByCriticity(),
      this.facilitiesByType(),
      this.globalSupervanity(),
      this.optimizationsByCountryAndState(),
      this.optimizedHours(),
    ];
  }

  static getListByApplication(applicationId: number): DataType[] {
    return this.getList().filter((x) => x.application.id === applicationId);
  }

  static getById(id: number): DataType | undefined {
    return this.getList().find((c) => c.id === id);
  }
}
