import { useReducer, type ReactNode } from 'react';
import { dashboardReducer, type DashboardState } from '../reducer/DashboardReducer';
import { DashboardContext, DashboardDispatchContext } from './DasboardContext';

interface DashboardProviderProps {
  children: ReactNode;
}

const initialDashboardState: DashboardState = {
  userApplications: [],
  applicationsData: {},
};

export const DashboardContextProvider = ({ children }: DashboardProviderProps): JSX.Element => {
  const [dashboard, dispatch] = useReducer(dashboardReducer, initialDashboardState);

  return (
    <DashboardContext.Provider value={dashboard}>
      <DashboardDispatchContext.Provider value={dispatch}>{children}</DashboardDispatchContext.Provider>
    </DashboardContext.Provider>
  );
};
