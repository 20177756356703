import {
  type Telefonica_DGSI_Dashboard_Application_UserData_Model_UserModel,
  type Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserApplicationLogs,
  type Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserDataResponse,
  UsersClient,
  type Telefonica_DGSI_Dashboard_Domain_Services_Section,
} from './services';

export type User = Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserDataResponse;
export type UserModel = Telefonica_DGSI_Dashboard_Application_UserData_Model_UserModel;
export type Log = Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserApplicationLogs;
export type Section = Telefonica_DGSI_Dashboard_Domain_Services_Section;
class UserService {
  constructor(private readonly usersClient: UsersClient) {}

  async getUser(): Promise<User> {
    return await this.usersClient.user();
  }

  async getAllUsers(): Promise<UserModel[]> {
    return await this.usersClient.all();
  }

  async getCurrentUsers(application: string): Promise<number> {
    return await this.usersClient.getcurrentusers({ application });
  }

  async updateLanguage(language: string): Promise<void> {
    const request = {
      language,
    };
    await this.usersClient.updateLanguage({ body: request });
  }

  async getSections(): Promise<Section[]> {
    return await this.usersClient.sections();
  }
}

export default new UserService(new UsersClient());
