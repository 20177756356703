import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_US from '@shared/translations/en-US.json';
import es_ES from '@shared/translations/es-ES.json';

export const resources = {
  en_US,
  es_ES,
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: 'en_US',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
