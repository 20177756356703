import { usersGridDataMapper } from './mappers/usersGridDataMapper';
import { type Role } from '@state/permissionsManagement/models/role';
import { type Application } from '../../state/permissionsManagement/models/applicacion';
import { type Subject } from '../../state/permissionsManagement/models/subject';
import { ApplicationMapper } from './mappers/applicationMapper';
import { RolesMapper } from './mappers/roleMapper';
import { SubjectMapper } from './mappers/subjectMapper';
import { type ApplicationsClient } from './services';
import { type AddUserModel } from './models/addUserModel';
import { type UserGrid } from '@state/permissionsManagement/models/userGrid';

export class PermissionsManagementService {
  constructor(private readonly client: ApplicationsClient) {}

  async getAllApplications(): Promise<Application[]> {
    const content = await this.client.applications();

    return ApplicationMapper.map(content);
  }

  async getUsers(appId: number): Promise<Subject[]> {
    const content = await this.client.users({ id: appId });

    return SubjectMapper.map(content);
  }

  async getUsersGridData(appId: number): Promise<UserGrid[]> {
    const content = await this.client.users({ id: appId });

    return usersGridDataMapper(content);
  }

  async getRoles(appName: string, sub: string): Promise<Role[]> {
    const content = await this.client.roles({ appName, sub });

    return RolesMapper.map(content);
  }

  async addRole(appName: string, sub: string, roleName: string): Promise<void> {
    await this.client.roles1({ appName, sub, roleName });
  }

  async deleteRole(appName: string, sub: string, roleName: string): Promise<void> {
    await this.client.roles2({ appName, sub, roleName });
  }

  async addUser(addUserModel: AddUserModel): Promise<void> {
    await this.client.users1({ body: addUserModel });
  }
}
