import { CustomGrid, TooltipCell } from '@components/shared/CustomGrid/CustomGrid';
import { type DataResult, type State } from '@progress/kendo-data-query';
import { GridColumn, type GridDataStateChangeEvent, type GridRowClickEvent } from '@progress/kendo-react-grid';
import { type UserGrid } from '@state/permissionsManagement/models/userGrid';

interface UsersTableProps {
  userClick: (e: GridRowClickEvent) => void;
  onGridOptionsChange: (e: GridDataStateChangeEvent) => void;
  users: UserGrid[] | DataResult;
  gridOptions: State;
}

export const UsersTable = ({ userClick, onGridOptionsChange, users, gridOptions }: UsersTableProps): JSX.Element => {
  return (
    <CustomGrid data={users} onRowClick={userClick} onDataStateChange={onGridOptionsChange} {...gridOptions}>
      <GridColumn field="name" title="User" cell={TooltipCell} />
      <GridColumn field="countries" title="Country" cell={TooltipCell} />
      <GridColumn field="roles" title="Role" cell={TooltipCell} />
      <GridColumn field="lastAccess" title="Last Access" cell={TooltipCell} />
      <GridColumn field="accessCount" title="Acess Count" filter="numeric" cell={TooltipCell} />
    </CustomGrid>
  );
};

export const UsersTableArena = ({
  userClick,
  users,
  onGridOptionsChange,
  gridOptions,
}: UsersTableProps): JSX.Element => {
  return (
    <CustomGrid data={users} onRowClick={userClick} onDataStateChange={onGridOptionsChange} {...gridOptions}>
      <GridColumn field="name" title="User" cell={TooltipCell} />
      <GridColumn field="countries" title="Country" cell={TooltipCell} />
      <GridColumn field="modules" title="Modules" cell={TooltipCell} />
      <GridColumn field="roles" title="Role" cell={TooltipCell} />
      <GridColumn field="lastAccess" title="Last Access" cell={TooltipCell} />
      <GridColumn field="accessCount" title="Acess Count" filter="numeric" cell={TooltipCell} />
    </CustomGrid>
  );
};

export const UsersTableJourney = ({
  userClick,
  users,
  onGridOptionsChange,
  gridOptions,
}: UsersTableProps): JSX.Element => {
  return (
    <CustomGrid data={users} onRowClick={userClick} onDataStateChange={onGridOptionsChange} {...gridOptions}>
      <GridColumn field="name" title="User" cell={TooltipCell} />
      <GridColumn field="countries" title="Country" cell={TooltipCell} />
      <GridColumn field="roles" title="Role" cell={TooltipCell} />
      <GridColumn field="corporate" title="Corporate Agency" cell={TooltipCell} />
      <GridColumn field="lastAccess" title="Last Access" cell={TooltipCell} />
      <GridColumn field="accessCount" title="Acess Count" filter="numeric" cell={TooltipCell} />
    </CustomGrid>
  );
};
