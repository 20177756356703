import {
  Calendar,
  DatePicker,
  type CalendarProps,
  type DatePickerHandle,
  type DatePickerProps,
} from '@progress/kendo-react-dateinputs';
import '@progress/kendo-theme-default/scss/calendar/_index.scss';
import '@progress/kendo-theme-default/scss/input/_index.scss';
import { forwardRef, type Ref } from 'react';
import { type InputCommonProps } from '../form.model';
import { InputGroup } from '../input-group';
type CustomDatePickerProps = InputCommonProps & DatePickerProps;
const DatePickerCustom = forwardRef(
  ({ label, error, disabled, ...props }: CustomDatePickerProps, ref: Ref<DatePickerHandle>) => (
    <InputGroup label={label} error={error} disabled={disabled}>
      {() => <NormalDatePicker ref={ref} {...props} />}
    </InputGroup>
  ),
);
const NormalDatePicker = (props: Any): JSX.Element => {
  return <DatePicker {...props} format="dd/MM/yyyy" calendar={ChildCalendar} />;
};
const ChildCalendar = ({ value, onChange }: CalendarProps) => {
  return <Calendar value={value} onChange={onChange} />;
};
DatePickerCustom.displayName = 'DatePickerCustom';
export { DatePickerCustom };
