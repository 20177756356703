import { MapChart } from '@components/dashboard/mapChart/MapChart';
import { AlphanumericChart } from '@components/dashboard/simpleChart/alphanumericChart/AlphanumericChart';
import { PieChart } from '@components/dashboard/simpleChart/pieChart/PieChart';
import { Sparkline } from '@components/dashboard/simpleChart/sparkLine/Sparkline';
import { TreeMapChart } from '@components/dashboard/simpleChart/treeMapChart/TreeMapChart';
import { type MasonryStone } from '@components/shared/MasonryLayout/MasonryLayout';
import { analyticsService } from '@core/services/analyticsJourneyService';
import { mapTotalSessionsToSparkline } from '@core/services/mappers/totalSessionsMapper';
import { Application } from '@core/services/models/application';
import { type TotalSessionsModel } from '@core/services/models/totalSessionsModel';
import userService from '@core/services/userService';
import { mapTimeSpan } from '@shared/utils';
import { type BlockMetadata } from '@state/block/models/blockMetadata';
import { ChartType } from '@state/block/models/chartType';
import { type MapHeatData } from '@state/block/models/mapHeatData';
import { type TreeMapData } from '@state/block/models/treeMapData';
import { useEffect, useState } from 'react';
import { AnalyticsChart } from '../AnalyticsChart/AnalyticsChart';

const CurrentUsers = (): JSX.Element => {
  const [currentUsers, setCurrentUsers] = useState<number>(0);
  useEffect(() => {
    userService.getCurrentUsers(Application.journey().name).then(setCurrentUsers);
  }, []);
  return (
    <AnalyticsChart title="Total current users" application={Application.journey()}>
      {currentUsers != null && <AlphanumericChart data={currentUsers?.toString()} />}
    </AnalyticsChart>
  );
};

const AverageSessionTime = (): JSX.Element => {
  const [sessionAverageTime, setSessionAverageTime] = useState<string>('');
  useEffect(() => {
    analyticsService.getAverageSessionTime().then((response) => {
      const [hours, minutes, seconds] = response.split(':');
      setSessionAverageTime(mapTimeSpan(+hours, +minutes, +seconds));
    });
  }, []);
  return (
    <AnalyticsChart title="Average session time" application={Application.journey()}>
      {sessionAverageTime && <AlphanumericChart data={sessionAverageTime} />}
    </AnalyticsChart>
  );
};

const SessionsByPages = (): JSX.Element => {
  const [sessionsByPages, setSessionsByPages] = useState<TreeMapData[]>([]);
  useEffect(() => {
    analyticsService.getTopPageViews().then(setSessionsByPages);
  }, []);
  return (
    <AnalyticsChart title="Most visited sections" application={Application.journey()}>
      {sessionsByPages.length > 0 && <TreeMapChart data={sessionsByPages} />}
    </AnalyticsChart>
  );
};

const TotalSessions = (): JSX.Element => {
  const [totalSessions, setTotalSessions] = useState<TotalSessionsModel>();
  useEffect(() => {
    analyticsService.getTotalSessions().then(setTotalSessions);
  }, []);

  return (
    <AnalyticsChart title="Total sessions" application={Application.journey()}>
      {totalSessions && <Sparkline visibleCategories={false} data={mapTotalSessionsToSparkline(totalSessions)} />}
    </AnalyticsChart>
  );
};

const SessionsByCountry = (): JSX.Element => {
  const [sessionsByCountry, setSessionsByCountry] = useState<MapHeatData[]>([]);
  useEffect(() => {
    analyticsService.getCountByCountry().then(setSessionsByCountry);
  }, []);
  return (
    <AnalyticsChart title="Total sessions by Country" application={Application.journey()}>
      {sessionsByCountry.length > 0 && <MapChart chartType={ChartType.heat()} data={sessionsByCountry} />}
    </AnalyticsChart>
  );
};

const SessionsByBrowsers = (): JSX.Element => {
  const [sessionsByBrowsers, setSessionsByBrowsers] = useState<BlockMetadata[]>([]);
  useEffect(() => {
    analyticsService.getListbrowsers().then(setSessionsByBrowsers);
  }, []);

  return (
    <AnalyticsChart title="Browsers" application={Application.journey()}>
      {sessionsByBrowsers.length > 0 && <PieChart data={sessionsByBrowsers} />}
    </AnalyticsChart>
  );
};

export const JourneyAnalytics = (): MasonryStone[] => {
  return [
    { id: 1, element: <CurrentUsers />, columns: 1, rows: 1 },
    { id: 3, element: <SessionsByPages />, columns: 2, rows: 2 },
    { id: 2, element: <AverageSessionTime />, columns: 1, rows: 1 },
    { id: 4, element: <TotalSessions />, columns: 1, rows: 2 },
    { id: 5, element: <SessionsByCountry />, columns: 1, rows: 2 },
    { id: 6, element: <SessionsByBrowsers />, columns: 1, rows: 2 },
  ];
};
