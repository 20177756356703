import loader_small from '@assets/images/loading_small.svg';
import React from 'react';
import './LoaderSmall.scss';

export const LoaderSmall = () => {
  return (
    <div className="db-loaderSmall__wrapper">
      <img src={loader_small} alt="" />
    </div>
  );
};
