import React from 'react';
import '../../../assets/fonts/iconsFont/db-icons-font.css';
import './Icon.scss';

interface IIconProps {
  iconName: string;
  title?: string;
  onClick?: () => void;
}

const Icon: React.FC<IIconProps> = ({ iconName, onClick, title }: IIconProps) => {
  return <span onClick={onClick} className={`db-icon icon-${iconName}`} title={title}></span>;
};

export { Icon };
