import React from 'react';
import './tableRisks.scss';

interface ITableRisksProps {
  id: number;
  country?: string;
  riskPercent?: string;
  currentTravelers?: string;
  nextTravelers?: string;
}
export const TableRisks: React.FC = () => {
  const listElements: ITableRisksProps[] = [
    {
      id: 0,
      country: 'Country 1',
      riskPercent: '10',
      currentTravelers: '10',
      nextTravelers: '15',
    },
    {
      id: 1,
      country: 'Country 2',
      riskPercent: '15',
      currentTravelers: '15',
      nextTravelers: '20',
    },
    {
      id: 2,
      country: 'Country 3',
      riskPercent: '20',
      currentTravelers: '20',
      nextTravelers: '25',
    },
    {
      id: 3,
      country: 'Country 4',
      riskPercent: '25',
      currentTravelers: '25',
      nextTravelers: '30',
    },
    {
      id: 4,
      country: 'Country 5',
      riskPercent: '30',
      currentTravelers: '30',
      nextTravelers: '35',
    },
    {
      id: 5,
      country: 'Country 6',
      riskPercent: '35',
      currentTravelers: '35',
      nextTravelers: '40',
    },
  ];
  return (
    <div className="dashboard-table-risks">
      <div className="dashboard-table-risks__header dashboard-table-risks__item">
        <span>#</span>
        <span className="dashboard-table-risks__country">Country</span>
        <span className="dashboard-table-risks__item-column">Risk</span>
        <span className="dashboard-table-risks__item-column">Current travelers</span>
        <span className="dashboard-table-risks__item-column">Next travelers</span>
      </div>
      <div className="dashboard-table-risks__body">
        {listElements.map((element, index) => (
          <div key={`indicator_${element.id}`} className="dashboard-table-risks__item">
            <span>{index + 1}</span>
            <span className="dashboard-table-risks__country">{element.country}</span>
            <span className="dashboard-table-risks__item-column">{element.riskPercent} %</span>
            <span className="dashboard-table-risks__item-column">{element.currentTravelers}</span>
            <span className="dashboard-table-risks__item-column">{element.nextTravelers}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
