import { type NewsItemResponse } from '@core/services/models/newsItemModel';
import { RssSize, type RssSizeElementProps } from '@containers/News/NewsSection/rssSize';
import { type RssType } from '@state/block/models/rssType';
import { type FC } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import './NewsItem.scss';

export interface NewsItemProps {
  newsItem: NewsItemResponse;
  sizeId: number;
  type: RssType;
}

export const NewsItem: React.FC<NewsItemProps> = ({ newsItem, sizeId, type }: NewsItemProps) => {
  const NewsItemElement = RssSize.getById(sizeId)?.element as FC<RssSizeElementProps>;

  return <NewsItemElement newsItem={newsItem} type={type} />;
};
