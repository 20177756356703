import { createContext, useContext, type Dispatch } from 'react';
import { type DashboardActionTypes } from '../actions/actionTypes';
import { type DashboardAction } from '../actions/dasboardActionCreator';
import { type DashboardState } from '../reducer/DashboardReducer';

export const DashboardContext = createContext<DashboardState>({} as DashboardState);

export const DashboardDispatchContext = createContext<Dispatch<DashboardAction<DashboardActionTypes>>>(
  (() => null) as Dispatch<DashboardAction<DashboardActionTypes>>,
);

export const useDashboardContext = () => useContext(DashboardContext);

export const useDashboardDispatch = () => useContext(DashboardDispatchContext);
