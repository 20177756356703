import { Icon } from '@components/shared/Icon/Icon';
import { type ReactNode } from 'react';
import { type InputCommonProps } from './form.model';
import './input-group.scss';

interface ChildrenProps {
  hasError: boolean;
}

type InputGroupProps = InputCommonProps & {
  children: (props: ChildrenProps) => ReactNode;
};

const InputGroup = ({ label, error, children, disabled }: InputGroupProps): JSX.Element => {
  const hasError = Boolean(error);

  return (
    <label>
      {label && <span className={`db-input-group__label ${disabled ? 'is-disabled' : ''}`}>{label}</span>}
      {children({ hasError })}
      <div className="db-input-group__error-wrapper">
        {error ? (
          <div className="db-input-group__error-container">
            <Icon iconName="icon-cross"></Icon>
            <span className="db-input-group__label-error">{error}</span>
          </div>
        ) : null}
      </div>
    </label>
  );
};

export { InputGroup };
