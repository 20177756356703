import { createContext } from 'react';

interface ISidePanel {
  toggleAlertsAndNotifications: () => void;
  toggleMyProfile: () => void;
  toggleRss: () => void;
  alertsPanelOpen: boolean;
  myProfilePanelOpen: boolean;
  rssPanelOpen: boolean;
}

export const sidePanelContext = createContext<ISidePanel | null>(null);
