import { observer } from 'mobx-react-lite';
import { lazy, Suspense, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';
import './RssPanel.scss';

interface IProps {
  onClose: () => void;
  showSocial: boolean;
}

const LazyTimeline = lazy(async () => await import('./TwitterTimeline/TwitterTimeline'));

export const RssPanel: FunctionComponent<IProps> = observer(({ onClose, showSocial }) => {
  const { t } = useTranslation('translation');

  return (
    <div hidden={!showSocial} className="rss-panel__wrapper-hero">
      <div className="rss-panel__wrapper">
        <div className="rss-panel__wrapper-in">
          <div className="rss-panel__header">
            <span className="rss-panel__title">{t('Rss.SocialMedia')}</span>
            <Icon onClick={onClose} iconName="close"></Icon>
          </div>
          <div className="rss-panel__body">
            <Suspense fallback={null}>
              <LazyTimeline />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
});
