import { BlockContent } from '@components/dashboard/BlockContent';
import { InputSelect, type SimpleOption } from '@components/form/input-select/input-select';
import { DBButton } from '@components/shared/button/button';
import { Icon } from '@components/shared/Icon/Icon';
import { Loader } from '@components/shared/Loader/Loader';
import { MasonryLayout } from '@components/shared/MasonryLayout/MasonryLayout';
import { DashboardsService } from '@core/services';
import { arcaService } from '@core/services/arcaService';
import { type WorkstreamModel } from '@core/services/models/workstreamModel';
import { DashboardsClient } from '@core/services/services';
import { useStores } from '@hooks/useStores/useStores';
import { type Dashboard as DashboardModel } from '@state/block/models/dashboard';
import { type DataType } from '@state/block/models/dataType';
import { type Widget } from '@state/block/models/widget';
import { observer } from 'mobx-react-lite';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationsList } from './ApplicationsList/ApplicationsList';
import { BlockForm } from './BlockForm/BlockForm';
import { ChartsList } from './ChartsList/ChartsList';
import './Dashboard.scss';
import { DashboardForm } from './DashboardForm/DashboardForm';

export const Dashboard: FunctionComponent = observer((): JSX.Element => {
  const { t } = useTranslation('translation', { keyPrefix: 'Dashboard' });
  const dashboardService = new DashboardsService(new DashboardsClient());
  const [dashboardIndex, setDashboardIndex] = useState<number>(0);
  const [dashboards, setDashboards] = useState<DashboardModel[] | null>(null);
  const [selectedDataType, setSelectedDataType] = useState<DataType | null>(null);
  const [applicationsListOpen, setApplicationsListOpen] = useState<boolean>(false);
  const [chartsListOpen, setChartsListOpen] = useState<boolean>(false);
  const [blockFormOpen, setBlockFormOpen] = useState<boolean>(false);
  const [selectedBlockId, setSelectedBlockId] = useState<number | null>(null);
  const [selectedWidget, setSelectedWidget] = useState<Widget<Any> | null>(null);
  const [dashboardFormOpen, setDashboardFormOpen] = useState<boolean>(false);
  const [dashboardToEditId, setDashboardToEditId] = useState<number | null>(null);
  const [workstreams, setWorkstreams] = useState<WorkstreamModel[]>([]);

  const { dashboardStore } = useStores();
  const { dashboard } = dashboardStore;

  useEffect(() => {
    loadDashboards();
    loadWorkstreams();
  }, []);

  const loadDashboards = async (): Promise<void> => {
    await dashboardService.getAll().then(async (data) => {
      setDashboards(data);
      const firstDashboard = data[0];
      if (firstDashboard) {
        setDashboardIndex(0);
        await dashboardStore.loadDashboard(firstDashboard.id);
      }
    });
  };

  const loadWorkstreams = async (): Promise<void> => {
    await arcaService.getWorkstreams().then(setWorkstreams);
  };

  const onOpenDashboardForm = (): void => {
    setDashboardFormOpen(true);
    setBlockFormOpen(false);
  };

  const onOpenApplicationsList = (): void => {
    setApplicationsListOpen(true);
    setDashboardFormOpen(false);
  };

  const onOpenChartsList = (dataType: DataType): void => {
    setSelectedDataType(dataType);
    setChartsListOpen(true);
    setBlockFormOpen(false);
    setDashboardFormOpen(false);
  };

  const onOpenBlockForm = (widget: Widget<Any>): void => {
    setSelectedWidget(widget);
    setBlockFormOpen(true);
    setDashboardFormOpen(false);
  };

  const onOpenEditBlockForm = (): void => {
    setBlockFormOpen(true);
    setDashboardFormOpen(false);
  };

  const onCloseApplication = (): void => {
    setApplicationsListOpen(false);
    setChartsListOpen(false);
    setBlockFormOpen(false);
    setSelectedBlockId(null);
  };

  const onCloseDashboardForm = (): void => {
    setDashboardFormOpen(false);
    setDashboardToEditId(null);
  };

  const onCloseBlockForm = (): void => {
    setBlockFormOpen(false);
    setSelectedBlockId(null);
  };

  const onSaveBlock = (): void => {
    setBlockFormOpen(false);
    setChartsListOpen(false);
    setApplicationsListOpen(false);
    setSelectedBlockId(null);
  };

  const onCloseBlockFormChartsList = (): void => {
    setBlockFormOpen(false);
    setChartsListOpen(false);
    setSelectedBlockId(null);
  };

  const onChangeDashboard = async (event: SimpleOption): Promise<void> => {
    if (dashboards === null) return;
    const index = dashboards.findIndex((d) => d.id === parseInt(`${event.value}`, 10));
    setDashboardIndex(index);
    await dashboardStore.loadDashboard(dashboards[index].id);
  };

  const previousPage = async (): Promise<void> => {
    if (dashboards === null) return;
    const previousIndex = dashboardIndex - 1;
    if (previousIndex >= 0) {
      setDashboardIndex(previousIndex);
      await dashboardStore.loadDashboard(dashboards[previousIndex].id);
    }
  };

  const nextPage = async (): Promise<void> => {
    if (dashboards === null) return;
    const nextIndex = dashboardIndex + 1;
    if (nextIndex < dashboards.length) {
      setDashboardIndex(nextIndex);
      await dashboardStore.loadDashboard(dashboards[nextIndex].id);
    }
  };

  const onDeleteDashboardAndCLose = async (): Promise<void> => {
    await dashboardStore.deleteDashboard();
    onCloseDashboardForm();
    setDashboardIndex(0);
    await loadDashboards();
  };

  return (
    <section className="dashboard__body">
      {dashboards && dashboards.length > 0 ? (
        <div className="dashboard__actions-row">
          <div className="dashboard__actions-row--left">
            <InputSelect
              usePortal
              value={dashboards[dashboardIndex]?.id?.toString()}
              options={dashboards.map((d) => ({ label: d.name, value: d.id.toString() }))}
              onChangeFullOption={(e) => {
                onChangeDashboard(e);
              }}
            />
            <Icon
              iconName="config"
              title={t('EditDashboard')}
              onClick={() => {
                onOpenDashboardForm();
                setDashboardToEditId(dashboard.id);
              }}
            ></Icon>
            <Icon
              iconName="add-circle"
              title={t('AddDashboard')}
              onClick={() => {
                onOpenDashboardForm();
                setDashboardToEditId(null);
              }}
            ></Icon>
          </div>

          {dashboards[dashboardIndex] ? (
            <div className="dashboard__page-selector">
              {dashboardIndex > 0 && (
                <Icon
                  iconName="chevron-left"
                  onClick={() => {
                    previousPage();
                  }}
                ></Icon>
              )}

              <span className="dashboard__page-selector--number">{`${dashboardIndex + 1} / ${dashboards.length}`}</span>
              {dashboardIndex < dashboards.length - 1 && (
                <Icon
                  iconName="chevron-right"
                  onClick={() => {
                    nextPage();
                  }}
                ></Icon>
              )}
            </div>
          ) : null}
          <div className="dashboard__actions">
            {dashboard.name ? (
              <>
                <DBButton
                  type="button"
                  name="withIcon"
                  iconName="add"
                  text={t('CreateNewWidget')}
                  onClick={() => {
                    onOpenApplicationsList();
                    setSelectedBlockId(null);
                  }}
                ></DBButton>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        dashboards && (
          <div className="dashboard__empty-wrapper">
            <div
              className="shared-layout__container dashboard__empty"
              onClick={() => {
                onOpenDashboardForm();
                setDashboardToEditId(null);
              }}
            >
              <h1 className="title">{t('WelcomeToDatacampus')}</h1>
              <p>{t('ToCreateYourFirstDashboardClickHere')}</p>
            </div>
          </div>
        )
      )}
      {dashboard.name && (
        <MasonryLayout
          columnBreakPoints={{ 700: 2, 1200: 3 }}
          rows={2}
          stoneHeight={500}
          gutter={24}
          stones={dashboard.blocks
            .filter(
              (block) =>
                !block.workstreamId || workstreams.map((workstream) => workstream.id).includes(block.workstreamId),
            )
            .map((block) => ({
              element: (
                <div key={`block_${block.id}`} className="dashboard__block">
                  <BlockContent
                    onUpdate={() => {
                      onOpenEditBlockForm();
                      setSelectedBlockId(block.id);
                    }}
                    block={block}
                  />
                </div>
              ),
              columns: block.columns,
              rows: block.rows,
            }))}
        />
      )}
      {!dashboards && <Loader />}

      {applicationsListOpen ? (
        <ApplicationsList
          onSelectDataType={onOpenChartsList}
          onCloseApplication={onCloseApplication}
        ></ApplicationsList>
      ) : null}

      {chartsListOpen ? (
        <ChartsList
          dataType={selectedDataType}
          onSelectWidget={onOpenBlockForm}
          onClose={onCloseBlockFormChartsList}
        ></ChartsList>
      ) : null}

      {blockFormOpen && dashboards !== null ? (
        <BlockForm
          blockId={selectedBlockId}
          dashboardId={dashboards[dashboardIndex].id}
          workstreams={workstreams}
          widget={selectedWidget}
          onSave={onSaveBlock}
          onClose={onCloseBlockForm}
        />
      ) : null}
      {dashboardFormOpen ? (
        <DashboardForm
          dashboardId={dashboardToEditId}
          onSave={() => {
            loadDashboards();
            onCloseDashboardForm();
          }}
          onClose={onCloseDashboardForm}
          onDelete={() => {
            onDeleteDashboardAndCLose();
          }}
        />
      ) : null}
    </section>
  );
});
