import { DropdownMenu } from '@components/shared/dropdown-menu/dropdown-menu';
import { Icon } from '@components/shared/Icon/Icon';
import { Application } from '@core/services/models/application';
import { useStores } from '@hooks/useStores/useStores';
import { observer } from 'mobx-react-lite';
import { type FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { timeAgo } from '../../../../utils';
import './Alerts.scss';

export const Alerts: FunctionComponent = observer(() => {
  const { t } = useTranslation('translation');
  const { tasksStore } = useStores();
  const { alertsFiltered } = tasksStore;

  useEffect(() => {
    tasksStore.loadTasks();
  }, []);

  const markAsRead = (id: number) => () => {
    tasksStore.readAlert(id);
  };

  const deleteAlert = (id: number) => () => {
    tasksStore.deleteAlert(id);
  };

  return (
    <div className="alerts-panel__list-wrapper">
      <ul className="alerts-panel__list">
        {alertsFiltered.map((item) => (
          <li className={`alerts-panel__item${item.read ? ' read' : ''}`} key={`alert_${item.id}`}>
            <div className={`alerts-panel__item-ball ${item.platform.toLocaleLowerCase()}`}>
              {Application.getByName(item.platform)?.acronym}
            </div>
            <div className="alerts-panel__info">
              <div className="alerts-panel__item-title-container">
                <p className="alerts-panel__item-title">{item.title}</p>
                <div className="alerts-panel__item-menu">
                  <p className="alerts-panel__item-time">{`${timeAgo(new Date(item.showDate))} ago`}</p>
                  <DropdownMenu renderToggle={() => <Icon iconName="dots"></Icon>}>
                    <ul className="dropdown-menu__list">
                      {!item.read ? (
                        <li onClick={markAsRead(item.id)} className="dropdown-menu__list-item">
                          <Icon iconName="tick"></Icon>
                          <span className="dropdown-menu__list-item-text">{t('DropdownMenu.MarkAsRead')}</span>
                        </li>
                      ) : null}
                      <li onClick={deleteAlert(item.id)} className="dropdown-menu__list-item">
                        <Icon iconName="trash"></Icon>
                        <span className="dropdown-menu__list-item-text">{t('DropdownMenu.Delete')}</span>
                      </li>
                    </ul>
                  </DropdownMenu>
                </div>
              </div>
              <p className="alerts-panel__item-description">{item.message}</p>
              <a
                className="alerts-panel__item-detail"
                href={item.url}
                onClick={markAsRead(item.id)}
                rel="noreferrer"
                target="_blank"
              >
                {t('AlertsAndNotifications.GoToAlert')}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
});
