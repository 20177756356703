import { type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountry } from './../services';
import { groupBy } from 'lodash';

export const mapPostsByCountryToBarChart = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountry[],
): MultipleBlockMetadata[] => {
  const dataGroupedByNature = groupBy(response, (item) => item.postNatureName);
  const dataGroupedByCountry = groupBy(response, (item) => item.countryName);
  const natures = Object.keys(dataGroupedByNature);
  const countries = Object.keys(dataGroupedByCountry);

  const allData = [
    {
      name: 'All',
      data: countries.map((country) => ({
        name: country,
        value: dataGroupedByCountry[country].reduce((prev, current) => (prev += current.postsCount), 0),
      })),
    },
  ];

  return [
    {
      option: 'Single',
      data: [
        ...allData,
        ...natures.map((nature) => ({
          name: nature,
          data: dataGroupedByNature[nature].map((itemMetadata) => ({
            name: itemMetadata.countryName,
            value: itemMetadata.postsCount,
          })),
        })),
      ],
    },
  ];
};
