import { Navigate, type RouteProps } from 'react-router-dom';
import { AppSelector } from './AppSelector/AppSelector';
import { PermissionsManagement } from './PermissionsManagement/PermissionsManagement';
import { UserList } from './UserList/UserList';
import { UserSelector } from './UserSelector/UserSelector';

export const permissionsManegementRoutes: RouteProps[] = [
  { path: '*', element: <Navigate to="apps" /> },
  { path: 'apps', element: <AppSelector /> },
  { path: 'users', element: <UserList /> },
  { path: 'apps/:appId/users', element: <UserSelector /> },
  { path: 'apps/:appId/users/:userSub/permissions', element: <PermissionsManagement /> },
];
