import { Icon } from '@components/shared/Icon/Icon';
import { ERRORS } from '@state/constants';
import { type FunctionComponent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ErrorPage.scss';

interface IProps {
  code?: number;
  message?: string;
}

export const ErrorPage: FunctionComponent<IProps> = ({ code = 404, message }) => {
  const location = useLocation();
  const [error, setError] = useState({
    code,
    message: message ?? 'Page not found',
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    if (code) {
      setError({ ...ERRORS[+code] });
    }

    if (location.state) {
      setError({ code: location.state.code, message: location.state.message });
    }
  }, [location.state, location.search]);

  return (
    <>
      <div className="shared-layout" data-test="error-page">
        <div className="shared-layout__container">
          <Icon iconName="error-connection"></Icon>
          <div className="shared-layout__info">
            <span className="shared-layout__code-error" data-test="error-code">
              Error {error.code}
            </span>
            <p className="shared-layout__description">{error.message}</p>
            <Link to="/home" className="shared-layout__navigation">
              {'Go to home'}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
