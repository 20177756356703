import '@assets/scss/import.scss';
import { Analytics } from '@containers/Analytics/Analytics';
import { SigninCallbackPage } from '@containers/authentication/SigninCallbackPage';
import { Dashboard } from '@containers/Dashboard/Dashboard';
import { ErrorPage } from '@containers/ErrorPage/ErrorPage';
import { Home } from '@containers/Home/Home';
import { HomePage } from '@containers/HomePage/HomePage';
import { homePageRoutes } from '@containers/HomePage/HomePageRoutes';
import { News } from '@containers/News/News';
import { PermissionsManagementSection } from '@containers/PermissionsManagement/PermissionsManagementSection';
import { permissionsManegementRoutes } from '@containers/PermissionsManagement/PermissionsManegementRoutes';
import { AppConfiguration } from '@core/config/appConfiguration';
import { AuthProvider } from '@providers/AuthProvider';
import { StoreProvider } from '@providers/StoreProvider';
import TelemetryProvider from '@providers/TelemetryProvider';
import { PrivateRoute } from '@routes/PrivateRoute';
import { DashboardContextProvider } from '@state/dashboard/context/DashboardContextProvider';
import { UserContextProvider } from '@state/user/UserContextProvider';
import 'mobx-react-lite/batchingForReactDom';
import { Suspense } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import AppLayout from './AppLayout';
import './AppLayout.scss';

const App: React.FC = () => {
  const appConfiguration = AppConfiguration.getInstance();
  return (
    <AuthProvider>
      <StoreProvider>
        <TelemetryProvider instrumentationKey={appConfiguration.settings.appInsightKey}>
          <Switch>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <UserContextProvider>
                    <AppLayout />
                  </UserContextProvider>
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <PrivateRoute>
                    <DashboardContextProvider>
                      <Home />
                    </DashboardContextProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="permissionsManagement/*"
                element={
                  <PrivateRoute>
                    <PermissionsManagementSection />
                  </PrivateRoute>
                }
              >
                {permissionsManegementRoutes.map((route) => (
                  <Route key={route.path} {...route} />
                ))}
              </Route>

              <Route
                path="dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="home"
                element={
                  <PrivateRoute>
                    <Suspense>
                      <HomePage />
                    </Suspense>
                  </PrivateRoute>
                }
              >
                {homePageRoutes.map((route) => (
                  <Route key={route.path} {...route} />
                ))}
              </Route>
              <Route
                path="news"
                element={
                  <PrivateRoute>
                    <News />
                  </PrivateRoute>
                }
              />
              <Route
                path="analytics"
                element={
                  <PrivateRoute>
                    <Analytics />
                  </PrivateRoute>
                }
              />
              <Route path="error" element={<ErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
            <Route path="signin-callback" element={<SigninCallbackPage />} />
          </Switch>
        </TelemetryProvider>
      </StoreProvider>
    </AuthProvider>
  );
};

export default App;
