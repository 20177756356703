import { getColor } from '@shared/utils';
import { type DonutTableData } from '@state/block/models/donutTableData';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryHoursResponse } from '../services';

export const donutOptimizedHoursMapper = (
  data: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryHoursResponse[],
): DonutTableData[] => {
  return data.length > 0
    ? [
        {
          name: '',
          data: [
            {
              group: '',
              data: data.map((itemMetadata, index) => ({
                category: itemMetadata.country,
                value: itemMetadata.hours,
                color: getColor(index),
              })),
            },
          ],
        },
      ]
    : [{ name: 'countries', data: [{ group: 'all', data: [{ category: '', value: 0, color: '' }] }] }];
};
