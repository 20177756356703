import { type Section, type User } from '@core/services/userService';
import { createContext, useContext } from 'react';

export const UserContext = createContext<{ userData: User; sections: Section[]; setUser: (args: any) => void }>({
  userData: { name: '', email: '', applicationLogs: [], userRoles: [], language: '' },
  sections: [],
  setUser: () => null,
});

export const useUserContext = () => useContext(UserContext);
