import { getColor, months } from '@shared/utils';
import { type BarPercentageData, type BarPercentageMetaData } from '@state/block/models/barPercentageData';
import {
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths,
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths_FlightsByCountriesAndMonth,
} from '../services';

export const mapFlightsbycountriesandmonths = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths,
): BarPercentageData => {
  return {
    names: months,
    data: response.flightsByCountriesAndMonths.map((value, index) => {
      return mapFlightsbycountriesandmonthsSparkLineMetaData(value, index);
    }),
    option: response.year.toString(),
  };
};

export const mapFlightsbycountriesandmonthsPercentage = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths[],
): BarPercentageData[] => {
  return [...response.map(mapFlightsbycountriesandmonths)];
};

export const mapFlightsbycountriesandmonthsSparkLineMetaData = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths_FlightsByCountriesAndMonth,
  index: number,
): BarPercentageMetaData => {
  return {
    role: response.country,
    color: getColor(index),
    value: [
      response.january,
      response.february,
      response.march,
      response.april,
      response.may,
      response.june,
      response.july,
      response.august,
      response.september,
      response.october,
      response.november,
      response.december,
    ],
  };
};
