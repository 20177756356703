import { getColor } from '@shared/utils';
import lodash from 'lodash';
import { type BarPercentageData } from './../../../state/block/models/barPercentageData';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostByCountryCodeGroupingByNature } from './../services';

export const mapPostsByPostProfileAndCountryPercentage = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostByCountryCodeGroupingByNature[],
): BarPercentageData[] => {
  return response.map(mapPostsByPostProfileAndCountry);
};

export const mapPostsByPostProfileAndCountry = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostByCountryCodeGroupingByNature,
): BarPercentageData => {
  const dataGrouped = lodash.groupBy(response.postByCountryCodeGroupings, (item) => item.codeGroupingName);
  const countries = Object.keys(lodash.groupBy(response.postByCountryCodeGroupings, (item) => item.countryName));

  return {
    names: countries,
    data: Object.keys(dataGrouped).map((item, index) => ({
      role: item,
      color: getColor(index),
      value: countries.map(
        (country) => dataGrouped[item].find((dataItem) => dataItem.countryName === country)?.postsCount ?? 0,
      ),
    })),
    option: response.profileNature,
  };
};
