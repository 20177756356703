import { InputRadio } from '@components/form/input-radio/input-radio';
import { InputSelect } from '@components/form/input-select/input-select';
import { type resources } from 'I18n';
import { useEffect, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './FilterOptions.scss';

export type FilterKey = keyof (typeof resources)['en_US']['translation']['Filter'];
export interface DataModel {
  name: string;
  value: Any;
  subfilter?: Array<{ name: FilterKey; value: Any }>;
}

export interface Filters {
  filter: string | number;
  subfilter?: string | number;
}

export interface Props {
  data: DataModel[];
  value: Filters;
  onChange: (filters: Filters) => void;
}

export const FilterOptions: FunctionComponent<Props> = ({ data, value, onChange }: Props) => {
  const subfilters = data.find((item) => item.value === value.filter)?.subfilter;
  const showSubfilter = subfilters && subfilters.length > 1;
  const subfiltersDropdown = subfilters && subfilters.length > 4;
  const showFilter = data.length > 1;
  const filterDropdown = data.length > 3 || showSubfilter;

  const { t } = useTranslation('translation');
  const filterNames = t('Filter', { returnObjects: true });

  useEffect(() => {
    if (!data || !value || !onChange) return;
    const actualYear = new Date().getFullYear();
    if (data.some((d) => +d.name)) {
      const actualYearFilter = data.find((d) => +d.name === actualYear);
      onChange({
        filter: actualYearFilter ? actualYearFilter.name : data[data.length - 1].name,
        subfilter: value.subfilter,
      });
    } else if (data.some((d) => d.subfilter?.some((s) => +s.name))) {
      const selectedSubfilter = data.find((d) => d.name === value.filter)?.subfilter;

      if (selectedSubfilter) {
        const actualYearFilter = selectedSubfilter.find((d) => +d.name === actualYear);
        onChange({
          filter: value.filter,
          subfilter: actualYearFilter ? actualYearFilter.name : selectedSubfilter[selectedSubfilter.length - 1].name,
        });
      }
    }
  }, [data]);

  return (
    <div className="filter-group">
      {showFilter ? (
        <form className="chart-filters-wrapper">
          {filterDropdown ? (
            <InputSelect
              value={value.filter}
              options={data.map((d) => ({ label: d.name, value: d.value }))}
              onChangeFullOption={(event: Any) => {
                onChange({ filter: event.value, subfilter: value.subfilter });
              }}
            />
          ) : (
            <div className="radio-group">
              {data?.map((d, index) => (
                <InputRadio
                  key={`option_${index}`}
                  value={d.value}
                  checked={value.filter === d.value}
                  name={d.name}
                  onClick={() => {
                    onChange({ filter: d.value, subfilter: value.subfilter });
                  }}
                />
              ))}
            </div>
          )}
        </form>
      ) : null}
      {showSubfilter ? (
        <form className="chart-filters-wrapper">
          {subfiltersDropdown ? (
            <InputSelect
              value={value.subfilter}
              options={subfilters?.map((d) => ({ label: filterNames[d.name] ?? d.name, value: d.value }))}
              onChangeFullOption={(event: Any) => {
                onChange({ filter: value.filter, subfilter: event.value });
              }}
            />
          ) : (
            <div className="radio-group">
              {subfilters?.map((d, index) => (
                <InputRadio
                  key={`option_${index}`}
                  value={d.value}
                  checked={value.subfilter === d.value}
                  name={filterNames[d.name] ?? d.name}
                  onClick={() => {
                    onChange({ filter: value.filter, subfilter: d.value });
                  }}
                />
              ))}
            </div>
          )}
        </form>
      ) : null}
    </div>
  );
};
