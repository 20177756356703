import { type FunctionComponent } from 'react';
import './ToolTipFormated.scss';

export interface Props {
  value: Any;
}

export const ToolTipFormated: FunctionComponent<Props> = ({ value }: Props) => {
  return (
    <div className="custom-tooltip">
      <span className="custom-tooltip__title">{value.categoryText}</span>
      {value.points.map((item: Any, index: number) => {
        return (
          <div key={`${index}__rowTooltip`} className="custom-tooltip__row">
            <span className="custom-tooltip__row--year">{item.series.name}:</span>
            <span className="custom-tooltip__row--value">{item.value}</span>
          </div>
        );
      })}
    </div>
  );
};
