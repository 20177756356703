import React from 'react';
import { Icon } from '../Icon/Icon';
import './button.scss';

interface IDBButtonProps {
  name: 'primary' | 'secondary' | 'bordered' | 'simple' | 'simple-danger' | 'withIcon';
  type: 'button' | 'submit' | 'reset';
  className?: string;
  text: string;
  iconName?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const DBButton: React.FC<IDBButtonProps> = ({
  name,
  type,
  text,
  iconName,
  disabled,
  className,
  onClick,
}: IDBButtonProps) => {
  return (
    <button onClick={onClick} type={type} className={`db-button ${name} ${className ?? ''}`} disabled={disabled}>
      {iconName ? <Icon iconName={iconName}></Icon> : null}
      {text}
    </button>
  );
};

export { DBButton };
