import { type Block } from '../../../state/block/models/block';
import { type Dashboard } from '../../../state/block/models/dashboard';
import {
  type Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_BlockResponse,
  type Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_DashboardResponse,
} from './../services';

export const mapDashboard = (
  response: Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_DashboardResponse,
): Dashboard => {
  return {
    id: response.id,
    name: response.name,
    description: response.description,
    isPublic: response.isPublic,
    createdBy: response.createdBy,
    blocks: response.blocks.map(mapBlock),
    sharedUserSubs: response.sharedUserSubs,
  };
};

export const mapBlock = (response: Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_BlockResponse): Block =>
  ({
    id: response.id,
    title: response.title,
    columns: response.columns,
    rows: response.rows,
    order: response.order,
    widgetId: response.widgetId,
    startDate: response.startDate,
    endDate: response.endDate,
    countryIds: response.countryIds,
    workstreamId: response.workstreamId,
  } as Block);
