import { type resources } from 'I18n';

type TypeName = keyof (typeof resources)['en_US']['translation']['AlertsAndNotifications']['Type'];
export class TaskType {
  id: number;
  name: TypeName;

  private constructor(id: number, name: TypeName) {
    this.id = id;
    this.name = name;
  }

  static alert(): TaskType {
    return new TaskType(1, 'Alerts');
  }

  static notification(): TaskType {
    return new TaskType(2, 'Notifications');
  }
}
