import { type Role } from '@state/permissionsManagement/models/role';
import { type Subject } from '../../../state/permissionsManagement/models/subject';
import {
  type Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role,
  type Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_User,
} from '../services';
export const SubjectMapper = {
  map(response: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_User[]): Subject[] {
    return response.map<Subject>((item) => {
      return {
        sub: item.sub,
        name: item.name,
        imageUrl: item.imageUrl,
        email: item.email,
        lastAccess: item.lastAccess,
        accessCount: item.accessCount,
        roles: item.roles.map((role) => SubjectMapper.mapRole(role)),
      };
    });
  },

  mapRole(role: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role): Role {
    return {
      id: role.id,
      name: role.name,
      description: role.description,
      enabled: role.enabled,
      canEdit: false,
      selected: false,
    };
  },
};
