import { sidePanelContext } from '@contexts/sidePanelContext';
import { useAuth } from '@hooks/useAuth/useAuth';
import { useUserContext } from '@state/user/userContext';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AlertsAndNotifications } from '../AlertsAndNotifications/AlertsAndNotifications';
import { Icon } from '../Icon/Icon';
import { MyProfile } from '../MyProfile/MyProfile';
import { RssPanel } from '../RssPanel/RssPanel';
import { Tooltip } from '../Tooltip/Tooltip';
import './Navbar.scss';

interface Sections {
  users: boolean;
  balea: boolean;
  dashboard: boolean;
  notifications: boolean;
  analytics: boolean;
}

export const Navbar: FunctionComponent = observer(() => {
  const sidePanel = useContext(sidePanelContext);
  const auth = useAuth();
  const { sections } = useUserContext();
  const { t } = useTranslation('translation');
  const [allowedSections, setAllowedSections] = useState<Partial<Sections>>();

  useEffect(() => {
    setAllowedSections({
      balea: sections?.some((i) => i.name === 'Balea'),
      analytics: sections?.some((i) => i.name === 'Analytics'),
      notifications: sections?.some((i) => i.name === 'Notifications'),
      dashboard: sections?.some((i) => i.name === 'Dashboard'),
      users: sections?.some((i) => i.name === 'User'),
    });
  }, [sections]);

  return (
    <>
      <MyProfile
        showProfile={sidePanel?.myProfilePanelOpen ?? false}
        onClose={() => {
          sidePanel?.toggleMyProfile();
        }}
      />
      {sidePanel?.alertsPanelOpen && (
        <AlertsAndNotifications
          onClose={() => {
            sidePanel?.toggleAlertsAndNotifications();
          }}
        />
      )}
      <RssPanel
        showSocial={sidePanel?.rssPanelOpen ?? false}
        onClose={() => {
          sidePanel?.toggleRss();
        }}
      />
      <div className="navbar">
        <ul className="navbar__list profile">
          <li className="navbar__item">
            <Tooltip text={t('MyProfile.Title')}>
              <Icon iconName="user" onClick={sidePanel?.toggleMyProfile} />
            </Tooltip>
          </li>
        </ul>
        <ul className="navbar__list">
          <li className="navbar__item">
            <Link to="/">
              <Tooltip text={t('Home.Title')}>
                <Icon iconName="home" />
              </Tooltip>
            </Link>
          </li>
          <li className="navbar__item" hidden={!allowedSections?.dashboard}>
            <Link to="/dashboard">
              <Tooltip text={t('Dashboard.Title')}>
                <Icon iconName="dashboard" />
              </Tooltip>
            </Link>
          </li>
          <li className="navbar__item" hidden={!allowedSections?.notifications}>
            <Tooltip text={t('AlertsAndNotifications.Title')}>
              <Icon iconName="alert" onClick={sidePanel?.toggleAlertsAndNotifications} />
            </Tooltip>
          </li>
          <li className="navbar__item">
            <Link to="/news">
              <Tooltip text={t('News.Title')}>
                <Icon iconName="news" />
              </Tooltip>
            </Link>
          </li>
          <li className="navbar__item analytics" hidden={!allowedSections?.analytics}>
            <Link to="/analytics">
              <Tooltip text={t('Analytics.Title')}>
                <Icon iconName="analytics" />
              </Tooltip>
            </Link>
          </li>
          <li className="navbar__item">
            <Tooltip text={t('Rss.Title')}>
              <Icon iconName="rss" onClick={sidePanel?.toggleRss} />
            </Tooltip>
          </li>
        </ul>
        <ul className="navbar__list user">
          <li className="navbar__item" hidden={!allowedSections?.balea}>
            <Link to="/home/balea/applications">
              <Tooltip text={t('Balea.Title')}>
                <Icon iconName="permissions-balea" />
              </Tooltip>
            </Link>
          </li>
          <li className="navbar__item" hidden={!allowedSections?.users}>
            <Link to="/permissionsmanagement">
              <Tooltip text={t('PermissionsManagement.Title')}>
                <Icon iconName="permissions-user" />
              </Tooltip>
            </Link>
          </li>

          <li className="navbar__item">
            <Tooltip text={t('Balea.LogOut')}>
              <Icon
                iconName="on-off"
                onClick={() => {
                  void auth.logout();
                }}
              />
            </Tooltip>
          </li>
        </ul>
      </div>
    </>
  );
});
