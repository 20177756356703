import { LoaderSmall } from '@components/shared/LoaderSmall/LoaderSmall';
import { AppConfiguration } from '@core/config/appConfiguration';
import { ApplicationIds } from '@core/services/models/application';
import { useDashboard } from '@hooks/useDashboald/useDashboard';
import { useDashboardContext } from '@state/dashboard/context/DasboardContext';
import { useEffect } from 'react';
import { MetricWidget } from '../MetricWidget/MetricWidget';

export const ArenaMetricsGroup = (): JSX.Element => {
  const { getArenaData } = useDashboard();
  const { applicationsData } = useDashboardContext();
  const arenaData = applicationsData[ApplicationIds.Arena];
  const arenaUrl = AppConfiguration.getInstance().settings.apps.arenaUrl;

  useEffect(() => {
    if (!applicationsData[ApplicationIds.Arena]) {
      getArenaData();
    }
  }, [getArenaData]);

  return arenaData ? (
    <>
      <MetricWidget
        value={arenaData?.data?.todayTotalHours ?? '-'}
        description="Total Q hours"
        link={`${arenaUrl}/#/services/countries`}
      />
      <MetricWidget
        value={arenaData?.data?.totalPhysicalPosts ?? '-'}
        description="Total physical posts"
        link={`${arenaUrl}/#/services/post/physical`}
      />
      <MetricWidget
        value={arenaData?.data?.totalTechnologicPosts ?? '-'}
        description="Total technologies"
        link={`${arenaUrl}/#/services/post/technology`}
      />
      <MetricWidget
        value={arenaData?.data?.totalFacilityCount ?? '-'}
        description="Total facilities"
        link={`${arenaUrl}/#/services/cluster`}
      />
      <MetricWidget
        value={`${arenaData?.data?.performanceInfo?.slaPercentage ?? '-'} %`}
        description="SLA"
        link={`${arenaUrl}/#/performance/reports/sla`}
      />
      <MetricWidget
        value={`${arenaData?.data?.performanceInfo?.surveysPercentage ?? '-'} %`}
        description="Surveys"
        link={`${arenaUrl}/#/performance/reports/surveys`}
      />
      <MetricWidget
        value={`${arenaData?.data?.performanceInfo?.invoicePercentage ?? '-'} %`}
        description="Invoices"
        link={`${arenaUrl}/#/performance/reports/invoices`}
      />
      <MetricWidget
        value={`${arenaData?.data?.performanceInfo?.totalPercentage ?? '-'} %`}
        description="Performance completion"
        link={`${arenaUrl}/#/performance/dashboard`}
      />
    </>
  ) : (
    <LoaderSmall />
  );
};
