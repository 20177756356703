import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './Tooltip.scss';

interface ITooltipProps {
  text: string;
  children: React.ReactNode;
}

export const Tooltip: React.FC<ITooltipProps> = ({ text, children }: ITooltipProps) => {
  return (
    <>
      <div
        className="db-tooltip-text"
        data-tooltip-id="tooltip"
        data-tooltip-class="db-tooltip"
        data-tooltip-content={text}
      >
        {children}
      </div>
      <ReactTooltip id="tooltip" place="right" />
    </>
  );
};
