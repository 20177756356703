import { type NewsItemResponse, type RssResponse } from '../models/newsItemModel';
import {
  type Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_NewsItemResponse,
  type Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_RssResponse,
} from '../services';

export const mapRss = (response: Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_RssResponse): RssResponse => {
  return {
    rssType: response.rssType,
    rssTypeId: response.rssTypeId,
    rssSize: response.rssSize.toLowerCase(),
    rssSizeId: response.rssSizeId,
    autoPlayInterval: response.autoPlayInterval,
    order: response.order,
    newsItemResponse: response.newsItemResponse.map(mapNewsItem),
  };
};

export const mapNewsItem = (
  response: Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_NewsItemResponse,
): NewsItemResponse => {
  return {
    title: response.title,
    description: response.description,
    link: response.link,
    date: response.publish,
    imgUrl: '',
    source: response.source,
  };
};
