import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import addHeatmapModule from 'highcharts/modules/heatmap';
import addTreemapModule from 'highcharts/modules/treemap';
import './TreeMapChart.scss';
import { type TreeMapData } from '@state/block/models/treeMapData';
addHeatmapModule(Highcharts);
addTreemapModule(Highcharts);

interface TreeMapProps {
  data: TreeMapData[];
}

export function TreeMapChart({ data }: TreeMapProps) {
  const getOptions = () => ({
    legend: { enabled: false },
    title: undefined,
    credits: { enabled: false },
    exporting: { enabled: false },
    chart: { backgroundColor: 'transparent' },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        clip: false,
        data,
      },
    ],
  });
  return (
    <div className="treemap-wrapper">
      <HighchartsReact highcharts={Highcharts} options={getOptions()} />{' '}
    </div>
  );
}
