import {
  MarketPrizeClient,
  type Telefonica_DGSI_Dashboard_Domain_Services_MarketPriceService_Responses_MarketPrizeResponse,
} from './services';

export type MarketPrice = Telefonica_DGSI_Dashboard_Domain_Services_MarketPriceService_Responses_MarketPrizeResponse;

class MarketPrizeService {
  client: MarketPrizeClient;

  constructor(client: MarketPrizeClient) {
    this.client = client;
  }

  async getMarketPrice(): Promise<MarketPrice> {
    return await this.client.marketprize();
  }
}

const marketPrizeService = new MarketPrizeService(new MarketPrizeClient());

export default marketPrizeService;
