import { Widget } from '@state/block/models/widget';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type BlockSizeType } from '../../../state/block/models/blockSizeType';
import './blockSizes.scss';

const boxes = [1, 2, 3, 4, 5, 6];

export interface IBlockSizesProps {
  onChangeSize: (blockSize: BlockSizeType) => void;
  rows: number;
  columns: number;
  widgetId: number | undefined;
}

export const BlockSizes = ({ rows, columns, widgetId, onChangeSize }: IBlockSizesProps): JSX.Element => {
  const { t } = useTranslation('translation');
  const [selected, setSelected] = useState<BlockSizeType>();
  const [selectedSizeList, setSelectedSizeList] = useState<BlockSizeType[]>([]);
  useEffect(() => {
    if (widgetId) {
      const selectedSizeList = Widget.getById(widgetId)?.sizes as BlockSizeType[];
      setSelectedSizeList(selectedSizeList);
      const selectedSize = selectedSizeList.find((b) => b.rows === rows && b.columns === columns);
      setSelected(selectedSize);
    }
  }, [rows, columns, widgetId]);
  return (
    <>
      {selectedSizeList.length > 0 && (
        <div className="box-size__wrapper">
          <span className="db-form__section-title">{t('Dashboard.Form.Sizes')}</span>
          {selectedSizeList.map((blockSize, index) => (
            <div
              key={index}
              className={`${blockSize.name} box-size__container ${selected?.id === blockSize.id ? 'selected' : ''}`}
              onClick={() => {
                onChangeSize(blockSize);
              }}
            >
              {boxes.map((item) => (
                <div key={item} className="box-size__child "></div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
