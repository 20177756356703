import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { type Props } from 'react-select';
import { debounce } from 'throttle-debounce';
import { type InputCommonProps } from '../form.model';
import { InputGroup } from '../input-group';

export interface SimpleOption {
  label: string;
  value: string;
}

type InputSelectMultpleProps<TValue> = InputCommonProps &
  Omit<Props<SimpleOption, true>, 'value' | 'isDisabled' | 'onChange'> & {
    values?: TValue[];
    readOnly?: boolean;
    disabled?: boolean;
    usePortal?: boolean;
    placeholder?: string;
    dependantValues?: Any[];
    onChange?: Callback<void, Any>;
    onChangeFullOption?: Callback<void, Any>;
    onFetchData?: Callback<Promise<Any>>;
  };

function InputSelectMultple<TValue extends { value: string } | string | number>({
  label,
  error,
  values,
  readOnly = false,
  disabled = false,
  placeholder,
  usePortal = false,
  dependantValues = [],
  onChange,
  onFetchData,
  onChangeFullOption,
  ...props
}: InputSelectMultpleProps<TValue>): JSX.Element {
  const { t } = useTranslation('translation');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SimpleOption[]>((props.options ?? []) as SimpleOption[]);

  useEffect(() => {
    setOptions(props.options as SimpleOption[]);
  }, [props.options]);

  const onFetchDataDebounced = debounce(1000, () => {
    if (onFetchData && !dependantValues?.some((x) => x == null)) {
      setLoading(true);
      onFetchData?.().finally(() => {
        setLoading(false);
      });
    }
  });

  useEffect(() => {
    onFetchDataDebounced();
  }, [...dependantValues]);

  const valueProps = useMemo(() => {
    const calculateValue = (value: Any) => {
      if (value == null) return null;

      return options?.filter((option) => value.some((v: TValue) => v === option.value));
    };

    return {
      value: calculateValue(values),
      onChange: (option: Any) => {
        onChange?.(option?.value);
        onChangeFullOption?.(option);
      },
    };
  }, [options, values, onChange]);

  return (
    <InputGroup
      label={label}
      error={error}
      disabled={disabled || readOnly || dependantValues?.some((x: Any) => x == null)}
    >
      {({ hasError }: Any) => (
        <Select
          {...props}
          {...valueProps}
          isMulti
          options={options}
          isLoading={isLoading}
          className="db-input-select"
          classNamePrefix="db-input-select"
          menuPortalTarget={usePortal ? document.body : undefined}
          menuPosition={usePortal ? 'fixed' : 'absolute'}
          menuShouldScrollIntoView={!usePortal}
          noOptionsMessage={() => `${t('Shared.NoOptions')}`}
          placeholder={placeholder ?? `${t('Shared.SelectOneOption')}`}
          isDisabled={disabled || readOnly || dependantValues?.some((x: Any) => x == null)}
        />
      )}
    </InputGroup>
  );
}

export { InputSelectMultple };
