import worldmap from '@highcharts/map-collection/custom/world.geo.json';
import { type MapHeatData } from '@state/block/models/mapHeatData';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_CountByCountryResponse } from './../services';

export const mapSessionsByCountryToHeatMap = (
  data: Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_CountByCountryResponse[],
): MapHeatData[] => {
  return data.map((item) => ({
    countryName: item.country,
    code: getCode(item.country) as string,
    value: item.sessions,
    text: item.sessions.toString(),
  }));
};

function getCode(country: string) {
  // Infamous work around, we must study another method to get USA in list with string United States
  if (country === 'United States') {
    // We need update name, because if search United States, get another country
    country = 'United States of America';
  }
  const countries = worldmap.features.map((c) => c.properties);
  const countryData = countries.find((item) => item.name.includes(country));

  return countryData?.['iso-a2'] !== undefined ? countryData?.['iso-a2'] : '';
}
