import { forwardRef, type Ref } from 'react';
import { type InputCommonProps } from '../form.model';
import { InputGroup } from '../input-group';
import './toogle.scss';

type InputToogleProps = InputCommonProps & React.InputHTMLAttributes<HTMLInputElement>;

const InputToogle = forwardRef(
  ({ label, error, value, disabled, ...props }: InputToogleProps, ref: Ref<HTMLInputElement>) => (
    <InputGroup label={label} error={error} disabled={disabled}>
      {() => (
        <label className="switch">
          <input type="checkbox" ref={ref} checked={value !== undefined} disabled={disabled} {...props} />
          <span className="slider round"></span>
        </label>
      )}
    </InputGroup>
  ),
);

InputToogle.displayName = 'InputToogle';
export { InputToogle };
