import logoUrl from '@assets/images/logo_datacampus.svg';
import '@assets/scss/import.scss';
import { InputSelect } from '@components/form/input-select/input-select';
import { Navbar } from '@components/shared/Navbar/Navbar';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import marketPrizeService, { type MarketPrice } from '@core/services/socialMediaService';
import userService from '@core/services/userService';
import { useLanguage } from '@hooks/useLanguage/useLanguage';
import { SidePanelProvider } from '@providers/SidePanelProvider';
import { useUserContext } from '@state/user/userContext';
import 'mobx-react-lite/batchingForReactDom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './AppLayout.scss';

const AppLayout = (): JSX.Element => {
  const { t } = useTranslation('translation');
  const { languages, setLanguage, currentLanguage } = useLanguage();
  const { pathname } = useLocation();
  const { userData } = useUserContext();
  const [marketShare, setMarketShare] = useState<MarketPrice>();

  useEffect(() => {
    marketPrizeService.getMarketPrice().then(setMarketShare);
  }, []);

  useEffect(() => {
    setLanguage(userData.language);
  }, [userData]);

  return (
    <SidePanelProvider>
      <ToastContainer autoClose={5000} />
      <Navbar></Navbar>
      <header className="main__header">
        <img src={logoUrl} alt="Telefónica Data Campus Logo" />
        {!matchPath('home/*', pathname) && (
          <div className="main__header--tools">
            <div className="prize">
              <Tooltip text={`${t(`Home.UpdateDate`)}: ${marketShare ? marketShare.datePrize : ''}`}>
                {'TEF '}
                {new Intl.NumberFormat(navigator.language ?? 'es-ES', { style: 'currency', currency: 'EUR' }).format(
                  marketShare?.prize ?? 0,
                )}
                {marketShare && marketShare.difference > 0 ? (
                  <span className="icon icon-chevron-up"></span>
                ) : (
                  <span className="icon icon-chevron-down"></span>
                )}
                <span>({marketShare?.difference ?? 0})</span>
                <span>{marketShare?.percentage.toFixed(2)}%</span>
              </Tooltip>
            </div>
            <InputSelect
              value={currentLanguage}
              onChangeFullOption={(event) => {
                setLanguage(event.value);
                userService.updateLanguage(event.value);
              }}
              options={languages.map((lang) => ({ label: t(`Languages.${lang}`), value: lang }))}
            />
          </div>
        )}
      </header>
      <main role="main" className="wrapper">
        <Outlet />
      </main>
    </SidePanelProvider>
  );
};

export default AppLayout;
