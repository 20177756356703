import { InputTextbox } from '@components/form/input-text/input-text';
import { DBButton } from '@components/shared/button/button';
import { SidePanel } from '@components/shared/SidePanel/SidePanel';
import { PermissionsManagementService } from '@core/services/permissionsManagementService';
import { ApplicationsClient } from '@core/services/services';
import { yupResolver } from '@hookform/resolvers/yup';
import { type SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { showSuccessMessage } from '../../../utils';

interface IProps {
  onSave: () => void;
  onClose: () => void;
}

const schema = yup.object({
  name: yup.string().required().min(5),
  email: yup.string().required().email(),
});

type FormData = yup.InferType<typeof schema>;

export const UserForm = ({ onSave, onClose }: IProps): JSX.Element => {
  const permissionsManagementService = new PermissionsManagementService(new ApplicationsClient());

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    await permissionsManagementService.addUser(formData).then(() => {
      showSuccessMessage('User has been added succesfully');
    });
    onSave();
  };

  return (
    <SidePanel onClose={onClose} title={'Create User'}>
      <form
        className="db-form"
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="db-form__structure">
          <span className="db-form__section-title">Basic information</span>
          <div className="db-form__row">
            <div className="db-form__col-0">
              <InputTextbox {...register('name')} label="Name" error={errors.name?.message}></InputTextbox>
            </div>
            <div className="db-form__col-0">
              <InputTextbox {...register('email')} label="Email" error={errors.email?.message}></InputTextbox>
            </div>
          </div>
        </div>
        <div className="db-form__actions">
          <DBButton name="simple" type="button" onClick={onClose} text="Cancel"></DBButton>
          <DBButton name="primary" type="submit" text="Save"></DBButton>
        </div>
      </form>
    </SidePanel>
  );
};
