import { observer } from 'mobx-react-lite';
import { type FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';
import { Alerts } from './Alerts/Alerts';
import './AlertsAndNotifications.scss';
import { Notifications } from './Notifications/Notifications';
import { Filters } from './Filters/Filters';
import { TaskType } from '@state/tasks/taskType';

interface IProps {
  onClose: () => void;
}

interface Tab {
  icon: string;
  type: TaskType;
  component: JSX.Element;
}

const tabs: Tab[] = [
  { icon: 'warning', type: TaskType.alert(), component: <Alerts /> },
  { icon: 'alert', type: TaskType.notification(), component: <Notifications /> },
];

export const AlertsAndNotifications: FunctionComponent<IProps> = observer(({ onClose }) => {
  const { t } = useTranslation('translation');
  const [selectedTab, setSelectedTab] = useState<Tab>(tabs[0]);
  return (
    <div className="alerts-notifications__wrapper-hero">
      <div className="alerts-notifications__wrapper">
        <div className="alerts-notifications__wrapper-in">
          <div className="alerts-notifications__header">
            <span className="alerts-notifications__title">{t('AlertsAndNotifications.Title')}</span>
            <Icon onClick={onClose} iconName="close"></Icon>
          </div>
          <div className="alerts-notifications__body">
            <div className="alerts-notifications__tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.type.id}
                  onClick={() => {
                    setSelectedTab(tab);
                  }}
                  className={`alerts-notifications__tab ${
                    tab.type.id === selectedTab.type.id ? 'selected' : ''
                  }`.trim()}
                >
                  <Icon iconName={tab.icon} />
                  <span>{t(`AlertsAndNotifications.Type.${tab.type.name}`)}</span>
                </div>
              ))}
            </div>
            <div className="alerts-notifications__content">
              <Filters taskType={selectedTab.type.id }/>
              {selectedTab.component}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
