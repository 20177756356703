import { sidePanelContext } from '../contexts/sidePanelContext';
import { type ReactNode, useState } from 'react';

interface IProps {
  children: ReactNode;
}

export const SidePanelProvider = ({ children }: IProps): JSX.Element => {
  const [alertsPanelOpen, setAlertsPanelOpen] = useState(false);
  const [myProfilePanelOpen, setMyProfilePanelOpen] = useState(false);
  const [rssPanelOpen, setRssPanelOpen] = useState(false);

  const toggleAlertsAndNotifications = (): void => {
    setRssPanelOpen(false);
    setMyProfilePanelOpen(false);
    setAlertsPanelOpen(!alertsPanelOpen);
  };

  const toggleMyProfile = (): void => {
    setRssPanelOpen(false);
    setAlertsPanelOpen(false);
    setMyProfilePanelOpen(!myProfilePanelOpen);
  };

  const toggleRss = (): void => {
    setMyProfilePanelOpen(false);
    setAlertsPanelOpen(false);
    setRssPanelOpen(!rssPanelOpen);
  };

  return (
    <sidePanelContext.Provider
      value={{
        toggleAlertsAndNotifications,
        toggleMyProfile,
        toggleRss,
        alertsPanelOpen,
        myProfilePanelOpen,
        rssPanelOpen,
      }}
    >
      {children}
    </sidePanelContext.Provider>
  );
};
