export class BlockSizeType {
  id: number;
  name: string;
  rows: number;
  columns: number;

  constructor(id: number, name: string, rows: number, columns: number) {
    this.id = id;
    this.name = name;
    this.rows = rows;
    this.columns = columns;
  }

  static oneByOne(): BlockSizeType {
    return new BlockSizeType(1, 'oneByOne', 1, 1);
  }

  static oneByTwo(): BlockSizeType {
    return new BlockSizeType(2, 'oneByTwo', 1, 2);
  }

  static twoByOne(): BlockSizeType {
    return new BlockSizeType(3, 'twoByOne', 2, 1);
  }

  static oneByThree(): BlockSizeType {
    return new BlockSizeType(4, 'oneByThree', 1, 3);
  }

  static twoByThree(): BlockSizeType {
    return new BlockSizeType(5, 'twoByThree', 2, 3);
  }

  static twoByTwo(): BlockSizeType {
    return new BlockSizeType(6, 'twoByTwo', 2, 2);
  }

  static getList(): BlockSizeType[] {
    return [this.oneByOne(), this.oneByTwo(), this.twoByOne(), this.oneByThree(), this.twoByThree(), this.twoByTwo()];
  }
}
