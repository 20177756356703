import { FilterOptions, type DataModel, type Filters } from '@components/dashboard/shared/filterOptions/FilterOptions';
import { ToolTipFormated } from '@components/dashboard/shared/ToolTipFormated';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { getColor } from '@shared/utils';
import { type Metadata, type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, type FunctionComponent } from 'react';
import './BarChart.scss';

const valueAxisLabels = {
  font: '11px Telefonica Web Regular, Arial, sans-serif',
};

const valueLegendLabels = {
  font: '11px Telefonica Web Regular, Arial, sans-serif',
};

const defaultTooltipRender = (value: Any) => {
  return <ToolTipFormated value={value} />;
};
export interface Props {
  data: MultipleBlockMetadata[];
  vertical?: boolean;
}

const emptyBarChart: MultipleBlockMetadata = {
  data: [],
  option: 'Empty',
};

export const BarChart: FunctionComponent<Props> = observer(({ data, vertical }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<Filters>({ filter: '' });
  const [barChart, setBarChart] = useState<MultipleBlockMetadata>(emptyBarChart);
  const [filterData, setFilterData] = useState<DataModel[]>([]);

  useEffect(() => {
    setFilterData(data.map((d) => ({ name: d.option, value: d.option })));
    setSelectedFilter({ filter: data.length > 0 ? data[0].option : '' });
  }, [data]);

  useEffect(() => {
    const dataSelected = data.find((Item) => Item.option === selectedFilter.filter);
    if (dataSelected === undefined) return;
    setBarChart(dataSelected);
  }, [selectedFilter]);

  const onChange = (event: Filters): void => {
    setSelectedFilter({ filter: event.filter });
  };

  const categories =
    data.length > 0 && data[0].data.length > 0 ? data[0].data[0].data.map((d: Metadata) => d.name) : [];

  return (
    <div className="barchart-container">
      {filterData.length > 1 && <FilterOptions data={filterData} value={selectedFilter} onChange={onChange} />}
      <Chart
        zoomable={false}
        style={{
          width: '100%',
          height: filterData.length > 1 ? 'calc(100% - 30px)' : '100%',
          background: 'transparent',
        }}
      >
        <ChartLegend align="start" position="top" orientation="horizontal" labels={valueLegendLabels} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} labels={valueAxisLabels} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem labels={valueAxisLabels} />
        </ChartValueAxis>
        <ChartTooltip shared={true} render={defaultTooltipRender} background="#000000" color="#FFFFFF" />
        <ChartSeries>
          {barChart?.data.map((element, index: number) => (
            <ChartSeriesItem
              gap={2}
              key={index}
              color={getColor(index)}
              type={vertical ? undefined : 'bar'}
              name={element.name}
              data={element.data}
            ></ChartSeriesItem>
          ))}
        </ChartSeries>
      </Chart>
    </div>
  );
});
