import { type Block } from '@state/block/models/block';
import { type Dashboard } from '../../state/block/models/dashboard';
import { mapDashboard } from './mappers/dashboardMapper';
import { type DashboardsClient } from './services';

interface CreateDashboardModel {
  name: string;
  description: string;
  isPublic: boolean;
  sharedUserSubs: string[];
}
export class DashboardsService {
  constructor(private readonly dashboardClient: DashboardsClient) {}

  async getAll(): Promise<Dashboard[]> {
    const data = await this.dashboardClient.dashboards();

    return data.map(mapDashboard);
  }

  async getById(id: number): Promise<Dashboard> {
    const data = await this.dashboardClient.dashboards3({ id });

    return mapDashboard(data);
  }

  async createDashboard(dashboard: CreateDashboardModel): Promise<void> {
    await this.dashboardClient.dashboards1({ body: dashboard });
  }

  async updateDashboard(dashboard: Dashboard): Promise<void> {
    const request = {
      id: dashboard.id,
      name: dashboard.name,
      description: dashboard.description,
      isPublic: dashboard.isPublic,
      sharedUserSubs: dashboard.sharedUserSubs,
    };
    await this.dashboardClient.dashboards2({ body: request });
  }

  async deleteDashboard(id: number): Promise<void> {
    await this.dashboardClient.dashboards4({ id });
  }

  async addBlock(block: Block): Promise<Block> {
    const response = await this.dashboardClient.addBlock({ id: block.dashboardId, body: block });

    return {
      ...response,
      dashboardId: block.dashboardId,
    };
  }

  async updateBlock(block: Block): Promise<Block> {
    const response = await this.dashboardClient.updateBlock({
      dashboardId: block.dashboardId,
      blockId: block.id,
      body: block,
    });

    return {
      ...response,
      dashboardId: block.dashboardId,
    };
  }

  async deleteBlock(dashboard: Dashboard, block: Block): Promise<void> {
    await this.dashboardClient.deleteBlock({
      dashboardId: dashboard.id,
      blockId: block.id,
    });
  }
}
