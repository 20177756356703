import { useAuth } from '@hooks/useAuth/useAuth';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@components/shared/Loader/Loader';
export const SigninCallbackPage = (): JSX.Element => {
  const auth = useAuth();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    auth.login().then(async (user) => {
      if (!user) await auth.logout();

      navigate(localStorage.getItem('redirectUri') ?? '/');
    });
  }, [auth]);

  return <Loader />;
};
