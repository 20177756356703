import { forwardRef, type Ref } from 'react';
import { type InputCommonProps } from '../form.model';
import { InputGroup } from '../input-group';
import {
  Calendar,
  type CalendarProps,
  DatePicker,
  type DatePickerHandle,
  type DatePickerProps,
} from '@progress/kendo-react-dateinputs';
import '@progress/kendo-theme-default/scss/calendar/_index.scss';
import '@progress/kendo-theme-default/scss/input/_index.scss';

type CustomDatePickerProps = InputCommonProps & DatePickerProps;

const DatePickerYear = forwardRef(
  ({ label, error, disabled, ...props }: CustomDatePickerProps, ref: Ref<DatePickerHandle>) => (
    <InputGroup label={label} error={error} disabled={disabled}>
      {() => <YearDatePicker ref={ref} {...props} />}
    </InputGroup>
  ),
);

const YearDatePicker = (props: Any): JSX.Element => {
  return <DatePicker {...props} format="yyyy" calendar={YearCalendar} />;
};
const YearCalendar = ({ value, onChange }: CalendarProps) => {
  return <Calendar bottomView="decade" value={value} onChange={onChange} />;
};

DatePickerYear.displayName = 'DatePickerYear';
export { DatePickerYear };
