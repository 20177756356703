/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { InputSelectMultple, type SimpleOption } from '@components/form/input-select-multiple/input-select-multiple';
import { InputTextbox } from '@components/form/input-text/input-text';
import { InputToogle } from '@components/form/toogle/toogle';
import { DBButton } from '@components/shared/button/button';
import { ModalDialog } from '@components/shared/ModalDialog/ModalDialog';
import { SidePanel } from '@components/shared/SidePanel/SidePanel';
import { DashboardsService } from '@core/services';
import { DashboardsClient } from '@core/services/services';
import userService, { type UserModel } from '@core/services/userService';
import { yupResolver } from '@hookform/resolvers/yup';
import { type Dashboard } from '@state/block/models/dashboard';
import { observer } from 'mobx-react-lite';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { showSuccessMessage } from '../../../utils';
import './DashboardForm.scss';
import { useAuth } from '@hooks/useAuth/useAuth';
import { useTranslation } from 'react-i18next';

interface IProps {
  dashboardId: number | null;
  onSave: () => void;
  onClose: () => void;
  onDelete: () => void;
}

const schema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
});
type FormData = yup.InferType<typeof schema>;

export const DashboardForm: FunctionComponent<IProps> = observer(({ dashboardId, onClose, onSave, onDelete }) => {
  const { t } = useTranslation('translation');
  const dashboardService = new DashboardsService(new DashboardsClient());
  const auth = useAuth();
  const [dashboard, setDashboard] = useState<Dashboard | null>(null);
  const [dashboardIdToDelete, setDashboardIdToDelete] = useState<number>();
  const [users, setUsers] = useState<UserModel[]>([]);
  const [sharedUserSubs, setSharedUserSubs] = useState<string[]>([]);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [sub, setSub] = useState<string>();
  const author: string = users?.find((u) => u.userSub === dashboard?.createdBy)?.name ?? '';

  useEffect(() => {
    userService.getAllUsers().then(setUsers);
    auth.getUser().then((user) => {
      setSub(user?.profile.sub);
    });
  }, []);

  useEffect(() => {
    if (dashboardId) {
      dashboardService.getById(dashboardId).then((dashboard) => {
        setDashboard(dashboard);
        setIsPublic(dashboard.isPublic);
        setSharedUserSubs(dashboard.sharedUserSubs);
      });
    }
  }, [dashboardId]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('name', dashboard?.name ?? '');
    setValue('description', dashboard?.description ?? '');
  }, [dashboard]);

  const onSubmit = async (formData: { name: string; description: string }) => {
    if (dashboardId) {
      await dashboardService
        .updateDashboard({ id: dashboardId, ...formData, isPublic, sharedUserSubs } as Dashboard)
        .then(() => {
          showSuccessMessage(`${t('Toaster.DashboardHasBeenUpdatedSuccesfully')}`);
          onSave();
        });
    } else {
      await dashboardService.createDashboard({ ...formData, isPublic, sharedUserSubs }).then(() => {
        showSuccessMessage(`${t('Toaster.DashboardHasBeenCreatedSuccesfully')}`);
        onSave();
      });
    }
  };

  const showDeleteDialog = (): void => {
    setDashboardIdToDelete(dashboard?.id);
  };
  const closeDeleteDialog = (): void => {
    setDashboardIdToDelete(undefined);
  };

  const onChangeUsers = (event: SimpleOption[]): void => {
    setSharedUserSubs(event.map((e: SimpleOption) => e.value));
  };

  const fieldsDisabled = sub !== dashboard?.createdBy && !!dashboardId;

  return (
    <SidePanel
      onClose={onClose}
      title={dashboard ? `${t('Dashboard.EditDashboard')} ${dashboard?.id}` : `${t('Dashboard.CreateDashboard')}`}
      subtitle={author && `${t('Dashboard.CreatedBy')}: ${author}`}
    >
      <>
        <form
          className="db-form"
          onSubmit={(e) => {
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="db-form__structure">
            <span className="db-form__section-title">{t('Dashboard.Form.BasicInformation')}</span>
            <div className="db-form__row">
              <div className="db-form__col-0">
                <InputTextbox
                  label={t('Shared.Name')}
                  {...register('name')}
                  defaultValue={dashboard?.name}
                  disabled={fieldsDisabled}
                  error={errors.name?.message}
                />
              </div>
            </div>
            <div className="db-form__row">
              <div className="db-form__col-0">
                <InputTextbox
                  label={t('Shared.Description')}
                  {...register('description')}
                  disabled={fieldsDisabled}
                  error={errors.description?.message}
                />
              </div>
            </div>
            <div className="db-form__row">
              <div className="db-form__col-0">
                <InputToogle
                  label={t('Shared.Public')}
                  value={isPublic ? 1 : undefined}
                  disabled={fieldsDisabled}
                  onChange={() => {
                    setIsPublic((isPublic) => !isPublic);
                  }}
                />
              </div>
            </div>
            {!isPublic && (
              <div className="db-form__row">
                <div className="db-form__col-0">
                  <InputSelectMultple
                    label={t('Shared.Users')}
                    name="countryIds"
                    values={sharedUserSubs}
                    options={users.map((u) => ({ label: u.name, value: u.userSub }))}
                    disabled={fieldsDisabled}
                    onChangeFullOption={onChangeUsers}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="db-form__actions">
            {dashboard ? (
              <DBButton
                name="simple-danger"
                type="button"
                onClick={showDeleteDialog}
                text={t('Shared.Delete')}
                disabled={fieldsDisabled}
              ></DBButton>
            ) : null}
            <DBButton name="simple" type="button" onClick={onClose} text={t('Shared.Cancel')}></DBButton>
            <DBButton name="primary" type="submit" text={t('Shared.Save')} disabled={fieldsDisabled}></DBButton>
          </div>
        </form>
        <ModalDialog
          acceptText={t('ModalDialog.YesDelete')}
          cancelText={t('ModalDialog.Cancel')}
          onAccept={onDelete}
          onCancel={closeDeleteDialog}
          visible={dashboardIdToDelete !== undefined}
          title={t('ModalDialog.DeleteDashboard')}
          message={t('ModalDialog.DeleteDashboardDescription')}
          icon="delete"
        ></ModalDialog>
      </>
    </SidePanel>
  );
});
