import {
  type DataCampusInfo,
  type GeneralStatusToday,
  type GetAllAlerts,
  type MostFrequentRoute,
  type WorkstreamKpiKpm,
} from '@core/services/homeService';
import { Application, type ApplicationName, type ApplicationIds } from '@core/services/models/application';
import { type ArenaInfoModel } from '@core/services/models/arenaInfoModel';
import { DashboardActionTypes } from '../actions/actionTypes';
import {
  type RemoveApplicationDataActionType,
  type DashboardAction,
  type LoadApplicationDataActionType,
  type LoadUserAppsActionType,
} from '../actions/dasboardActionCreator';

export interface DashboardState {
  userApplications: Application[];
  applicationsData: DashboardApplications;
}

export interface StorageApplicationModel<T> {
  data: T;
  lastUpdate: string;
}

export type DashboardApplications = Partial<{
  [ApplicationIds.DataCampus]: StorageApplicationModel<DataCampusInfo>;
  [ApplicationIds.Arca]: StorageApplicationModel<WorkstreamKpiKpm>;
  [ApplicationIds.Journey]: StorageApplicationModel<{
    status: GeneralStatusToday;
    alertsCount: GetAllAlerts;
    frequentRoute: MostFrequentRoute;
  }>;
  [ApplicationIds.Arena]: StorageApplicationModel<ArenaInfoModel>;
}>;

export interface MetricsUnit {
  description: string;
  value: number | string;
  link?: string;
  action?: () => any;
}
export interface FlightUnit {
  description: string;
  passengers: number;
  route: string;
  link?: string;
}

export const dashboardReducer = (
  state: DashboardState,
  action: DashboardAction<DashboardActionTypes>,
): DashboardState => {
  switch (action.type) {
    case DashboardActionTypes.LOAD_USER_APPS:
      return {
        ...state,
        userApplications: (action as LoadUserAppsActionType).payload
          .map((app) => Application.getByName(app.name as ApplicationName))
          .sort((a, b) => (a?.id && b?.id ? a?.id - b?.id : 0)) as Application[],
      };

    case DashboardActionTypes.LOAD_APPLICATION_DATA:
      return {
        ...state,
        applicationsData: {
          ...state.applicationsData,
          ...(action as LoadApplicationDataActionType).payload,
        },
      };

    case DashboardActionTypes.REMOVE_APPLICATION_DATA:
      return {
        ...state,
        applicationsData: {
          ...state.applicationsData,
          [(action as RemoveApplicationDataActionType).payload]: undefined,
        },
      };

    default:
      return { ...state };
  }
};
