import { FilterByOptions, OrderByOptions, Period } from '@components/shared/AlertsAndNotifications/Filters/Filters';
import { alertsService } from '@core/services/alertsService';
import { type AlertModel } from '@core/services/models/alertModel';
import { type NotificationItem } from '@core/services/models/notification';
import { TaskTypeEnum } from '@core/services/models/taskTypeEnum';
import { notificationService } from '@core/services/notificationsService';
import { uniq } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { TaskType } from './taskType';

const MILISECONDS_DAYS_CONVERSION = 1000 * 60 * 60 * 24;
const RECENT_DAYS = 7;
const ALL_APPS = 'All';
export interface TaskModel {
  id: number;
  type: TaskType;
  title: string;
  url: string;
  platform: string;
  showDate: Date;
}

export class TasksStore {
  alerts: AlertModel[] = [];
  notifications: NotificationItem[] = [];
  alertsFiltered: AlertModel[] = [];
  notificationsFiltered: NotificationItem[] = [];
  alertApps: string[] = [ALL_APPS];
  notificationApps: string[] = [ALL_APPS];

  constructor() {
    makeObservable(this, {
      alerts: observable,
      notifications: observable,
      alertsFiltered: observable,
      notificationsFiltered: observable,
      tasks: computed,
      loadTasks: action,
      filterTasks: action,
      readNotification: action,
      deleteNotification: action,
      readAlert: action,
      deleteAlert: action,
    });
  }

  get tasks(): TaskModel[] {
    const alertTasks = this.alerts
      .filter((a) => !a.read && a.taskType === TaskTypeEnum.Task)
      .map((alert) => ({ ...alert, type: TaskType.alert() }));
    const notificationTaks = this.notifications
      .filter((n) => !n.read && n.taskType === TaskTypeEnum.Task)
      .map((notification) => ({ ...notification, type: TaskType.notification() }));

    return [...alertTasks, ...notificationTaks];
  }

  async loadTasks(): Promise<void> {
    if (this.alerts.length === 0 && this.notifications.length === 0) {
      const alerts = await alertsService.getAll();
      const notifications = await notificationService.getAll();

      runInAction(() => {
        this.alerts = alerts;
        this.notifications = notifications;
        this.alertsFiltered = this.alerts;
        this.notificationsFiltered = this.notifications;
        this.alertApps = uniq([...this.alertApps, ...alerts.map((alert) => alert.platform)]);
        this.notificationApps = uniq([
          ...this.notificationApps,
          ...notifications.map((notification) => notification.platform),
        ]);
      });
    }
  }

  get taskCount(): number {
    return this.tasks.length;
  }

  get alertCount(): number {
    return this.alerts.length;
  }

  filterTasks(
    type: number,
    filterSearch: string,
    selectedPeriod: Period,
    filterBy: FilterByOptions,
    filterApp: string,
    orderBy: OrderByOptions,
  ): void {
    runInAction(() => {
      if (type === TaskType.alert().id) {
        this.alertsFiltered = this.filterAndSorttasks(
          this.alerts,
          filterSearch,
          selectedPeriod,
          filterBy,
          filterApp,
          orderBy,
        );
      }
      if (type === TaskType.notification().id) {
        this.notificationsFiltered = this.filterAndSorttasks(
          this.notifications,
          filterSearch,
          selectedPeriod,
          filterBy,
          filterApp,
          orderBy,
        );
      }
    });
  }

  private filterAndSorttasks(
    task: AlertModel[] | NotificationItem[],
    filterSearch: string,
    selectedPeriod: Period,
    filterBy: FilterByOptions,
    filterApp: string,
    orderBy: OrderByOptions,
  ): AlertModel[] | NotificationItem[] {
    return task
      .filter(
        ({ message, title }) =>
          message.toLowerCase().includes(filterSearch.toLowerCase()) ||
          title.toLowerCase().includes(filterSearch.toLowerCase()),
      )
      .filter(({ showDate }) =>
        selectedPeriod === Period.LastDays
          ? (new Date().getTime() - new Date(showDate).getTime()) / MILISECONDS_DAYS_CONVERSION < RECENT_DAYS
          : (new Date().getTime() - new Date(showDate).getTime()) / MILISECONDS_DAYS_CONVERSION >= RECENT_DAYS,
      )
      .filter(({ platform }) => filterApp === platform || ALL_APPS === filterApp)
      .filter(({ read }) => (filterBy === FilterByOptions.all ? true : !read))
      .sort((a, b) => {
        switch (orderBy) {
          case OrderByOptions.dateAsc:
            return new Date(a.showDate).getTime() - new Date(b.showDate).getTime();
          case OrderByOptions.dateDesc:
            return new Date(b.showDate).getTime() - new Date(a.showDate).getTime();
          case OrderByOptions.za:
            return b.title.localeCompare(a.title);
          case OrderByOptions.az:
          default:
            return a.title.localeCompare(b.title);
        }
      });
  }

  async readNotification(id: number): Promise<void> {
    await notificationService.markAsRead(id);
    const notification = this.notifications.find((n) => n.id === id);
    if (notification) {
      runInAction(() => {
        notification.read = true;
      });
    }
  }

  async reaAlldNotification(): Promise<void> {
    await notificationService.markAllAsRead();
    runInAction(() => {
      this.notifications.map((notification) => (notification.read = true));
    });
  }

  async deleteNotification(id: number): Promise<void> {
    await notificationService.delete(id);
    runInAction(() => {
      this.notifications = this.notifications.filter((n) => n.id !== id);
    });
  }

  async readAlert(id: number): Promise<void> {
    await alertsService.markAsRead(id);
    const alert = this.alerts.find((a) => a.id === id);
    if (alert) {
      runInAction(() => {
        alert.read = true;
      });
    }
  }

  async deleteAlert(id: number): Promise<void> {
    await alertsService.delete(id);
    runInAction(() => {
      this.alerts = this.alerts.filter((a) => a.id !== id);
    });
  }
}
