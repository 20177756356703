import { Icon } from '@components/shared/Icon/Icon';
import { Application, ApplicationIds } from '@core/services/models/application';
import { useDashboard } from '@hooks/useDashboald/useDashboard';
import { useDashboardContext } from '@state/dashboard/context/DasboardContext';
import { observer } from 'mobx-react-lite';
import { useEffect, type FunctionComponent, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './Home.scss';
import { ArcaMetricsGroup } from './metricGroups/ArcaMetricsGroup';
import { ArenaMetricsGroup } from './metricGroups/ArenaMetricsGroup';
import { DatacampusMetricsGroup } from './metricGroups/DatacampusMetricsGroup';
import { JourneyMetricsGroup } from './metricGroups/JourneyMetricsGroup';

const applicationsMetricsGroups: Record<number, ReactNode> = {
  [ApplicationIds.DataCampus]: <DatacampusMetricsGroup />,
  [ApplicationIds.Arca]: <ArcaMetricsGroup />,
  [ApplicationIds.Arena]: <ArenaMetricsGroup />,
  [ApplicationIds.Journey]: <JourneyMetricsGroup />,
};

export const Home: FunctionComponent = observer(() => {
  const { getUserApplications, reloadAppData } = useDashboard();
  const { userApplications, applicationsData } = useDashboardContext();
  const { t } = useTranslation('translation');

  useEffect(() => {
    getUserApplications();
  }, []);

  return (
    <div className="home">
      <ul className="home__list">
        {userApplications.map((app, i) => (
          <li className={`app app--${app.formattedName}`} key={app.id} style={{ animationDelay: `${(i + 1) * 0.1}s` }}>
            <div className="app__card">
              <h2 className="app__title">{app.name}</h2>
              <img className="app__image" src={app.image} alt={app.name} />
              {app.id !== Application.dataCampus()?.id && (
                <a className="app__link" href={app.url} rel="noreferrer" target="_blank">
                  {t('Home.GoTo', { app: app.name })}
                </a>
              )}
            </div>
            <article className="app__metrics">
              <header className="metrics-header">
                <h2 className="metrics-header__title">
                  <span className="metrics-header__title metrics-header__title--highlight">{app.name}</span>
                  {' - '}
                  {t(`Home.Applications.${app.name}`)}
                </h2>
                <div className="metrics-header__date-info">
                  <span>{`${t('Home.LastUpdate')}: ${applicationsData[app.id]?.lastUpdate ?? ''}`}</span>
                  <button
                    onClick={() => {
                      reloadAppData(app.id);
                    }}
                  >
                    <Icon iconName="reload"></Icon>
                  </button>
                </div>
              </header>
              <div className="metrics-wrapper">{applicationsMetricsGroups[app.id]}</div>
            </article>
          </li>
        ))}
      </ul>
    </div>
  );
});
