import { AppConfiguration, type Settings } from '@core/config/appConfiguration';
import { Log, UserManager, type User, type UserManagerSettings } from 'oidc-client-ts';

const settingsMapper = (settings: Settings): UserManagerSettings => ({
  authority: settings.oidc.authority,
  client_id: settings.oidc.clientId,
  client_secret: settings.oidc.clientSecret,
  redirect_uri: `${settings.oidc.clientRoot}/signin-callback`,
  post_logout_redirect_uri: settings.oidc.clientRoot,
  response_type: 'code',
  scope: settings.oidc.clientScope,
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: false,
});

export class Auth {
  private static instance: Auth;
  private readonly userManagerSettings: Settings;
  private readonly userManager: UserManager;
  private currentlySigninIn: boolean = false;

  constructor() {
    this.userManagerSettings = AppConfiguration.getInstance().settings;
    this.userManager = new UserManager(settingsMapper(this.userManagerSettings));

    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);
    this.userManager.startSilentRenew();
    this.userManager.events.addSilentRenewError((e: Error) => {
      console.log(`Silent Renew Error: ${e.message}`);
    });

    this.userManager.events.addAccessTokenExpiring(() => {
      console.log('Token expiring soon');
    });

    this.userManager.events.addUserLoaded(async () => {
      await this.updateToken();
    });
  }

  async updateToken(): Promise<void> {
    const user = await this.getUser();
    if (user) {
      sessionStorage.setItem('token', `${user.token_type} ${user.access_token}`);
    }
  }

  async loginRedirect(): Promise<void> {
    if (!this.currentlySigninIn) {
      this.savePath();
      await this.userManager.signinRedirect();
    }
  }

  async login(): Promise<User | null> {
    this.currentlySigninIn = true;
    const user = await this.userManager.signinCallback();
    this.currentlySigninIn = false;
    return user ?? null;
  }

  async getUser(): Promise<User | null> {
    return await this.userManager.getUser();
  }

  get userKey(): string {
    return `oidc.user:${this.userManagerSettings.oidc.authority}:${this.userManagerSettings.oidc.clientId}`;
  }

  isAuthenticated(): boolean {
    const authStorage: User = JSON.parse(sessionStorage.getItem(this.userKey) ?? '{}');
    if (authStorage.access_token) {
      return true;
    }
    return !!authStorage.access_token;
  }

  async logout(): Promise<void> {
    await this.userManager.clearStaleState();
    await this.userManager.signoutRedirect({ id_token_hint: localStorage.getItem('id_token') ?? '' });
  }

  savePath(): void {
    localStorage.setItem('redirectUri', window.location.pathname);
  }

  static getInstance(): Auth {
    this.instance = this.instance || new Auth();
    return this.instance;
  }
}
