import { ComplexChart } from '@components/dashboard/complexChart/complexChart';
import { FeaturedRoute } from '@components/dashboard/featuredRoute/featuredRoute';
import { GeneralRow } from '@components/dashboard/generalRowValues/generalRowValues';
import { MapChart } from '@components/dashboard/mapChart/MapChart';
import { WorldMap } from '@components/dashboard/mapChart/worldMap/WorldMap';
import { AlphanumericChart } from '@components/dashboard/simpleChart/alphanumericChart/AlphanumericChart';
import { BarChart } from '@components/dashboard/simpleChart/barChart/BarChart';
import { BarChartPercentage } from '@components/dashboard/simpleChart/barChartPercentage/BarChartPercentage';
import { BarChartWithOptions } from '@components/dashboard/simpleChart/barChartWithOptions/BarChartWithOptions';
import { DonutChart } from '@components/dashboard/simpleChart/donutChart/DonutChart';
import { Sparkline } from '@components/dashboard/simpleChart/sparkLine/Sparkline';
import { TableReports } from '@components/dashboard/tableReports/tableReports';
import { TableRisks } from '@components/dashboard/tableRisks/tableRisks';
import { Dialog } from '@progress/kendo-react-dialogs';
import { type Block } from '@state/block/models/block';
import { ChartType } from '@state/block/models/chartType';
import { Widget } from '@state/block/models/widget';
import { type FunctionComponent } from 'react';
import { Icon } from '../Icon/Icon';
import './Modal.scss';

const chartGroupTypes = {
  [ChartType.sparkline().id]: Sparkline,
  [ChartType.multipleBar().id]: BarChart,
  [ChartType.bubble().id]: MapChart,
  [ChartType.heat().id]: MapChart,
  [ChartType.generalRow().id]: GeneralRow,
  [ChartType.tableReport().id]: TableReports,
  [ChartType.tableRisks().id]: TableRisks,
  [ChartType.barChart().id]: BarChartWithOptions,
  [ChartType.featuredRoute().id]: FeaturedRoute,
  [ChartType.donutChart().id]: DonutChart,
  [ChartType.worldMap().id]: WorldMap,
  [ChartType.barPercentage().id]: BarChartPercentage,
  [ChartType.verticalBarChart().id]: BarChartWithOptions,
  [ChartType.complexChart().id]: ComplexChart,
  [ChartType.alphanumericChart().id]: AlphanumericChart,
};

export interface ModalProps {
  visible: boolean;
  block: Block;
  data: Any;
  onCancel?: () => void;
}
export const Modal: FunctionComponent<ModalProps> = (props: ModalProps) => {
  const widget = Widget.getById(props.block.widgetId);
  const Chart = chartGroupTypes[widget ? widget.chartType.id : 1];
  return props.visible ? (
    <Dialog className="db-modal">
      <div className="db-modal-dialog__header">
        <h2 className="db-modal-title">{props.block.title}</h2>
        <Icon iconName="close" onClick={props.onCancel}></Icon>
      </div>
      <div className="db-modal__body">
        {<Chart isModal data={props.data} widget={widget} chartType={widget?.chartType as ChartType} />}
      </div>
    </Dialog>
  ) : null;
};
