import { SidePanel } from '@components/shared/SidePanel/SidePanel';
import { Application } from '@core/services/models/application';
import { DataType } from '@state/block/models/dataType';
import { observer } from 'mobx-react-lite';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './ApplicationsList.scss';
import { FolderItem } from './FolderItem/FolderItem';

interface IApplicationsListProps {
  onCloseApplication: () => void;
  onSelectDataType: (dataType: DataType) => void;
}

export const ApplicationsList: FunctionComponent<IApplicationsListProps> = observer(
  ({ onCloseApplication, onSelectDataType }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'Dashboard.Form' });

    return (
      <SidePanel onClose={onCloseApplication} title={t('SelectWidgetData')}>
        <div className="db-side-panel-application__body">
          {Application.getList().map(
            (application, index) =>
              DataType.getListByApplication(application.id).length > 0 && (
                <FolderItem
                  key={`folder_${index}`}
                  application={application}
                  onSelectChildren={onSelectDataType}
                ></FolderItem>
              ),
          )}
        </div>
      </SidePanel>
    );
  },
);
