import { forwardRef, type Ref } from 'react';
import { type InputCommonProps } from '../form.model';
import { InputGroup } from '../input-group';
import './input-number.scss';

type InputNumberProps = InputCommonProps & React.InputHTMLAttributes<HTMLInputElement>;

const InputNumber = forwardRef(({ label, error, disabled, ...props }: InputNumberProps, ref: Ref<HTMLInputElement>) => (
  <InputGroup label={label} error={error} disabled={disabled}>
    {({ hasError }) => (
      <input className="db-input-number" type="number" disabled={disabled} {...props} ref={ref} autoComplete="off" />
    )}
  </InputGroup>
));

InputNumber.displayName = 'InputNumber';
export { InputNumber };
