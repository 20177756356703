import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import './PermissionsManagementSection.scss';

export const PermissionsManagementSection: FunctionComponent = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PermissionsManagement' });
  return (
    <div className="permissions-management__wrapper">
      <span className="permissions-management__title">{t('PermissionsManagement')}</span>
      <Outlet />
    </div>
  );
};
