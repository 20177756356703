import { type WorkstreamModel } from './models/workstreamModel';
import { ArcaClient } from './services';

class ArcaService {
  async getWorkstreams(): Promise<WorkstreamModel[]> {
    return (await arcaClient.workstreams()) || [];
  }
}
const arcaClient = new ArcaClient();
export const arcaService = new ArcaService();
