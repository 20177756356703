import { type BlockMetadata, type MultipleBlockMetadata } from './../../../state/block/models/blockMetadata';
import { type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByCountries } from './../services';

export const mapPassengerByYearAndCountryBar = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByCountries[],
): MultipleBlockMetadata[] => {
  return response.map((item) => ({
    option: item.year.toString(),
    data: mapPassengerByYearAndCountryBarBlockMeta(item),
  }));
};

export const mapPassengerByYearAndCountryBarBlockMeta = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByCountries,
): BlockMetadata[] => {
  const passengerNational = response.passengersByYearAndCountries.map((item) => ({
    name: item.country,
    value: item.passengerNational,
  }));
  const passengerInternational = response.passengersByYearAndCountries.map((item) => ({
    name: item.country,
    value: item.passengerInternational,
  }));
  return [
    { name: 'Passenger National', data: passengerNational },
    { name: 'Passenger International', data: passengerInternational },
  ];
};
