import Highcharts, { Options, SeriesOptionsType, PointOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BlockMetadata } from '@state/block/models/blockMetadata';
import React from 'react';
import { getAllColors } from '@shared/utils';
import './PieChart.scss';

export interface PieChartProps {
  data: BlockMetadata[];
}

export const PieChart = ({ data }: PieChartProps): JSX.Element => {
  const options: Options = {
    colors: Highcharts.map(getAllColors(), function (color: string) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7,
        },
        stops: [
          [0, color],
          [1, Highcharts.color(color).brighten(-0.3).get('rgb')],
        ],
      };
    }),
    chart: {
      backgroundColor: 'transparent',
      plotShadow: false,
      type: 'pie',
    },
    title: { text: '' },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        borderColor: 'transparent',
        dataLabels: {
          color: '#fff',
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          connectorColor: 'silver',
          style: {
            fontFamily: 'Telefonica Web Regular, Arial, sans-serif',
          },
        },
      },
    },
    series: data.map<SeriesOptionsType>((serie) => ({
      type: 'pie',
      name: serie.name,
      data: serie.data.map<PointOptionsObject>((point) => ({
        name: point.name,
        y: point.value,
      })),
    })),
  };

  return (
    <div className="pie-chart__wrapper">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
