/* eslint-disable @typescript-eslint/naming-convention */
import { type BlockMetadata, type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import { type Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths } from '../services';

export const mapPassengersByYearAndMonthsMultipleBar = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths[],
): MultipleBlockMetadata[] => {
  return [
    {
      option: 'Single',
      data: mapTelefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonthsList(
        response,
      ),
    },
  ];
};

export const mapTelefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonthsList = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths[],
): BlockMetadata[] => {
  return response.map(mapTelefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths);
};

export const mapTelefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths,
): BlockMetadata => {
  return {
    name: response.year.toString(),
    data: [
      { name: 'January', value: response.valuesByMonthAndYear.january },
      { name: 'February', value: response.valuesByMonthAndYear.february },
      { name: 'March', value: response.valuesByMonthAndYear.march },
      { name: 'April', value: response.valuesByMonthAndYear.april },
      { name: 'June', value: response.valuesByMonthAndYear.june },
      { name: 'July', value: response.valuesByMonthAndYear.july },
      { name: 'August', value: response.valuesByMonthAndYear.august },
      { name: 'September', value: response.valuesByMonthAndYear.september },
      { name: 'October', value: response.valuesByMonthAndYear.october },
      { name: 'November', value: response.valuesByMonthAndYear.november },
      { name: 'December', value: response.valuesByMonthAndYear.december },
    ],
  };
};
