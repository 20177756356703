import React from 'react';
import './generalRowValues.scss';

interface IGeneralRowValuesProps {
  lastUpdate?: string;
  props?: IGeneralRowItemValuesProps[];
}

interface IGeneralRowItemValuesProps {
  id: number;
  label: string;
  url?: string;
  value: string;
}

export const GeneralRow: React.FC = () => {
  const listElements: IGeneralRowItemValuesProps[] = [
    {
      id: 0,
      label: 'Test1',
      url: 'www.google.es',
      value: '3',
    },
    {
      id: 1,
      label: 'Test2',
      url: 'www.google.es',
      value: '120',
    },
    {
      id: 2,
      label: 'Test3',
      url: 'www.google.es',
      value: '60',
    },
    {
      id: 3,
      label: 'Test4',
      url: 'www.google.es',
      value: '13',
    },
  ];
  const generalRow: IGeneralRowValuesProps = {
    lastUpdate: '02/05/2021',
    props: listElements,
  };
  return (
    <div className="dashboard-general-row-values">
      {generalRow.lastUpdate ? (
        <p className="dashboard-general-row-values__update">
          Last update: <span>{generalRow.lastUpdate}</span>
        </p>
      ) : null}
      <div className="dashboard-general-row-values__items-wrapper">
        {listElements.map((element) => (
          <div key={`indicator_${element.id}`} className="dashboard-general-row-values__item">
            <span className="dashboard-general-row-values__value">{element.value}</span>
            <span className="dashboard-general-row-values__label">{element.label}</span>
            <a className="dashboard-general-row-values__url" href={element.url}>
              View
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
