import './CustomGrid.scss';
import { Grid, type GridCellProps, type GridProps } from '@progress/kendo-react-grid';
import { type PropsWithChildren } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';

export const TooltipCell = ({ dataItem, field }: GridCellProps): JSX.Element => {
  const text = dataItem[field ?? ''];

  return <td title={text}>{text}</td>;
};

export const CustomGrid = ({
  pageable,
  sortable,
  filterable,
  resizable,
  reorderable,
  groupable,
  ...props
}: PropsWithChildren<GridProps>): JSX.Element => {
  const gridProps: GridProps = {
    ...props,
    pageable: pageable ?? false,
    sortable: sortable ?? true,
    filterable: filterable ?? true,
    resizable: resizable ?? true,
    reorderable: reorderable ?? true,
    groupable: groupable ?? false,
  };

  return (
    <Tooltip anchorElement="target">
      <Grid {...gridProps} />
    </Tooltip>
  );
};
