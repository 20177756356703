import { type Application } from '@core/services/models/application';
import { DataType } from '@state/block/models/dataType';
import { useState, type FunctionComponent } from 'react';
import './FolderItem.scss';

export interface Props {
  application: Application;
  onSelectChildren: (dataType: DataType) => void;
}

export const FolderItem: FunctionComponent<Props> = ({ application, onSelectChildren }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="folder">
      <div
        className="folder__row"
        onClick={() => {
          setExpanded((expanded) => !expanded);
        }}
      >
        <ul key={`indicator_${application.id}`} className="users-assignment-list__wrapper">
          <span className="db-form__section-title">{application.name}</span>
        </ul>
      </div>

      {expanded &&
        DataType.getListByApplication(application.id).map((dataType, index) => (
          <li
            key={`children_${index}`}
            onClick={() => {
              onSelectChildren(dataType);
            }}
            className="users-assignment-list__wrapper--row"
          >
            {dataType.name}
          </li>
        ))}
    </div>
  );
};
