import { donutOptimizedHoursMapper } from '@core/services/mappers/donutOptimizedHoursMapper';
import { mapDonutPassengerByFlightTypeByYearDonut } from '@core/services/mappers/donutPassengerByFlightTypeByYearMapper';
import { donutPostsByCountryAndModuleMapper } from '@core/services/mappers/donutPostsByCountryAndModuleMapper';
import { mapFacilitiesByType } from '@core/services/mappers/facilitiesByTypeMapper';
import { mapFlightsBycountriesAndMonths } from '@core/services/mappers/flightsbycountriesandmonthsMapper';
import { mapFlightsbycountriesandmonthsPercentage } from '@core/services/mappers/flightsbycountriesandmonthsPercentageMapper';
import {
  mapFlightsPercentageByCountryToHeatMap,
  mapFlightsPercentageByCountryToTableReport,
} from '@core/services/mappers/flightsPercentageByCountryToHeatMapper';
import { mapGlobalSupervanity } from '@core/services/mappers/globalSupervanityMapper';
import {
  mapArrivalsFlightBarChar,
  mapFlightsByCountries,
  mapFlightsbyCountriesaAndMonths,
  mapFlightsPassengersByCountries,
} from '@core/services/mappers/journeyMapper';
import { mapOptimizationbycountryandstateMultiple } from '@core/services/mappers/optimizationbycountryandstatePercentageMapper';
import { mapPassengerByYearAndCountryBar } from '@core/services/mappers/passengerByYearAndCountryMapper';
import { mapPassengersByYearAndMonthsMultipleBar } from '@core/services/mappers/passengersByYearAndMonthsMultipleBarMapper';
import { mapPassengersByYearAndMonthsSparkLine } from '@core/services/mappers/passengersByYearAndMonthsSparkLineMapper';
import { mapPostsByCountryToBarChart } from '@core/services/mappers/postsByCountryMapper';
import { mapPostsByPostProfileAndCountryPercentage } from '@core/services/mappers/postsByPostProfileAndCountryMapper';
import { mapPostsByProviderToBarChart } from '@core/services/mappers/postsByProviderMapper';
import i18n from '../../../I18n';
import { mapFacilitiesByCriticality } from './../../../core/services/mappers/facilitiesByCriticalityMapper';
import { mapPostsBymoduleToBarChart } from './../../../core/services/mappers/postsByCountryAndModuleMapper';
import { BlockSizeType } from './blockSizeType';
import { ChartType } from './chartType';
import { DataType } from './dataType';
import { type NoParams, type TableReportFlightsByCountriesAndMonthsParams } from './parametersType';

export interface WidgetOption {
  name: string;
  value: Any;
}

export enum FilterTypes {
  countries,
  countriesWithAll,
  workstreams,
  noFilter,
}

export class Widget<P> {
  id: number;
  description: string;
  dataType: DataType;
  filterType: FilterTypes;
  chartType: ChartType;
  sizes: BlockSizeType[];
  mapCall?: (value: Any) => Any;
  parameters?: P;
  options?: WidgetOption[];

  constructor(
    id: number,
    description: string,
    filterType: FilterTypes,
    dataType: DataType,
    chartType: ChartType,
    sizes: BlockSizeType[],
    mapCall?: (value: Any) => Any,
    parameters?: P,
    options?: WidgetOption[],
  ) {
    this.id = id;
    this.description = description;
    this.filterType = filterType;
    this.dataType = dataType;
    this.chartType = chartType;
    this.sizes = sizes;
    this.mapCall = mapCall;
    this.parameters = parameters;
    this.options = options;
  }

  static barEvolutionFlights(): Widget<NoParams> {
    return new Widget(
      18,
      i18n.t('Dashboard.Form.BarEvolutionFlightsDescription'),
      FilterTypes.countriesWithAll,
      DataType.passengersToMonthsByYear(),
      ChartType.multipleBar(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByThree()],
      mapPassengersByYearAndMonthsMultipleBar,
    );
  }

  static sparkLineEvolutionFlights(): Widget<NoParams> {
    return new Widget(
      19,
      i18n.t('Dashboard.Form.SparkLineEvolutionFlightsDescription'),
      FilterTypes.countriesWithAll,
      DataType.passengersToMonthsByYear(),
      ChartType.sparkline(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapPassengersByYearAndMonthsSparkLine,
    );
  }

  static multipleBarInternationalDepartureFlights(): Widget<NoParams> {
    return new Widget(
      2,
      i18n.t('Dashboard.Form.MultipleBarInternationalDepartureFlightsDescription'),
      FilterTypes.countriesWithAll,
      DataType.internationalDepartureFlights(),
      ChartType.multipleBar(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapArrivalsFlightBarChar,
    );
  }

  static tableReportInternationalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      9,
      i18n.t('Dashboard.Form.TableReportInternationalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countriesWithAll,
      DataType.internationalDeparturesByCountry(),
      ChartType.tableReport(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsbyCountriesaAndMonths,
    );
  }

  static sparkLineReportInternationalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      10,
      i18n.t('Dashboard.Form.SparkLineReportInternationalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countries,
      DataType.internationalDeparturesByCountry(),
      ChartType.sparkline(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsBycountriesAndMonths,
    );
  }

  static tableReportFlightsPassengersByCountries(): Widget<NoParams> {
    return new Widget(
      4,
      i18n.t('Dashboard.Form.TableReportFlightsPassengersByCountriesDescription'),
      FilterTypes.countriesWithAll,
      DataType.domesticFlightsAndPassengers(),
      ChartType.tableReport(),
      [BlockSizeType.twoByOne()],
      mapFlightsPassengersByCountries,
    );
  }

  static tableReportNationalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      5,
      i18n.t('Dashboard.Form.TableReportNationalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countriesWithAll,
      DataType.domesticFlights(),
      ChartType.tableReport(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsbyCountriesaAndMonths,
    );
  }

  static sparkLineReportNationalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      6,
      i18n.t('Dashboard.Form.SparkLineReportNationalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countries,
      DataType.domesticFlights(),
      ChartType.sparkline(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsBycountriesAndMonths,
    );
  }

  static tableReportNationalFlightsPercentageByCountries(): Widget<NoParams> {
    return new Widget(
      7,
      i18n.t('Dashboard.Form.TableReportNationalFlightsPercentageByCountriesDescription'),
      FilterTypes.countriesWithAll,
      DataType.domesticFlightsComparison(),
      ChartType.tableReport(),
      [BlockSizeType.twoByOne()],
      mapFlightsPercentageByCountryToTableReport,
    );
  }

  static worldMapNationalFlightsPercentageByCountries(): Widget<NoParams> {
    return new Widget(
      8,
      i18n.t('Dashboard.Form.WorldMapNationalFlightsPercentageByCountriesDescription'),
      FilterTypes.countriesWithAll,
      DataType.domesticFlightsComparison(),
      ChartType.heat(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo()],
      mapFlightsPercentageByCountryToHeatMap,
    );
  }

  static barChartPercentageReportInternationalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      11,
      i18n.t('Dashboard.Form.BarChartPercentageReportInternationalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countries,
      DataType.internationalDeparturesByCountry(),
      ChartType.barPercentage(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsbycountriesandmonthsPercentage,
    );
  }

  static tableReportFlightsByCountries(): Widget<TableReportFlightsByCountriesAndMonthsParams> {
    return new Widget(
      12,
      i18n.t('Dashboard.Form.TableReportFlightsByCountriesDescription'),
      FilterTypes.countriesWithAll,
      DataType.nationalAndInternationalFlights(),
      ChartType.tableReport(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo()],
      mapFlightsByCountries,
    );
  }

  static barPassengerByYearAndCountry(): Widget<NoParams> {
    return new Widget(
      13,
      i18n.t('Dashboard.Form.BarPassengerByYearAndCountryDescription'),
      FilterTypes.countriesWithAll,
      DataType.nationalAndInternationalPassengers(),
      ChartType.multipleBar(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo()],
      mapPassengerByYearAndCountryBar,
    );
  }

  static donutPassengerByFlightTypeByYear(): Widget<NoParams> {
    return new Widget(
      14,
      i18n.t('Dashboard.Form.DonutPassengerByFlightTypeByYearDescription'),
      FilterTypes.countriesWithAll,
      DataType.passengersByflightType(),
      ChartType.donutChart(),
      [BlockSizeType.twoByOne()],
      mapDonutPassengerByFlightTypeByYearDonut,
    );
  }

  static barPercentagePostsByCodeGroupingAndCountry(): Widget<number | null> {
    return new Widget(
      15,
      i18n.t('Dashboard.Form.BarPercentagePostsByCodeGroupingAndCountryDescription'),
      FilterTypes.countriesWithAll,
      DataType.postsByPostProfile(),
      ChartType.barPercentage(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapPostsByPostProfileAndCountryPercentage,
    );
  }

  static donutPostsByModuleAndCountry(): Widget<number | null> {
    return new Widget(
      16,
      i18n.t('Dashboard.Form.DonutPostsByModuleAndCountryDescription'),
      FilterTypes.countriesWithAll,
      DataType.postsByModule(),
      ChartType.donutChart(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo()],
      donutPostsByCountryAndModuleMapper,
    );
  }

  static barPostsByModuleAndCountry(): Widget<number | null> {
    return new Widget(
      22,
      i18n.t('Dashboard.Form.BarPostsByModuleAndCountryDescription'),
      FilterTypes.countriesWithAll,
      DataType.postsByModule(),
      ChartType.verticalBarChart(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapPostsBymoduleToBarChart,
    );
  }

  static barChartPostsByProvider(): Widget<NoParams> {
    return new Widget(
      20,
      i18n.t('Dashboard.Form.BarChartPostsByProviderDescription'),
      FilterTypes.countriesWithAll,
      DataType.postsByProvider(),
      ChartType.barChart(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapPostsByProviderToBarChart,
    );
  }

  static barChartPostsByCountry(): Widget<NoParams> {
    return new Widget(
      21,
      i18n.t('Dashboard.Form.BarChartPostsByCountryDescription'),
      FilterTypes.countriesWithAll,
      DataType.postsByCountry(),
      ChartType.barChart(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapPostsByCountryToBarChart,
    );
  }

  static facilitiesByCriticity(): Widget<NoParams> {
    return new Widget(
      23,
      i18n.t('Dashboard.Form.FacilitiesByCriticityDescription'),
      FilterTypes.countriesWithAll,
      DataType.facilitiesByCriticity(),
      ChartType.donutChart(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo()],
      mapFacilitiesByCriticality,
    );
  }

  static facilitiesByType(): Widget<NoParams> {
    return new Widget(
      24,
      i18n.t('Dashboard.Form.FacilitiesByTypeDescription'),
      FilterTypes.countriesWithAll,
      DataType.facilitiesByType(),
      ChartType.donutChart(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo()],
      mapFacilitiesByType,
    );
  }

  static globalSupervanityComplexChart(): Widget<number> {
    return new Widget(
      25,
      i18n.t('Dashboard.Form.GlobalSupervanityComplexChartDescription'),
      FilterTypes.workstreams,
      DataType.globalSupervanity(),
      ChartType.complexChart(),
      [BlockSizeType.twoByOne()],
      mapGlobalSupervanity,
    );
  }

  static barChartPercentageReportInternationalArrivalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      26,
      i18n.t('Dashboard.Form.BarChartPercentageReportInternationalArrivalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countries,
      DataType.internationalArrivalsByCountry(),
      ChartType.barPercentage(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsbycountriesandmonthsPercentage,
    );
  }

  static tableReportInternationalArrivalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      27,
      i18n.t('Dashboard.Form.TableReportInternationalArrivalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countriesWithAll,
      DataType.internationalArrivalsByCountry(),
      ChartType.tableReport(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsbyCountriesaAndMonths,
    );
  }

  static sparkLineReportInternationalArrivalFlightsByCountriesAndMonths(): Widget<number> {
    return new Widget(
      28,
      i18n.t('Dashboard.Form.SparkLineReportInternationalArrivalFlightsByCountriesAndMonthsDescription'),
      FilterTypes.countries,
      DataType.internationalArrivalsByCountry(),
      ChartType.sparkline(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapFlightsBycountriesAndMonths,
    );
  }

  static barChartPercentageOptimizationsByCountryAndState(): Widget<NoParams> {
    return new Widget(
      29,
      i18n.t('Dashboard.Form.BarMultipleBarOptimizationsByCountryAndState'),
      FilterTypes.noFilter,
      DataType.optimizationsByCountryAndState(),
      ChartType.multipleBar(),
      [BlockSizeType.twoByTwo(), BlockSizeType.twoByThree()],
      mapOptimizationbycountryandstateMultiple,
    );
  }

  static donutOptimizedHours(): Widget<NoParams> {
    return new Widget(
      30,
      i18n.t('Dashboard.Form.DonutOptimizedHours'),
      FilterTypes.noFilter,
      DataType.optimizedHours(),
      ChartType.donutChart(),
      [BlockSizeType.twoByOne(), BlockSizeType.twoByTwo()],
      donutOptimizedHoursMapper,
    );
  }

  static getList(): Array<Widget<Any>> {
    return [
      this.multipleBarInternationalDepartureFlights(),
      this.tableReportFlightsPassengersByCountries(),
      this.tableReportNationalFlightsByCountriesAndMonths(),
      this.sparkLineReportNationalFlightsByCountriesAndMonths(),
      this.tableReportNationalFlightsPercentageByCountries(),
      this.worldMapNationalFlightsPercentageByCountries(),
      this.tableReportInternationalFlightsByCountriesAndMonths(),
      this.sparkLineReportInternationalFlightsByCountriesAndMonths(),
      this.barChartPercentageReportInternationalFlightsByCountriesAndMonths(),
      this.tableReportFlightsByCountries(),
      this.barPassengerByYearAndCountry(),
      this.donutPassengerByFlightTypeByYear(),
      this.barPercentagePostsByCodeGroupingAndCountry(),
      this.donutPostsByModuleAndCountry(),
      this.barPostsByModuleAndCountry(),
      this.barEvolutionFlights(),
      this.sparkLineEvolutionFlights(),
      this.barChartPostsByProvider(),
      this.barChartPostsByCountry(),
      this.facilitiesByCriticity(),
      this.facilitiesByType(),
      this.globalSupervanityComplexChart(),
      this.tableReportInternationalArrivalFlightsByCountriesAndMonths(),
      this.sparkLineReportInternationalArrivalFlightsByCountriesAndMonths(),
      this.barChartPercentageReportInternationalArrivalFlightsByCountriesAndMonths(),
      this.barChartPercentageOptimizationsByCountryAndState(),
      this.donutOptimizedHours(),
    ];
  }

  static getListByDataType<P>(dataTypeId: number): Array<Widget<P>> {
    return this.getList().filter((x) => x.dataType.id === dataTypeId);
  }

  static getById<P>(id: number): Widget<P> | undefined {
    return this.getList().find((c) => c.id === id);
  }
}
