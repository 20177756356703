/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount: number;
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount!: number;
}

// customer definition
// empty
const basePath = '';

export class AlertsClient {
  /**
   *
   */
  alerts(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Alerts_Response_AlertResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/alerts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  read(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/alerts/read/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/alerts/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ApplicationsClient {
  /**
   *
   */
  applications(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Application_Applications_GetAllApplication_Application[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/applications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  users(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_User[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/applications/{id}/users';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  roles(
    params: {
      /**  */
      appName: string;
      /**  */
      sub: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Application_Applications_GetRoles_RoleDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/applications/{appName}/user/{sub}/roles';
      url = url.replace('{appName}', params['appName'] + '');
      url = url.replace('{sub}', params['sub'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  roles1(
    params: {
      /**  */
      appName: string;
      /**  */
      sub: string;
      /**  */
      roleName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/applications/{appName}/user/{sub}/roles/{roleName}';
      url = url.replace('{appName}', params['appName'] + '');
      url = url.replace('{sub}', params['sub'] + '');
      url = url.replace('{roleName}', params['roleName'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  roles2(
    params: {
      /**  */
      appName: string;
      /**  */
      sub: string;
      /**  */
      roleName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/applications/{appName}/user/{sub}/roles/{roleName}';
      url = url.replace('{appName}', params['appName'] + '');
      url = url.replace('{sub}', params['sub'] + '');
      url = url.replace('{roleName}', params['roleName'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  users1(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Api_UseCases_Applications_AddUser_AddUserModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/applications/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ArcaClient {
  /**
   *
   */
  globalsupervanity(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArcaParameterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArcaService_Responses_GlobalSupervanityData[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arca/globalsupervanity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  workstreams(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArcaService_Responses_Workstream[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arca/workstreams';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ArenaClient {
  /**
   *
   */
  postsbypostprofile(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersWithDateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostByCountryCodeGroupingByNature[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/postsbypostprofile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  postsbymodule(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersWithDateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountryByModule[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/postsbymodule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  postsbyprovider(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersWithDateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByProvider[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/postsbyprovider';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  postsbycountry(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersWithDateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountry[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/postsbycountry';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  facilitiesbycriticity(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_FacilitiesByCriticity[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/facilitiesbycriticity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  facilitiesbytype(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_FacilitiesByType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/facilitiesbytype';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  countries(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryArena[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  info(options: IRequestOptions = {}): Promise<Telefonica_DGSI_Dashboard_Application_Arena_Model_ArenaInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  optimizationbycountryandstate(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_OptimizationByCountryAndStateResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/optimizationbycountryandstate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  optimizedhours(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryHoursResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/arena/optimizedhours';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DashboardsClient {
  /**
   *
   */
  dashboards(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_DashboardResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  dashboards1(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_Create_CreateDashboardModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  dashboards2(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_Update_UpdateDashboardModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  dashboards3(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_DashboardResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  dashboards4(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  addBlock(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_AddBlock_AddBlockModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_BlockResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards/{id}/addBlock';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  updateBlock(
    params: {
      /**  */
      dashboardId: number;
      /**  */
      blockId: number;
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_UpdateBlock_UpdateBlockModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_BlockResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards/{dashboardId}/updateBlock/{blockId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');
      url = url.replace('{blockId}', params['blockId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteBlock(
    params: {
      /**  */
      dashboardId: number;
      /**  */
      blockId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_BlockResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboards/{dashboardId}/deleteBlock/{blockId}';
      url = url.replace('{dashboardId}', params['dashboardId'] + '');
      url = url.replace('{blockId}', params['blockId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HomeClient {
  /**
   *
   */
  generalstatustoday(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGeneralStatusTodayResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/home/journey/generalstatustoday';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getallalerts(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGetAllAlertsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/home/journey/getallalerts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  mostfrequentroute(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentMostFrequentRouteResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/home/journey/mostfrequentroute';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  workstreamkpikpm(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentWorkstreamKpiKpmResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/home/arca/workstreamkpikpm';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  info(options: IRequestOptions = {}): Promise<Telefonica_DGSI_Dashboard_Application_Home_Model_DataCampusInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/home/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class JourneyClient {
  /**
   *
   */
  internationaldepartureflights(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightEvolutionInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/internationaldepartureflights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  domesticflightsandpassengers(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentArrivalsFlightsByCountries[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/domesticflightsandpassengers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  domesticflights(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/domesticflights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  internationaldeparturesbycountry(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/internationaldeparturesbycountry';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  internationalarrivalsbycountry(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/internationalarrivalsbycountry';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  domesticflightscomparison(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightsPercentageByCountry[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/domesticflightscomparison';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  nationalandinternationalflights(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByYearAndCountry[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/nationalandinternationalflights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  nationalandinternationalpassengers(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByCountries[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/nationalandinternationalpassengers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  passengersbyflighttype(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByFlightTypeByYear[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/passengersbyflighttype';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  totalflights(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/totalflights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  totalpassengerstomonthsbyyear(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/totalpassengerstomonthsbyyear';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  countries(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_CountryJourney[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/journey/countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MarketPrizeClient {
  /**
   *
   */
  marketprize(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_MarketPriceService_Responses_MarketPrizeResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/marketprize';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotificationsClient {
  /**
   *
   */
  notifications(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Notifications_Response_NotificationResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/notifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  read(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/notifications/read/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  allread(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/notifications/allread';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/notifications/delete/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RssClient {
  /**
   *
   */
  rss(options: IRequestOptions = {}): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_RssResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/rss';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  update(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Api_UseCases_Rss_Update_UpdateRssModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/rss/update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SampleClient {
  /**
   *
   */
  test1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/samples/test1';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  test2(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/samples/test2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  test3(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/samples/test3';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  test4(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/samples/test4';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  samples(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/samples';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  samples1(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Api_UseCases__Samples_Create_CreateSampleModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/samples';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TelemetryArcaClient {
  /**
   *
   */
  getsessionsbytime(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_SessionsByTimeResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-Arca/getsessionsbytime';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getcountbycountry(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_CountByCountryResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-Arca/getcountbycountry';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettoppageviews(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopPageViewsCount[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-Arca/gettoppageviews';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettopbrowsers(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopBrowserUsed[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-Arca/gettopbrowsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getaveragetimebysessions(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-Arca/getaveragetimebysessions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TelemetryArenaClient {
  /**
   *
   */
  getsessionsbytime(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_SessionsByTimeResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-arena/getsessionsbytime';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getcountbycountry(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_CountByCountryResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-arena/getcountbycountry';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettoppageviews(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopPageViewsCount[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-arena/gettoppageviews';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettopbrowsers(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopBrowserUsed[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-arena/gettopbrowsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getaveragetimebysessions(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-arena/getaveragetimebysessions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TelemetryDataCampusClient {
  /**
   *
   */
  getsessionsbytime(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_SessionsByTimeResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-datacampus/getsessionsbytime';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getcountbycountry(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_CountByCountryResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-datacampus/getcountbycountry';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettoppageviews(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopPageViewsCount[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-datacampus/gettoppageviews';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettopbrowsers(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopBrowserUsed[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-datacampus/gettopbrowsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getaveragetimebysessions(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-datacampus/getaveragetimebysessions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TelemetryJourneyClient {
  /**
   *
   */
  getsessionsbytime(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_SessionsByTimeResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-journey/getsessionsbytime';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getcountbycountry(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_CountByCountryResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-journey/getcountbycountry';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettoppageviews(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopPageViewsCount[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-journey/gettoppageviews';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  gettopbrowsers(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopBrowserUsed[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-journey/gettopbrowsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getaveragetimebysessions(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/telemetry-journey/getaveragetimebysessions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersClient {
  /**
   *
   */
  user(
    options: IRequestOptions = {}
  ): Promise<Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserDataResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/user';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  sections(options: IRequestOptions = {}): Promise<Telefonica_DGSI_Dashboard_Domain_Services_Section[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/user/sections';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  all(options: IRequestOptions = {}): Promise<Telefonica_DGSI_Dashboard_Application_UserData_Model_UserModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/user/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getcurrentusers(
    params: {
      /**  */
      application: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/user/getcurrentusers/{application}';
      url = url.replace('{application}', params['application'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  updateLanguage(
    params: {
      /** requestBody */
      body?: Telefonica_DGSI_Dashboard_Application_UserData_Model_UpdateLanguageModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/user/update-language';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_Alerts_Response_NotificationMetaData {
  /**  */
  id: number;

  /**  */
  payload: string;

  /**  */
  type: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_Alerts_Response_AlertResponse {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  message: string;

  /**  */
  section: string;

  /**  */
  showDate: Date;

  /**  */
  url: string;

  /**  */
  platform: string;

  /**  */
  read: boolean;

  /**  */
  taskType: Telefonica_DGSI_Dashboard_Domain_Models_TaskType;

  /**  */
  metaDataJson: string;

  /**  */
  metaData: Telefonica_DGSI_Dashboard_Domain_Queries_Alerts_Response_NotificationMetaData;
}

export interface Microsoft_AspNetCore_Mvc_ValidationProblemDetails {
  /**  */
  type: string;

  /**  */
  title: string;

  /**  */
  status: number;

  /**  */
  detail: string;

  /**  */
  instance: string;

  /**  */
  errors: object;
}

export interface Microsoft_AspNetCore_Mvc_ProblemDetails {
  /**  */
  type: string;

  /**  */
  title: string;

  /**  */
  status: number;

  /**  */
  detail: string;

  /**  */
  instance: string;
}

export interface Telefonica_DGSI_Dashboard_Application_Applications_GetAllApplication_Application {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  imageUrl: string;
}

export interface Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  enabled: boolean;

  /**  */
  applicationId: number;
}

export interface Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_User {
  /**  */
  id: number;

  /**  */
  sub: string;

  /**  */
  name: string;

  /**  */
  imageUrl: string;

  /**  */
  email: string;

  /**  */
  lastAccess: Date;

  /**  */
  accessCount: number;

  /**  */
  roles: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role[];
}

export interface Telefonica_DGSI_Dashboard_Application_Applications_GetRoles_RoleDto {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  selected: boolean;

  /**  */
  description: string;

  /**  */
  enabled: boolean;

  /**  */
  canEdit: boolean;
}

export interface Telefonica_DGSI_Dashboard_Api_UseCases_Applications_AddUser_AddUserModel {
  /**  */
  name: string;

  /**  */
  email: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArcaParameterRequest {
  /**  */
  workstreamId: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArcaService_Responses_GlobalSupervanityData {
  /**  */
  color: string;

  /**  */
  name: string;

  /**  */
  value: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArcaService_Responses_Workstream {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersWithDateRequest {
  /**  */
  countries: number[];

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostByCountryCodeGrouping {
  /**  */
  codeGroupingName: string;

  /**  */
  countryName: string;

  /**  */
  postsCount: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostByCountryCodeGroupingByNature {
  /**  */
  profileNature: string;

  /**  */
  postByCountryCodeGroupings: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostByCountryCodeGrouping[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountryByModule {
  /**  */
  moduleNumber: string;

  /**  */
  countryName: string;

  /**  */
  postsCount: number;

  /**  */
  postNatureId: number;

  /**  */
  postNatureName: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByProvider {
  /**  */
  providerName: string;

  /**  */
  postsCount: number;

  /**  */
  postNatureName: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountry {
  /**  */
  countryName: string;

  /**  */
  postsCount: number;

  /**  */
  postNatureName: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_ArenaParametersRequest {
  /**  */
  countries: number[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_FacilitiesByCriticity {
  /**  */
  criticityName: string;

  /**  */
  facilitiesCount: number;

  /**  */
  country: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_FacilitiesByType {
  /**  */
  typeName: string;

  /**  */
  facilitiesCount: number;

  /**  */
  country: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryArena {
  /**  */
  id: number;

  /**  */
  code: string;

  /**  */
  name: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PerformanceInfo {
  /**  */
  slaPercentage: number;

  /**  */
  invoicePercentage: number;

  /**  */
  surveysPercentage: number;

  /**  */
  totalPercentage: number;
}

export interface Telefonica_DGSI_Dashboard_Application_Arena_Model_ArenaInfo {
  /**  */
  todayTotalHours: number;

  /**  */
  totalPhysicalPosts: number;

  /**  */
  totalTechnologicPosts: number;

  /**  */
  totalFacilityCount: number;

  /**  */
  performanceInfo: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PerformanceInfo;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryCount {
  /**  */
  country: string;

  /**  */
  count: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_OptimizationByCountryAndStateResponse {
  /**  */
  state: string;

  /**  */
  countryCounts: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryCount[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryHoursResponse {
  /**  */
  country: string;

  /**  */
  hours: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_BlockResponse {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  columns: number;

  /**  */
  rows: number;

  /**  */
  order: number;

  /**  */
  widgetId: number;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  countryIds: number[];

  /**  */
  workstreamId: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_DashboardResponse {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  createdBy: string;

  /**  */
  isPublic: boolean;

  /**  */
  blocks: Telefonica_DGSI_Dashboard_Domain_Queries_Dashboards_Response_BlockResponse[];

  /**  */
  sharedUserSubs: string[];
}

export interface Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_Create_CreateDashboardModel {
  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  isPublic: boolean;

  /**  */
  sharedUserSubs: string[];
}

export interface Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_Update_UpdateDashboardModel {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  isPublic: boolean;

  /**  */
  sharedUserSubs: string[];
}

export interface Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_AddBlock_AddBlockModel {
  /**  */
  title: string;

  /**  */
  columns: number;

  /**  */
  rows: number;

  /**  */
  order: number;

  /**  */
  widgetId: number;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  countryIds: number[];

  /**  */
  workstreamId: number;
}

export interface Telefonica_DGSI_Dashboard_Api_UseCases_Dashboards_UpdateBlock_UpdateBlockModel {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  columns: number;

  /**  */
  rows: number;

  /**  */
  order: number;

  /**  */
  widgetId: number;

  /**  */
  startDate: Date;

  /**  */
  endDate: Date;

  /**  */
  countryIds: number[];

  /**  */
  workstreamId: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGeneralStatusTodayResponse {
  /**  */
  totalFlights: number;

  /**  */
  totalPassengers: number;

  /**  */
  flightsFromRiskCountries: number;

  /**  */
  flightsToRiskCountries: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGetAllAlertsResponse {
  /**  */
  alerts: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentMostFrequentRouteResponse {
  /**  */
  featuredRoute: string;

  /**  */
  passengers: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentWorkstreamKpiKpmResponse {
  /**  */
  workstreams: number;

  /**  */
  kpis: number;

  /**  */
  kpms: number;

  /**  */
  pendingKpmForm: number;

  /**  */
  endOfTimeKpmForm: number;
}

export interface Telefonica_DGSI_Dashboard_Application_Home_Model_DataCampusInfo {
  /**  */
  usersArca: number;

  /**  */
  usersJourney: number;

  /**  */
  usersArena: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Request_JourneyParametersRequest {
  /**  */
  countries: number[];

  /**  */
  years: number[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightByYear {
  /**  */
  date: Date;

  /**  */
  flightsAmount: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo {
  /**  */
  year: string;

  /**  */
  flightByYears: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightByYear[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightEvolutionInfo {
  /**  */
  flightEvolutionInfo: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ArrivalsFlightsByCountry {
  /**  */
  country: string;

  /**  */
  flights: number;

  /**  */
  passengers: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentArrivalsFlightsByCountries {
  /**  */
  year: number;

  /**  */
  arrivalsFlightsByCountries: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ArrivalsFlightsByCountry[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths_FlightsByCountriesAndMonth {
  /**  */
  country: string;

  /**  */
  january: number;

  /**  */
  february: number;

  /**  */
  march: number;

  /**  */
  april: number;

  /**  */
  may: number;

  /**  */
  june: number;

  /**  */
  july: number;

  /**  */
  august: number;

  /**  */
  september: number;

  /**  */
  october: number;

  /**  */
  november: number;

  /**  */
  december: number;

  /**  */
  total: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths {
  /**  */
  year: number;

  /**  */
  flightsByCountriesAndMonths: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths_FlightsByCountriesAndMonth[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightsPercentageByCountry {
  /**  */
  country: string;

  /**  */
  countryCode: string;

  /**  */
  flightsPercentage: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightsByYearAndCountry {
  /**  */
  country: string;

  /**  */
  flightsNational: number;

  /**  */
  flightsInternational: number;

  /**  */
  total: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByYearAndCountry {
  /**  */
  year: number;

  /**  */
  flightsByYearAndCountries: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightsByYearAndCountry[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_PassengersByYearAndCountry {
  /**  */
  country: string;

  /**  */
  passengerNational: number;

  /**  */
  passengerInternational: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByCountries {
  /**  */
  year: number;

  /**  */
  passengersByYearAndCountries: Telefonica_DGSI_Dashboard_Domain_Services_Responses_PassengersByYearAndCountry[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByFlightTypeByYear {
  /**  */
  year: number;

  /**  */
  internationalAndNationalPassengers: number;

  /**  */
  internationalPassengers: number;

  /**  */
  nationalPassengers: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ValuesByMonthAndYear {
  /**  */
  january: number;

  /**  */
  february: number;

  /**  */
  march: number;

  /**  */
  april: number;

  /**  */
  may: number;

  /**  */
  june: number;

  /**  */
  july: number;

  /**  */
  august: number;

  /**  */
  september: number;

  /**  */
  october: number;

  /**  */
  november: number;

  /**  */
  december: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths {
  /**  */
  year: number;

  /**  */
  valuesByMonthAndYear: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ValuesByMonthAndYear;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_CountryJourney {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  iso: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_MarketPriceService_Responses_MarketPrizeResponse {
  /**  */
  symbol: string;

  /**  */
  prize: number;

  /**  */
  previousPrize: number;

  /**  */
  difference: number;

  /**  */
  percentage: number;

  /**  */
  datePrize: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_Notifications_Response_NotificationResponse {
  /**  */
  id: number;

  /**  */
  title: string;

  /**  */
  message: string;

  /**  */
  section: string;

  /**  */
  showDate: Date;

  /**  */
  url: string;

  /**  */
  platform: string;

  /**  */
  read: boolean;

  /**  */
  deleted: boolean;

  /**  */
  taskType: Telefonica_DGSI_Dashboard_Domain_Models_TaskType;

  /**  */
  metaDataJson: string;

  /**  */
  metaData: Telefonica_DGSI_Dashboard_Domain_Queries_Alerts_Response_NotificationMetaData;
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_NewsItemResponse {
  /**  */
  title: string;

  /**  */
  description: string;

  /**  */
  publish: Date;

  /**  */
  link: string;

  /**  */
  source: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_RssResponse {
  /**  */
  rssTypeId: number;

  /**  */
  rssType: string;

  /**  */
  rssSizeId: number;

  /**  */
  rssSize: string;

  /**  */
  order: number;

  /**  */
  autoPlayInterval: number;

  /**  */
  newsItemResponse: Telefonica_DGSI_Dashboard_Domain_Queries_Rss_Response_NewsItemResponse[];
}

export interface Telefonica_DGSI_Dashboard_Api_UseCases_Rss_Update_UpdateRssModel {
  /**  */
  rssSizeId: number;

  /**  */
  rssTypeId: number;

  /**  */
  order: number;

  /**  */
  autoPlayInterval: number;
}

export interface Telefonica_DGSI_Api_UseCases__Samples_Create_CreateSampleModel {
  /**  */
  name: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_SessionsByTimeResponse {
  /**  */
  sessions: number[];

  /**  */
  dates: string[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_CountByCountryResponse {
  /**  */
  sessions: number;

  /**  */
  country: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopPageViewsCount {
  /**  */
  count: number;

  /**  */
  page: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopBrowserUsed {
  /**  */
  count: number;

  /**  */
  browser: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserApplicationLogs {
  /**  */
  applicationName: string;

  /**  */
  lastAccess: Date;

  /**  */
  accessCount: number;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_SubjectEntity {
  /**  */
  sub: string;

  /**  */
  roles: Telefonica_DGSI_Dashboard_Domain_Services_RoleSubjectEntity[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_RoleSubjectEntity {
  /**  */
  roleId: number;

  /**  */
  role: Telefonica_DGSI_Dashboard_Domain_Services_RoleEntity;

  /**  */
  subjectId: number;

  /**  */
  subject: Telefonica_DGSI_Dashboard_Domain_Services_SubjectEntity;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_PermissionEntity {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  applicationId: number;

  /**  */
  roles: Telefonica_DGSI_Dashboard_Domain_Services_RolePermissionEntity[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_RolePermissionEntity {
  /**  */
  roleId: number;

  /**  */
  role: Telefonica_DGSI_Dashboard_Domain_Services_RoleEntity;

  /**  */
  permissionId: number;

  /**  */
  permission: Telefonica_DGSI_Dashboard_Domain_Services_PermissionEntity;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_RoleEntity {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  enabled: boolean;

  /**  */
  applicationId: number;

  /**  */
  subjects: Telefonica_DGSI_Dashboard_Domain_Services_RoleSubjectEntity[];

  /**  */
  permissions: Telefonica_DGSI_Dashboard_Domain_Services_RolePermissionEntity[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserApplicationRoles {
  /**  */
  applicationName: string;

  /**  */
  roles: Telefonica_DGSI_Dashboard_Domain_Services_RoleEntity[];
}

export interface Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserDataResponse {
  /**  */
  name: string;

  /**  */
  email: string;

  /**  */
  applicationLogs: Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserApplicationLogs[];

  /**  */
  userRoles: Telefonica_DGSI_Dashboard_Domain_Queries_UserData_Response_UserApplicationRoles[];

  /**  */
  language: string;
}

export interface Telefonica_DGSI_Dashboard_Domain_Services_Section {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;
}

export interface Telefonica_DGSI_Dashboard_Application_UserData_Model_UserModel {
  /**  */
  userSub: string;

  /**  */
  name: string;

  /**  */
  email: string;
}

export interface Telefonica_DGSI_Dashboard_Application_UserData_Model_UpdateLanguageModel {
  /**  */
  language: string;
}

export type Telefonica_DGSI_Dashboard_Domain_Models_TaskType = 0 | 1;
