import { type ApplicationIds } from '@core/services/models/application';
import { type Application } from '@state/permissionsManagement/models/applicacion';
import { type DashboardApplications } from '../reducer/DashboardReducer';
import { DashboardActionTypes } from './actionTypes';

export interface DashboardAction<T extends DashboardActionTypes> {
  type: T;
}

export interface DashboardActionPayload<T extends DashboardActionTypes, P> extends DashboardAction<T> {
  type: T;
  payload: P;
}

export type LoadUserAppsActionType = DashboardActionPayload<DashboardActionTypes.LOAD_USER_APPS, Application[]>;
export const loadUserAppsActionCreator = (apps: Application[]): LoadUserAppsActionType => ({
  type: DashboardActionTypes.LOAD_USER_APPS,
  payload: apps,
});

export type LoadApplicationDataActionType = DashboardActionPayload<
  DashboardActionTypes.LOAD_APPLICATION_DATA,
  DashboardApplications
>;
export const loadApplicationDataActionCreator = (data: DashboardApplications): LoadApplicationDataActionType => ({
  type: DashboardActionTypes.LOAD_APPLICATION_DATA,
  payload: data,
});

export type RemoveApplicationDataActionType = DashboardActionPayload<
  DashboardActionTypes.REMOVE_APPLICATION_DATA,
  ApplicationIds
>;
export const removeApplicationDataActionCreator = (id: ApplicationIds): RemoveApplicationDataActionType => ({
  type: DashboardActionTypes.REMOVE_APPLICATION_DATA,
  payload: id,
});
