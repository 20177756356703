import { type UserGrid } from '@state/permissionsManagement/models/userGrid';
import { showSuccessMessage } from './../../utils/toastMessage';
import { PermissionsManagementService } from '@core/services/permissionsManagementService';
import { type Role } from './models/role';
import { type Subject } from './models/subject';
import { type UserWithPermissions } from './models/userWithPermissions';
import { ApplicationsClient } from '@core/services/services';
import { type Application } from './models/applicacion';

export const usePermissionsManagement = () => {
  const permissionsManagementService = new PermissionsManagementService(new ApplicationsClient());

  const getApplications = async (): Promise<Application[]> => {
    return await permissionsManagementService.getAllApplications();
  };

  const getUsersWithAllApplications = async (): Promise<UserWithPermissions[]> => {
    const applications = await getApplications();
    const users: UserWithPermissions[] = [];
    for (const application of applications) {
      const newUsers: UserWithPermissions[] = (await getAllApplicationUsers(application.id)).map((user) => ({
        ...user,
        tooltipText: {},
      }));
      for (const newUser of newUsers) {
        const user = users.find((u) => u.sub === newUser.sub);
        if (user) {
          user[application.name] = newUser.roles;
          user.tooltipText[application.name] = newUser.countries + newUser.modules;
        } else if (newUser.roles.length > 0) {
          newUser[application.name] = newUser.roles;
          newUser.tooltipText[application.name] = newUser.countries + newUser.modules;
          users.push(newUser);
        }
      }
    }
    return users;
  };

  const getUsers = async (appId: number): Promise<Subject[]> => {
    return await permissionsManagementService.getUsers(appId);
  };

  const getUsersGrid = async (appId: number): Promise<UserGrid[]> => {
    return await permissionsManagementService.getUsersGridData(appId);
  };

  const getRoles = async (appName: string, sub: string): Promise<Role[]> => {
    return await permissionsManagementService.getRoles(appName, sub);
  };

  const addRole = async (appName: string, sub: string, roleName: string, roles: Role[]): Promise<Role[]> => {
    await permissionsManagementService.addRole(appName, sub, roleName);
    showSuccessMessage('Role has been added succesfully');
    return roles.map((role) => (role.name === roleName ? { ...role, selected: true } : role));
  };

  const deleteRole = async (appName: string, sub: string, roleName: string, roles: Role[]): Promise<Role[]> => {
    await permissionsManagementService.deleteRole(appName, sub, roleName);
    showSuccessMessage('Role has been deleted succesfully');
    return roles.map((role) => (role.name === roleName ? { ...role, selected: false } : role));
  };

  const getApp = async (id: number): Promise<Application | undefined> => {
    const apps = await permissionsManagementService.getAllApplications();
    return apps.find((app) => app.id === id);
  };

  const getAllApplicationUsers = async (appId: number): Promise<UserGrid[]> => {
    return await getUsersGrid(appId);
  };

  return {
    getApplications,
    getApp,
    getAllApplicationUsers,
    getUsersWithAllApplications,
    getUsers,
    getUsersGrid,
    getRoles,
    addRole,
    deleteRole,
  };
};
