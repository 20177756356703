import {
  type Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role,
  type Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_User,
} from '../services';
import { formatRoleWithPrefix } from './../../../shared/utils';
import { type UserGrid } from './../../../state/permissionsManagement/models/userGrid';
import { formatDate } from './../../../utils/dateFormatter';

export const usersGridDataMapper = (
  response: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_User[],
): UserGrid[] => {
  return response.flatMap((user) =>
    user.roles.length === 0
      ? []
      : {
          ...user,
          lastAccess: user.lastAccess ? formatDate(user.lastAccess) : '',
          roles: getUserRoles(user.roles),
          modules: getUserModules(user.roles),
          countries: getUserCountries(user.roles),
          corporate: getUserCorporateAgency(user.roles),
        },
  );
};

const getUserRoles = (roles: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role[]) => {
  return roles
    .flatMap((role) =>
      !role.name.toUpperCase().startsWith('GEO') &&
      !role.name.toUpperCase().startsWith('MOD') &&
      !role.name.toUpperCase().startsWith('CAG')
        ? role.name
        : [],
    )
    .join(', ');
};

const getUserCountries = (roles: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role[]) => {
  return roles
    .flatMap((role) => (role.name.toUpperCase().startsWith('GEO') ? formatRoleWithPrefix(role.name) : []))
    .join(', ');
};

const getUserModules = (roles: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role[]) => {
  return roles
    .flatMap((role) => (role.name.toUpperCase().startsWith('MOD') ? formatRoleWithPrefix(role.name) : []))
    .join(', ');
};

const getUserCorporateAgency = (
  roles: Telefonica_DGSI_Dashboard_Application_Applications_GetUsersByApplication_Role[],
) => {
  return roles
    .flatMap((role) => (role.name.toUpperCase().startsWith('CAG') ? formatRoleWithPrefix(role.name) : []))
    .join(', ');
};
