import { type DataModel, FilterOptions, type Filters } from '@components/dashboard/shared/filterOptions/FilterOptions';
import { ToolTipFormated } from '@components/dashboard/shared/ToolTipFormated';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { type BarPercentageData } from '@state/block/models/barPercentageData';
import { type FunctionComponent, useEffect, useState } from 'react';
import './BarChartPercentage.scss';

export interface Props {
  data: BarPercentageData[];
}

const emptyPercentageData: BarPercentageData = {
  data: [],
  names: [],
  option: '',
};

export const BarChartPercentage: FunctionComponent<Props> = ({ data }) => {
  const [selectedFilter, setSelectedFilter] = useState<Filters>({ filter: '' });
  const [barchartData, setBarChart] = useState<BarPercentageData>(emptyPercentageData);
  const [filterData, setFilterData] = useState<DataModel[]>([]);

  useEffect(() => {
    setFilterData(data.map((d) => ({ name: d.option, value: d.option })));
    setSelectedFilter({ filter: data[0].option });
  }, [data]);

  useEffect(() => {
    const dataSelected = data.find((Item) => Item.option === selectedFilter.filter);
    if (dataSelected === undefined) return;
    setBarChart(dataSelected);
  }, [selectedFilter]);

  const onChange = (event: Filters) => {
    setSelectedFilter({ filter: event.filter });
  };

  const valueAxisLabels = {
    font: '11px Telefonica Web Regular, Arial, sans-serif',
  };

  const valueLegendLabels = {
    font: '11px Telefonica Web Regular, Arial, sans-serif',
    margin: 5,
  };

  const defaultTooltipRender = (value: Any) => {
    return <ToolTipFormated value={value} />;
  };

  return barchartData ? (
    <div className="barchartPercentage-container">
      <FilterOptions data={filterData} value={selectedFilter} onChange={onChange} />
      <Chart zoomable={false} style={{ width: '100%', height: 'calc(100% - 50px)', background: 'transparent' }}>
        <ChartLegend align="start" position="left" orientation="vertical" labels={valueLegendLabels} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={barchartData.names} labels={valueAxisLabels} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem labels={valueAxisLabels} />
        </ChartValueAxis>
        <ChartTooltip shared={true} render={defaultTooltipRender} background='#000000' color='#FFFFFF'/>
        <ChartSeries>
          {barchartData.data.map((element, index) => (
            <ChartSeriesItem
              gap={2}
              key={index}
              color={element.color}
              stack={{ type: '100%' }}
              type="bar"
              name={element.role}
              data={element.value}
            ></ChartSeriesItem>
          ))}
        </ChartSeries>
      </Chart>
    </div>
  ) : null;
};
