import { type Application } from '../../../state/permissionsManagement/models/applicacion';
import { type Telefonica_DGSI_Dashboard_Application_Applications_GetAllApplication_Application } from '../services';
export const ApplicationMapper = {
  map(response: Telefonica_DGSI_Dashboard_Application_Applications_GetAllApplication_Application[]): Application[] {
    return response.map((item) => {
      return {
        id: item.id,
        name: item.name,
        imageUrl: item.imageUrl,
        description: item.description,
      } as Application;
    });
  },
};
