import { AxiosClient } from '../http/axiosClient';
import { DashboardsService } from './dashboardService';
import { NotificationsService } from './notificationsService';
import { PermissionsManagementService } from './permissionsManagementService';
import { SampleClient, serviceOptions } from './services';

export function configureServices(apiUrl: string): void {
  const { instance } = new AxiosClient(apiUrl);
  serviceOptions.axios = instance;
}

export { SampleClient, NotificationsService, DashboardsService, PermissionsManagementService as UserManagementService };
