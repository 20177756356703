import { getColor, months, tableReportsColumnTitles } from '@shared/utils';
import { type BlockTableData } from '../../../state/block/models/blockTableData';
import { type SparkLineBlockData, type SparkLineMetaData } from '../../../state/block/models/sparkLineData';
import {
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths,
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths_FlightsByCountriesAndMonth,
} from '../services';

export const mapFlightsbycountriesandmonthsTable = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths,
): BlockTableData => {
  return {
    columns: [tableReportsColumnTitles.country, ...months, tableReportsColumnTitles.total],
    rows: response.flightsByCountriesAndMonths.map((value) => {
      return {
        values: [
          value.country,
          value.january,
          value.february,
          value.march,
          value.april,
          value.may,
          value.june,
          value.july,
          value.august,
          value.september,
          value.october,
          value.november,
          value.december,
          value.total,
        ],
      };
    }),
    option: response.year.toString(),
  };
};

export const mapFlightsBycountriesAndMonths = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths[],
): SparkLineBlockData[] => {
  return [...response.map(mapFlightsBycountriesAndMonthsToSparkLine)];
};

export const mapFlightsBycountriesAndMonthsToSparkLine = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths,
): SparkLineBlockData => {
  return {
    categories: months,
    metaData: response.flightsByCountriesAndMonths.map((value, index) => {
      return mapFlightsByCountriesAndMonthsSparkLineMetaData(value, index);
    }),
    option: response.year.toString(),
  };
};

export const mapFlightsByCountriesAndMonthsSparkLineMetaData = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths_FlightsByCountriesAndMonth,
  index: number,
): SparkLineMetaData => {
  return {
    country: response.country,
    color: getColor(index),
    data: [
      {
        value: response.january.toString(),
        code: null,
      },
      {
        value: response.february.toString(),
        code: null,
      },
      {
        value: response.march.toString(),
        code: null,
      },
      {
        value: response.april.toString(),
        code: null,
      },
      {
        value: response.may.toString(),
        code: null,
      },
      {
        value: response.june.toString(),
        code: null,
      },
      {
        value: response.july.toString(),
        code: null,
      },
      {
        value: response.august.toString(),
        code: null,
      },
      {
        value: response.september.toString(),
        code: null,
      },
      {
        value: response.october.toString(),
        code: null,
      },
      {
        value: response.november.toString(),
        code: null,
      },
      {
        value: response.december.toString(),
        code: null,
      },
    ],
  };
};
