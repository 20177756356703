import userService, { type Section, type User } from '@core/services/userService';
import { useEffect, useMemo, useState, type ReactNode } from 'react';
import { UserContext } from './userContext';

interface UserProviderProps {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: UserProviderProps): JSX.Element => {
  const [userData, setUserData] = useState<User>({
    name: '',
    email: '',
    applicationLogs: [],
    userRoles: [],
    language: '',
  });

  const [sections, setSections] = useState<Section[]>([]);

  const setUser = (user: User): void => {
    setUserData({ ...user, applicationLogs: user.applicationLogs ?? [], userRoles: user.userRoles ?? [] });
  };

  useEffect(() => {
    userService.getSections().then(setSections);
  }, []);

  const value = useMemo(() => {
    return { userData, sections, setUser };
  }, [userData, sections, setUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
