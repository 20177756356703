import telefonicaIcon from '@assets/images/telefonica-logo-circle.svg';
import cyberthreatsIcon from '@assets/images/cyber-threat.svg';
import securityIcon from '@assets/images/national-security.svg';
import fraudIcon from '@assets/images/fraud.svg';
import { type resources } from 'I18n';

export type RssName = keyof (typeof resources)['en_US']['translation']['Rss'];
export class RssType {
  id: number;
  name: RssName;
  color: string;
  icon: string;

  constructor(id: number, name: RssName, color: string, icon: string) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.icon = icon;
  }

  static cyberthreats(): RssType {
    return new RssType(1, 'Cyberthreats', '#755E52', cyberthreatsIcon);
  }

  static security(): RssType {
    return new RssType(2, 'Security', '#2C4A79', securityIcon);
  }

  static news(): RssType {
    return new RssType(3, 'News', '#0166FF', telefonicaIcon);
  }

  static fraud(): RssType {
    return new RssType(4, 'Fraud', '#9D84A1', fraudIcon);
  }

  static getList(): RssType[] {
    return [this.cyberthreats(), this.security(), this.news(), this.fraud()];
  }

  static getById(id: number): RssType {
    return this.getList().find((c) => c.id === id) as RssType;
  }
}
