import { tableReportsColumnTitles } from '@shared/utils';
import { type BlockTableData } from '@state/block/models/blockTableData';
import { type MapHeatData } from '../../../state/block/models/mapHeatData';
import { type Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightsPercentageByCountry } from '../services';

export const mapFlightsPercentageByCountryToHeatMap = (
  data: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightsPercentageByCountry[],
): MapHeatData[] => {
  return data.map((item) => ({
    code: item.countryCode,
    value: +item.flightsPercentage.toFixed(2),
    countryName: item.country,
    text: `${+item.flightsPercentage.toFixed(2)} %`,
  }));
};

export const mapFlightsPercentageByCountryToTableReport = (
  data: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightsPercentageByCountry[],
): BlockTableData[] => {
  const item = {
    columns: [tableReportsColumnTitles.country, tableReportsColumnTitles.flightsPercentage],
    rows: data.map((item) => ({ values: [item.country, `${item.flightsPercentage.toFixed(2)} %`] })),
    option: 'empty',
  };
  return [item];
};
