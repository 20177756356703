import { getColor, months } from '@shared/utils';
import { type SparkLineBlockData, type SparkLineMetaData } from '@state/block/models/sparkLineData';
import { type Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths } from '../services';

export const mapPassengersByYearAndMonthsSparkLine = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths[],
): SparkLineBlockData[] => {
  return [
    {
      categories: months,
      metaData: response.map((value, index) => {
        return mapPassengersByYearAndMonthsSparkLineMetaData(value, index);
      }),
      option: 'Single',
    },
  ];
};

export const mapPassengersByYearAndMonthsSparkLineMetaData = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_PassengersByYearAndMonths,
  index: number,
): SparkLineMetaData => {
  return {
    country: response.year.toString(),
    color: getColor(index),
    data: [
      { value: response.valuesByMonthAndYear.january.toString(), code: null },
      { value: response.valuesByMonthAndYear.february.toString(), code: null },
      { value: response.valuesByMonthAndYear.march.toString(), code: null },
      { value: response.valuesByMonthAndYear.april.toString(), code: null },
      { value: response.valuesByMonthAndYear.june.toString(), code: null },
      { value: response.valuesByMonthAndYear.july.toString(), code: null },
      { value: response.valuesByMonthAndYear.august.toString(), code: null },
      { value: response.valuesByMonthAndYear.september.toString(), code: null },
      { value: response.valuesByMonthAndYear.october.toString(), code: null },
      { value: response.valuesByMonthAndYear.november.toString(), code: null },
      { value: response.valuesByMonthAndYear.december.toString(), code: null },
    ],
  };
};
