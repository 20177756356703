import { SidePanel } from '@components/shared/SidePanel/SidePanel';
import { type DataType } from '@state/block/models/dataType';
import { Widget } from '@state/block/models/widget';
import { observer } from 'mobx-react-lite';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './ChartsList.scss';

interface IChartsListProps {
  dataType: DataType | null;
  onClose: () => void;
  onSelectWidget: (widget: Widget<Any>) => void;
}

export const ChartsList: FunctionComponent<IChartsListProps> = observer(({ dataType, onClose, onSelectWidget }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'Dashboard.Form' });
  
  return (
    dataType && (
      <SidePanel onClose={onClose} title={t('SelectWidgetChart')} position="secondary">
        <div className="db-side-panel__body">
          <ul>
            {Widget.getListByDataType(dataType.id).map((widget) => (
              <li
                key={`indicator_${widget.id}`}
                className="db-side-panel__chart-list--item"
                onClick={() => {
                  onSelectWidget(widget);
                }}
              >
                <div className={'db-side-panel__chart-list--item-preview ' + widget.chartType.image}></div>
                <div className="db-side-panel__chart-list--item-info">
                  <span className="db-side-panel__chart-list--item-title">{widget.chartType.name}</span>
                  <p className="db-side-panel__chart-list--item-description">{widget.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </SidePanel>
    )
  );
});
