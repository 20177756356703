import '@assets/scss/import.scss';
import { defineCustomElements } from '@balea-telefonica/ui/dist/loader';
import { AppConfiguration, type Settings } from '@core/config/appConfiguration';
import { configureServices } from '@core/services';
import { configure } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './I18n';

const router = createBrowserRouter([{ path: '*', element: <App /> }]);

fetch('/appsettings.json')
  .then(async (settingsResponse: Response) => await settingsResponse.json())
  .then((settings: Settings) => {
    const appConfiguration = AppConfiguration.getInstance();
    appConfiguration.use(settings);
    configureServices(appConfiguration.settings.apiUrl);
    configure({ enforceActions: 'always' });

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>,
    );
    defineCustomElements(window);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
