import { type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountryByModule } from './../services';
import lodash from 'lodash';
import { type DataGrouped } from '../models/dataGrouped';

export const mapPostsBymoduleToBarChart = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountryByModule[],
): MultipleBlockMetadata[] => {
  const dataGroupedByCountry = lodash.groupBy(response, (item) => item.countryName);
  const countries = Object.keys(dataGroupedByCountry);
  const dataGroupedByCountryAndNature: DataGrouped = {};
  countries.forEach((country) => {
    dataGroupedByCountryAndNature[country] = {
      All: dataGroupedByCountry[country],
      ...lodash.groupBy(dataGroupedByCountry[country], (item) => item.postNatureName),
    };
  });

  return (countries.length > 0)
    ? countries.map((country) => ({
        option: country,
        data: Object.keys(dataGroupedByCountryAndNature[country]).map((nature) => ({
          name: nature,
          data: dataGroupedByCountryAndNature[country][nature].map((itemMetadata) => ({
            name: itemMetadata.moduleNumber,
            value: itemMetadata.postsCount,
          })),
        })),
      }))
    : [{ option: 'No Data', data: [{ name: 'posts', data: [{ name: '', value: 0 }] }] }];
};
