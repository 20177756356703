import { InputSelect } from '@components/form/input-select/input-select';
import { Icon } from '@components/shared/Icon/Icon';
import { MasonryLayout, type MasonryStone } from '@components/shared/MasonryLayout/MasonryLayout';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { Application } from '@core/services/models/application';
import { usePermissionsManagement } from '@state/permissionsManagement/usePermissionsManagement';
import { useEffect, useState, type FunctionComponent } from 'react';
import { ArenaAnalytics } from './analiticsGroups/ArenaAnalytics';
import { DataCampusAnalytics } from './analiticsGroups/DataCampusAnalytics';
import { JourneyAnalytics } from './analiticsGroups/JourneyAnalytics';
import { ArcaAnalytics } from './analiticsGroups/ArcaAnalytics';
import './Analytics.scss';

export const Analytics: FunctionComponent = () => {
  const [permitedApplications, setPermitedApplications] = useState<number[]>([]);
  const [currentAnalytics, setCurrentAnalytics] = useState<number>();

  const analitics: Record<number, MasonryStone[]> = {
    [Application.arena().id]: ArenaAnalytics(),
    [Application.arca().id]: ArcaAnalytics(),
    [Application.journey().id]: JourneyAnalytics(),
    [Application.dataCampus().id]: DataCampusAnalytics(),
  };

  const { getApplications } = usePermissionsManagement();
  useEffect(() => {
    getApplications().then((apps) => {
      const appIds: number[] = [];
      apps.forEach((app) => {
        const appId = Application.getByName(app.name)?.id;
        if (appId) {
          appIds.push(appId);
        }
      });
      if (appIds.length) {
        setPermitedApplications(appIds);
        setCurrentAnalytics(appIds[0]);
      }
    });
  }, []);

  return (
    <>
      <section className="analytics__body">
        <header className="analytics__header">
          <div className="analytics__selector">
            <InputSelect
              usePortal
              value={`${currentAnalytics ?? ''}`}
              options={permitedApplications.map((id) => ({
                label: Application.getById(id)?.name ?? '',
                value: `${id}`,
              }))}
              onChangeFullOption={(app) => {
                setCurrentAnalytics(app.value);
              }}
            />
          </div>
          <div className="analytics__titleIcon">
            <Tooltip text="Showing last 30 days data">
              <Icon iconName="info" />
            </Tooltip>
          </div>
        </header>
        {currentAnalytics && (
          <MasonryLayout
            stones={analitics[currentAnalytics].map((stone) => ({
              ...stone,
              element: <div key={stone.id}>{stone.element}</div>,
            }))}
            columnBreakPoints={{ 700: 2, 1200: 3 }}
            gutter={24}
            rows={2}
            stoneHeight={500}
          />
        )}
      </section>
    </>
  );
};
