import { forwardRef, type Ref } from 'react';
import { type InputCommonProps } from '../form.model';
import { InputGroup } from '../input-group';
import './input-text.scss';

type InputTextboxProps = InputCommonProps & React.InputHTMLAttributes<HTMLInputElement>;

const InputTextbox = forwardRef(
  ({ label, error, disabled, ...props }: InputTextboxProps, ref: Ref<HTMLInputElement>) => (
    <InputGroup label={label} error={error} disabled={disabled}>
      {() => (
        <input className="db-input-text" type="text" disabled={disabled} {...props} ref={ref} autoComplete="off" />
      )}
    </InputGroup>
  ),
);

InputTextbox.displayName = 'InputTextbox';
export { InputTextbox };
