import { forwardRef, type Ref } from 'react';
import { type InputCommonProps } from '../form.model';
import { InputGroup } from '../input-group';
import './input-radio.scss';

type InputRadioProps = InputCommonProps & React.InputHTMLAttributes<HTMLInputElement>;

const InputRadio = forwardRef(
  ({ label, error, value, disabled, name, ...props }: InputRadioProps, ref: Ref<HTMLInputElement>) => (
    <InputGroup label={label} error={error} disabled={disabled}>
      {() => (
        <div className="db-input-radio">
          {name ? <span className="db-input-radio__text">{name}</span> : null}
          <input readOnly type="radio" name="radio" disabled={disabled} value={value} {...props} ref={ref}></input>
          <span className="db-input-radio__checkmark"></span>
        </div>
      )}
    </InputGroup>
  ),
);

InputRadio.displayName = 'InputRadio';
export { InputRadio };
