import { type ArenaInfoModel } from './models/arenaInfoModel';
import { type CountryModel } from './models/countryModel';
import { ArenaClient } from './services';

class ArenaService {
  async getCountries(): Promise<CountryModel[]> {
    return await arenaClient.countries();
  }

  async getArenaInfo(): Promise<ArenaInfoModel> {
    return await arenaClient.info();
  }
}
const arenaClient = new ArenaClient();
export const arenaService = new ArenaService();
