import { DatePickerYear } from '@components/form/input-date-year/input-date-year';
import { DatePickerCustom } from '@components/form/input-date/input-date';
import { InputNumber } from '@components/form/input-number/input-number';
import { InputSelectMultple } from '@components/form/input-select-multiple/input-select-multiple';
import { InputSelect, type SimpleOption } from '@components/form/input-select/input-select';
import { InputTextbox } from '@components/form/input-text/input-text';
import { BlockSizes } from '@components/shared/blockSizes/blockSizes';
import { DBButton } from '@components/shared/button/button';
import { Icon } from '@components/shared/Icon/Icon';
import { SidePanel } from '@components/shared/SidePanel/SidePanel';
import { arenaService } from '@core/services/arenaService';
import { journeyService } from '@core/services/journeyServices';
import { Application } from '@core/services/models/application';
import { type CountryModel } from '@core/services/models/countryModel';
import { type WorkstreamModel } from '@core/services/models/workstreamModel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStores } from '@hooks/useStores/useStores';
import { GetUTCDate } from '@shared/utils';
import { type Block } from '@state/block/models/block';
import { FilterTypes, Widget } from '@state/block/models/widget';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import './BlockForm.scss';

interface IProps {
  blockId: number | null;
  dashboardId: number;
  widget: Widget<Any> | null;
  workstreams: WorkstreamModel[];
  onSave: () => void;
  onClose: () => void;
}

const MIN_ORDER = 1;
const MAX_LENGHT = 256;
const ALL_COUNTRIES_ID = -1;

const schema = yup.object({
  title: yup.string().required().max(MAX_LENGHT),
  order: yup.number().required(),
  startDate: yup.date(),
  endDate: yup.date(),
  workstreamId: yup.number(),
  countryIds: yup.array(yup.number()),
});
type FormData = yup.InferType<typeof schema>;

export const BlockForm: FunctionComponent<IProps> = observer(
  ({ blockId, dashboardId, widget, workstreams, onClose, onSave }): JSX.Element => {
    const { t } = useTranslation('translation');
    const { dashboardStore } = useStores();
    const [block, setBlock] = useState<Block>();
    const [rows, setRows] = useState<number>(widget?.sizes[0]?.rows ?? 0);
    const [columns, setColumns] = useState<number>(widget?.sizes[0]?.columns ?? 0);
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
    const [countries, setCountries] = useState<CountryModel[]>([]);
    const [selectedWorkstream, setSelectedWorkstream] = useState<number | undefined>(undefined);

    useEffect(() => {
      const selectedBlock = dashboardStore.dashboard.blocks.find((b: Block) => b.id === blockId);
      if (selectedBlock) {
        widget = Widget.getById(selectedBlock.widgetId) as Widget<Any>;

        setBlock(selectedBlock);
        setRows(selectedBlock.rows);
        setColumns(selectedBlock.columns);
        setStartDate(new Date(selectedBlock.startDate));
        setEndDate(new Date(selectedBlock.endDate));
        setSelectedCountries(
          selectedBlock.countryIds.some((c) => c === ALL_COUNTRIES_ID) ? [ALL_COUNTRIES_ID] : selectedBlock.countryIds,
        );
      }
    }, [blockId]);

    useEffect(() => {
      loadCountries();
      setSelectedWorkstream(block?.workstreamId);
    }, [block, widget]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm<FormData>({
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      setValue('title', block?.title ?? '');
      setValue('order', block?.order ?? 0);
    }, [block]);

    const { ref: startDateRef } = register('startDate');
    const { ref: endDateRef } = register('endDate');

    const loadCountries = async (): Promise<void> => {
      const countryDictionary = {
        [Application.arena().id]: arenaService.getCountries,
        [Application.journey().id]: journeyService.getCountries,
      };
      setCountries([]);
      if (selectedWidget && countryDictionary[selectedWidget?.dataType.application.id]) {
        await countryDictionary[selectedWidget?.dataType.application.id]().then((c) => {
          setCountries(
            widget?.filterType === FilterTypes.countriesWithAll
              ? [{ id: ALL_COUNTRIES_ID, code: 'all', name: 'All' }, ...c]
              : c,
          );
        });
      }
    };

    const selectedWidget = Widget.getById(block ? block?.widgetId : (widget?.id as number));

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
      const blockCountryIds = selectedCountries.includes(ALL_COUNTRIES_ID)
        ? countries.map((c) => c.id)
        : selectedCountries;

      if (blockId) {
        await dashboardStore.updateBlock(
          Object.assign({}, block, {
            ...formData,
            dashboardId,
            widgetId: widget?.id,
            columns,
            startDate,
            endDate,
            countryIds: blockCountryIds,
            workstreamId: selectedWorkstream,
          }),
        );
      } else {
        await dashboardStore.addBlock({
          ...formData,
          dashboardId,
          widgetId: widget?.id,
          rows,
          columns,
          startDate,
          endDate,
          countryIds: blockCountryIds,
          workstreamId: selectedWorkstream,
        } as Block);
      }
      onSave();
    };

    const onChangeCountries = (event: SimpleOption[]): void => {
      setSelectedCountries(
        event.some((c) => c.value === ALL_COUNTRIES_ID.toString())
          ? [ALL_COUNTRIES_ID]
          : event.map((e: SimpleOption) => +e.value),
      );
    };

    const onChangeWorkstream = (event: SimpleOption): void => {
      setSelectedWorkstream(+event.value);
    };
    return (
      <SidePanel
        onClose={onClose}
        position={block ? undefined : 'tertiary'}
        title={block ? `${t('Dashboard.Form.EditWidget')}` : t('Dashboard.Form.CustomizeWidget')}
      >
        <form
          className="db-form"
          onSubmit={(e) => {
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="db-form__structure">
            <span className="db-form__section-title">{t('Dashboard.Form.BasicInformation')}</span>
            <div className="db-form__row">
              <div className="db-form__col-0">
                <InputTextbox
                  {...register('title')}
                  label={t('Dashboard.Form.Title')}
                  error={errors.title?.message}
                ></InputTextbox>
              </div>
            </div>
            <div className="db-form__row">
              <div className="db-form__col-0 with-icon">
                <Icon iconName="order"></Icon>
                <InputNumber
                  label={t('Dashboard.Form.Order')}
                  {...register('order')}
                  error={errors.order?.message}
                  min={MIN_ORDER}
                  max={blockId ? dashboardStore.dashboard.blocks.length : dashboardStore.dashboard.blocks.length + 1}
                ></InputNumber>
              </div>
            </div>
            {(selectedWidget?.filterType === FilterTypes.countries ||
              selectedWidget?.filterType === FilterTypes.countriesWithAll) && (
              <div className="db-form__row">
                <div className="db-form__col-0">
                  <InputSelectMultple
                    label={t('Dashboard.Form.Countries')}
                    name="countryIds"
                    error={errors.countryIds?.message}
                    values={selectedCountries.map((c) => c.toString())}
                    options={countries.map((c) => ({ label: c.name, value: c.id.toString() }))}
                    onChangeFullOption={onChangeCountries}
                  />
                </div>
              </div>
            )}
            {selectedWidget?.filterType === FilterTypes.workstreams && (
              <div className="db-form__row">
                <div className="db-form__col-0">
                  <InputSelect
                    label={t('Dashboard.Form.Workstreams')}
                    name="workstreamId"
                    value={selectedWorkstream?.toString()}
                    error={errors.workstreamId?.message}
                    onChangeFullOption={onChangeWorkstream}
                    options={workstreams.map((c) => ({ label: c.name, value: c.id.toString() }))}
                  />
                </div>
              </div>
            )}
            {selectedWidget?.dataType.useDate && (
              <div className="db-form__row">
                {selectedWidget?.dataType.application.id === Application.arena().id ? (
                  <>
                    <div className="db-form__col-0">
                      <DatePickerCustom
                        ref={startDateRef}
                        label={t('Dashboard.Form.StartDate')}
                        name={'startDate'}
                        value={startDate}
                        onChange={({ target }) => {
                          setStartDate(GetUTCDate(target.value as Date));
                        }}
                      />
                    </div>
                    <div className="db-form__col-0">
                      <DatePickerCustom
                        ref={endDateRef}
                        label={t('Dashboard.Form.EndDate')}
                        name="endDate"
                        value={endDate}
                        onChange={({ target }) => {
                          setEndDate(GetUTCDate(target.value as Date));
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="db-form__col-0">
                      <DatePickerYear
                        ref={startDateRef}
                        label={t('Dashboard.Form.StartDate')}
                        name="startDate"
                        value={startDate}
                        onChange={({ target }) => {
                          setStartDate(GetUTCDate(target.value as Date));
                        }}
                      />
                    </div>
                    <div className="db-form__col-0">
                      <DatePickerYear
                        ref={endDateRef}
                        label={t('Dashboard.Form.EndDate')}
                        name="endDate"
                        value={endDate}
                        onChange={({ target }) => {
                          setEndDate(GetUTCDate(target.value as Date));
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <BlockSizes
              rows={rows}
              columns={columns}
              widgetId={block?.widgetId ? block?.widgetId : widget?.id}
              onChangeSize={(blockSize) => {
                setRows(blockSize.rows);
                setColumns(blockSize.columns);
              }}
            />
          </div>
          <div className="db-form__actions">
            <DBButton name="simple" type="button" onClick={onClose} text={t('Shared.Cancel')}></DBButton>
            <DBButton name="primary" type="submit" text={t('Shared.Save')}></DBButton>
          </div>
        </form>
      </SidePanel>
    );
  },
);
