import { Icon } from '@components/shared/Icon/Icon';
import { type ReactNode } from 'react';

interface ISidePanel {
  title: string;
  subtitle?: string;
  children: ReactNode;
  position?: 'secondary' | 'tertiary';
  onClose: () => void;
}

export const SidePanel = ({ title, subtitle, children, onClose, position }: ISidePanel): JSX.Element => {
  return (
    <div className={`db-side-panel ${position ?? ''}`.trim()}>
      <div className="db-side-panel__header">
        <div>
          <h1 className="db-side-panel__title">{title}</h1>
          {subtitle && <h2 className="db-side-panel__subtitle">{subtitle}</h2>}
        </div>
        <Icon onClick={onClose} iconName="close"></Icon>
      </div>
      {children}
    </div>
  );
};
