import { CustomGrid, TooltipCell } from '@components/shared/CustomGrid/CustomGrid';
import { GoBackHeader } from '@components/shared/GoBackHeader/GoBackHeader';
import { type State, process, type DataResult } from '@progress/kendo-data-query';
import { type GridCellProps, GridColumn, type GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { type UserWithPermissions } from '@state/permissionsManagement/models/userWithPermissions';
import { usePermissionsManagement } from '@state/permissionsManagement/usePermissionsManagement';
import { observer } from 'mobx-react-lite';
import { type FunctionComponent, useEffect, useState } from 'react';
import './UserList';

export const UserList: FunctionComponent = observer(() => {
  const { getUsersWithAllApplications } = usePermissionsManagement();
  const [usersFiltered, setUsersFiltered] = useState<DataResult>({ data: [], total: 0 });
  const [gridOptions, setGridOptions] = useState<State>({});
  const [usersWithAllApplications, setUsersWithAllApplications] = useState<UserWithPermissions[]>([]);

  useEffect(() => {
    getUsersWithAllApplications().then((users) => {
      setUsersWithAllApplications(users);
      setUsersFiltered(process(users, {}));
    });
  }, []);

  const onGridOptionsChange = (e: GridDataStateChangeEvent): void => {
    setUsersFiltered(process(usersWithAllApplications, e.dataState));
    setGridOptions(e.dataState);
  };

  const RolesTooltipCell = ({ dataItem, field }: GridCellProps): JSX.Element => {
    return <td title={(dataItem as UserWithPermissions).tooltipText[field ?? '']}>{dataItem[field ?? '']}</td>;
  };

  return (
    <div className="db-app-selector">
      <GoBackHeader title={'All users'} goBackLink={true} />
      <CustomGrid data={usersFiltered} {...gridOptions} onDataStateChange={onGridOptionsChange}>
        <GridColumn field="name" title="User" cell={TooltipCell} />
        <GridColumn field="Arena" title="Arena" cell={RolesTooltipCell} />
        <GridColumn field="Journey" title="Journey" cell={RolesTooltipCell} />
      </CustomGrid>
    </div>
  );
});
