import { InputSelect, type SimpleOption } from '@components/form/input-select/input-select';
import { InputTextbox } from '@components/form/input-text/input-text';
import { Icon } from '@components/shared/Icon/Icon';
import { useStores } from '@hooks/useStores/useStores';
import { TaskType } from '@state/tasks/taskType';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, type ChangeEvent, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './Filters.scss';

interface FiltersProps {
  taskType: number;
}

export enum Period {
  LastDays,
  RecentHistory,
}

const tabs = [
  { name: 'LastDays', type: Period.LastDays },
  { name: 'RecentHistory', type: Period.RecentHistory },
] as const;

export enum FilterByOptions {
  onlyUnread,
  all,
}

const filterByOptions: SimpleOption[] = [
  { label: 'Only unread', value: FilterByOptions.onlyUnread },
  { label: 'All', value: FilterByOptions.all },
];

export enum OrderByOptions {
  dateAsc,
  dateDesc,
  az,
  za,
}

const orderByOptions: SimpleOption[] = [
  { label: 'Date Asc', value: OrderByOptions.dateAsc },
  { label: 'Date Desc', value: OrderByOptions.dateDesc },
  { label: 'A-Z', value: OrderByOptions.az },
  { label: 'Z-A', value: OrderByOptions.za },
];

export const Filters: FunctionComponent<FiltersProps> = observer(({ taskType }: FiltersProps) => {
  const { t } = useTranslation('translation');
  const { tasksStore } = useStores();
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(Period.LastDays);
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [filterBy, setFilterBy] = useState<FilterByOptions>(FilterByOptions.all);
  const [filterApp, setFilterApp] = useState<string>('');
  const [orderBy, setOrderBy] = useState<OrderByOptions>(OrderByOptions.dateDesc);

  useEffect(() => {
    tasksStore.filterTasks(taskType, filterSearch, selectedPeriod, filterBy, filterApp, orderBy);
  }, [filterSearch, selectedPeriod, filterBy, filterApp, orderBy, taskType]);

  useEffect(() => {
    setFilterSearch('');
  }, [taskType]);

  useEffect(() => {
    setFilterApp((taskType === TaskType.alert().id ? tasksStore.alertApps : tasksStore.notificationApps)[0]);
  }, [tasksStore.notificationApps, tasksStore.alertApps]);

  return (
    <>
      <div className="filters-panel__tabs">
        {tabs.map((item) => (
          <div
            key={item.type}
            onClick={() => {
              setSelectedPeriod(item.type);
            }}
            className={`filters-panel__tab ${item.type === selectedPeriod ? 'selected' : ''}`.trim()}
          >
            {t(`AlertsAndNotifications.${item.name}`)}
          </div>
        ))}
      </div>
      <div className="filters-panel__search">
        <Icon iconName="search"></Icon>
        <InputTextbox
          placeholder={t('Shared.Filter')}
          name="filter"
          className="db-input-text with-search"
          error={undefined}
          value={filterSearch}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setFilterSearch(event.target.value);
          }}
        />
      </div>
      <div className="filters-panel__row">
        <InputSelect
          label={t('AlertsAndNotifications.FilterBy')}
          name="FilterBy"
          options={filterByOptions}
          value={filterBy}
          onChange={setFilterBy}
        />
        <InputSelect
          label={t('AlertsAndNotifications.App')}
          name="App"
          options={(taskType === TaskType.alert().id
            ? tasksStore.alertApps
            : tasksStore.notificationApps
          ).map<SimpleOption>((element) => ({ label: element, value: element }))}
          value={filterApp}
          onChange={setFilterApp}
        />
        <InputSelect
          value={orderBy}
          options={orderByOptions}
          label={t('AlertsAndNotifications.SortBy')}
          name="SortBy"
          onChange={setOrderBy}
        />
      </div>
    </>
  );
});
