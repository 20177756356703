import loader_animation from '@assets/images/loader.gif';
import './Loader.scss';

export const Loader = () => {
  return (
    <div className="db-loader__animation">
      <img src={loader_animation} alt="" />
      <p className="db-loader__description">Loading board</p>
    </div>
  );
};
