import userService, { type Log } from '@core/services/userService';
import { useStores } from '@hooks/useStores/useStores';
import { Grid, GridColumn, GridNoRecords, type GridCellProps } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { type TaskModel } from '@state/tasks/tasksStore';
import { TaskType } from '@state/tasks/taskType';
import { useUserContext } from '@state/user/userContext';
import { groupBy, type Dictionary } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils';
import { Icon } from '../Icon/Icon';
import './MyProfile.scss';

interface IProps {
  onClose: () => void;
  showProfile: boolean;
}

interface Role {
  app: string;
  roles: string;
  modules: string;
  countries: string;
}

interface CustomCellProps<T> extends GridCellProps {
  dataItem: T;
}

export const MyProfile: FunctionComponent<IProps> = observer(({ onClose, showProfile }) => {
  const { t } = useTranslation('translation');
  const { userData, setUser } = useUserContext();
  const [logsByApplication, setLogsByApplication] = useState<Dictionary<Log[]>>({});
  const [applications, setApplications] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<Role[]>([]);

  const { tasksStore } = useStores();
  const { tasks } = tasksStore;

  useEffect(() => {
    userService.getUser().then((user) => {
      setUser(user);
    });
    tasksStore.loadTasks();
  }, []);

  useEffect(() => {
    const dataGrouped = groupBy(userData.applicationLogs, (item) => item.applicationName);
    setLogsByApplication(dataGrouped);
    setApplications(Object.keys(dataGrouped));

    setUserRoles(
      userData.userRoles.map((app) => ({
        app: app.applicationName,
        roles: app.roles
          .flatMap((role) => (!role.name.startsWith('GEO') && !role.name.startsWith('MOD') ? role.name : []))
          .join(', '),
        modules: app.roles
          .flatMap((role) => (role.name.startsWith('MOD') ? role.name.replace('MOD-', '') : []))
          .join(', '),
        countries: app.roles
          .flatMap((role) => (role.name.startsWith('GEO') ? role.name.replace('GEO-', '') : []))
          .join(', '),
      })),
    );
  }, [userData]);

  const readTask = async (id: number, type: number): Promise<void> => {
    if (type === TaskType.alert().id) {
      await tasksStore.readAlert(id);
    }
    await tasksStore.readNotification(id);

    onClose();
  };

  return showProfile ? (
    <div className="my-profile__wrapper-hero">
      <div className="my-profile__wrapper">
        <div className="my-profile__wrapper-in">
          <div className="my-profile__header">
            <span className="my-profile__title">{t('MyProfile.MyProfile')}</span>
            <div className="my-profile__actions">
              <Icon onClick={onClose} iconName="close"></Icon>
            </div>
          </div>
          <div className="my-profile__body">
            <div className="my-profile__section">
              <div className="my-profile__section-title">{t('MyProfile.PersonalData')}</div>
              <div className="my-profile__personal-data">
                <p>
                  {t('Shared.User')}: <span>{userData.name}</span>
                </p>
                <p>
                  {t('Shared.Email')}: <span>{userData.email}</span>
                </p>
              </div>
            </div>
            <div className="my-profile__section">
              <div className="my-profile__user-info">
                <div className="my-profile__item first">
                  {applications.map((application) => (
                    <span key={application}>{application}</span>
                  ))}
                </div>
                <div className="my-profile__item data-highlight">
                  {applications.map((application) => (
                    <p key={application}>
                      {t('MyProfile.LastAccess')}:{' '}
                      <span>{formatDate(new Date(logsByApplication[application][0].lastAccess))}</span>
                    </p>
                  ))}
                </div>
                <div className="my-profile__item data-highlight">
                  {applications.map((application) => (
                    <p key={application}>
                      {t('MyProfile.AccessCount')}: <span>{logsByApplication[application][0].accessCount}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="my-profile__section">
              <div className="my-profile__section-title">{t('Shared.Roles')}</div>
              <Tooltip>
                <Grid data={userRoles} className="my-profile__table-roles" scrollable="none">
                  <GridColumn field="app" title={t('Shared.App')} />
                  <GridColumn field="roles" title={t('Shared.Role')} cell={RoleCell} />
                </Grid>
              </Tooltip>
            </div>
            <div className="my-profile__section">
              <div className="my-profile__section-title">Tasks</div>
              <Grid data={tasks} className="my-profile__table-roles" scrollable="none">
                <GridColumn field="platform" title={t('Shared.App')} />
                <GridColumn field="title" title={t('Shared.Task')} />
                <GridColumn
                  field="url"
                  title=" "
                  cell={(props) => (
                    <LinkCell
                      onClick={(id, taskType) => {
                        readTask(id, taskType);
                      }}
                      dataItem={props.dataItem}
                    />
                  )}
                />
                <GridNoRecords>{t('Shared.NoTasksPending')}</GridNoRecords>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
});

const RoleCell = ({ dataItem }: CustomCellProps<Role>): JSX.Element => {
  return (
    <td title={dataItem.modules}>
      {dataItem.countries ? [dataItem.roles, dataItem.countries].join(', ') : dataItem.roles}
    </td>
  );
};

interface LinkCellProps {
  onClick: (id: number, taskTypeId: number) => void;
  dataItem: TaskModel;
}

const LinkCell = ({ onClick, dataItem }: LinkCellProps): JSX.Element => {
  return (
    <td>
      <a
        className="table-icon"
        rel="noreferrer"
        target="_blank"
        href={dataItem.url}
        onClick={() => {
          onClick(dataItem.id, dataItem.type.id);
        }}
      >
        <Icon iconName="tick" />
      </a>
    </td>
  );
};
