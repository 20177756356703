import { InputSelect } from '@components/form/input-select/input-select';
import { DBButton } from '@components/shared/button/button';
import { SidePanel } from '@components/shared/SidePanel/SidePanel';
import { type RssResponse } from '@core/services/models/newsItemModel';
import { RssService } from '@core/services/rssServices';
import { yupResolver } from '@hookform/resolvers/yup';
import { RssSize } from '@containers/News/NewsSection/rssSize';
import { type FunctionComponent, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { showSuccessMessage } from '../../../utils';
import './RssForm.scss';
import { InputNumber } from '@components/form/input-number/input-number';

interface IProps {
  rss: RssResponse | null;
  onSave: () => void;
  onClose: () => void;
}

const schema = yup.object({
  size: yup.number().required(),
  order: yup.number().required(),
  autoPlayInterval: yup.number().required(),
});

type FormData = yup.InferType<typeof schema>;

export const RssForm: FunctionComponent<IProps> = ({ rss, onClose, onSave }) => {
  const rssService = new RssService();
  const rssSizeList = RssSize.getList();

  const [selectedSize, setSelectedSize] = useState(rss?.rssSizeId);

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async (formdata) => {
    if (rss) {
      await rssService.update({ rssTypeId: rss?.rssTypeId, rssSizeId: formdata.size, ...formdata }).then(() => {
        showSuccessMessage('Rss has been updated succesfully');
        onSave();
      });
    }
  };

  useEffect(() => {
    if (rss) {
      reset({ autoPlayInterval: rss.autoPlayInterval, order: rss.order, size: rss.rssSizeId });
      setSelectedSize(rss?.rssSizeId);
    }
  }, [rss]);

  return (
    <SidePanel onClose={onClose} title={rss ? `Edit ${rss?.rssType}` : ''}>
      <>
        {rss ? (
          <form
            className="db-form"
            onSubmit={(e) => {
              handleSubmit(onSubmit)(e);
            }}
          >
            <div className="db-form__structure">
              <span className="db-form__section-title">Information</span>
              <div className="db-form__row">
                <div className="db-form__col-0">
                  <InputSelect
                    name="size"
                    value={selectedSize}
                    options={rssSizeList.map((d) => ({ label: d.name, value: d.id }))}
                    onChange={(size) => {
                      setValue('size', size);
                      setSelectedSize(size)
                    }}
                    error={errors.size?.message}
                  />
                </div>
              </div>
              <div className="db-form__row">
                <div className="db-form__col-0">
                  <InputNumber {...register('order')} label="Order" error={errors.order?.message} />
                </div>
              </div>
              <div className="db-form__row">
                <div className="db-form__col-0">
                  <InputNumber
                    label="Auto Play Interval"
                    {...register('autoPlayInterval')}
                    error={errors.autoPlayInterval?.message}
                  />
                </div>
              </div>
            </div>
            <div className="db-form__actions">
              <DBButton name="simple" type="button" onClick={onClose} text="Cancel"></DBButton>
              <DBButton name="primary" type="submit" text="Save"></DBButton>
            </div>
          </form>
        ) : null}
      </>
    </SidePanel>
  );
};
