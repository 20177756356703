import { type NewsItemResponse } from '@core/services/models/newsItemModel';
import { type RssType } from '../../../state/block/models/rssType';
import { type FC } from 'react';
import { formatDate } from '../../../utils';

export class RssSize {
  id: number;
  name: string;
  element: FC<RssSizeElementProps>;

  constructor(id: number, name: string, element: FC<RssSizeElementProps>) {
    this.id = id;
    this.name = name;
    this.element = element;
  }

  static small(): RssSize {
    return new RssSize(1, 'Small', SmallNewsItem);
  }

  static medium(): RssSize {
    return new RssSize(2, 'Medium', MediumNewsItem);
  }

  static large(): RssSize {
    return new RssSize(3, 'Large', LargeNewsItem);
  }

  static getList(): RssSize[] {
    return [this.small(), this.medium(), this.large()];
  }

  static getById(id: number): RssSize | undefined {
    return this.getList().find((c) => c.id === id);
  }
}

export interface RssSizeElementProps {
  newsItem: NewsItemResponse;
  type: RssType;
}

const SmallNewsItem: React.FC<RssSizeElementProps> = ({ newsItem, type }: RssSizeElementProps) => {
  return (
    <div className="carousel-item" style={{ background: type.color }}>
      <div className="carousel-item__header">
        <p className="source">{newsItem.source}</p>
      </div>
      <div className="carousel-item__body">
        <div className="medium-wrapper">
          <img className="news-icon" src={type.icon}></img>

          <div className="description-wrapper">
            <span className="carousel-item__date">{formatDate(newsItem.date)}</span>
            <a
              className="carousel-item__title"
              title={newsItem.title}
              href={newsItem.link}
              target="_blank"
              rel="noreferrer"
            >
              {newsItem.title}
            </a>
            <p className="carousel-item__description small" title={newsItem.description}>
              {newsItem.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const MediumNewsItem: React.FC<RssSizeElementProps> = ({ newsItem, type }: RssSizeElementProps) => {
  return (
    <div className="carousel-item" style={{ background: type.color }}>
      <div className="carousel-item__header">
        <p className="source">{newsItem.source}</p>
      </div>
      <div className="carousel-item__body">
        <div className="medium-wrapper">
          <img className="news-icon" src={type.icon}></img>

          <div className="description-wrapper">
            <span className="carousel-item__date">{formatDate(newsItem.date)}</span>
            <a
              className="carousel-item__title"
              title={newsItem.title}
              href={newsItem.link}
              target="_blank"
              rel="noreferrer"
            >
              {newsItem.title}
            </a>
            <p className="carousel-item__description medium" title={newsItem.description}>
              {newsItem.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const LargeNewsItem: React.FC<RssSizeElementProps> = ({ newsItem, type }: RssSizeElementProps) => {
  return (
    <div className="carousel-item" style={{ background: type.color }}>
      <div className="carousel-item__header">
        <p className="source">{newsItem.source}</p>
      </div>
      <div className="carousel-item__body">
        <div className="description-wrapper large">
          <img className="news-icon" src={type.icon}></img>
          <span className="carousel-item__date">{formatDate(newsItem.date)}</span>
          <a
            className="carousel-item__title"
            title={newsItem.title}
            href={newsItem.link}
            target="_blank"
            rel="noreferrer"
          >
            {newsItem.title}
          </a>
          <p className="carousel-item__description large" title={newsItem.description}>
            {newsItem.description}
          </p>
        </div>
      </div>
    </div>
  );
};
