import { LoaderSmall } from '@components/shared/LoaderSmall/LoaderSmall';
import { AppConfiguration } from '@core/config/appConfiguration';
import { ApplicationIds } from '@core/services/models/application';
import { useDashboard } from '@hooks/useDashboald/useDashboard';
import { useDashboardContext } from '@state/dashboard/context/DasboardContext';
import { useEffect } from 'react';
import { MetricWidget } from '../MetricWidget/MetricWidget';

export const ArcaMetricsGroup = (): JSX.Element => {
  const { getArcaData } = useDashboard();
  const { applicationsData } = useDashboardContext();
  const arcaData = applicationsData[ApplicationIds.Arca];
  const arcaUrl = AppConfiguration.getInstance().settings.apps.arcaUrl;
  useEffect(() => {
    if (!applicationsData[ApplicationIds.Arca]) {
      getArcaData();
    }
  }, [getArcaData]);
  return arcaData ? (
    <>
      <MetricWidget
        value={arcaData?.data.workstreams ?? '0'}
        description="Workstreams"
        link={`${arcaUrl}/#/Workstreams`}
      />
      <MetricWidget value={arcaData?.data.kpms ?? '0'} description="Total KPMs" link={`${arcaUrl}/#/Admin/KPMs`} />
      <MetricWidget value={arcaData?.data.kpis ?? '0'} description="Total KPIs" link={`${arcaUrl}/#/Admin/KPIs`} />
      <MetricWidget
        value={arcaData?.data.pendingKpmForm ?? '0'}
        description="Pending KPMs Values"
        link={`${arcaUrl}/#/Forms`}
      />
      <MetricWidget
        urgent
        value={arcaData?.data.endOfTimeKpmForm ?? '0'}
        description="Expired KPMs Values"
        link={`${arcaUrl}/#/Forms`}
      />
    </>
  ) : (
    <LoaderSmall />
  );
};
