import { FilterOptions, type DataModel, type Filters } from '@components/dashboard/shared/filterOptions/FilterOptions';
import { ToolTipFormated } from '@components/dashboard/shared/ToolTipFormated';
import {
  Chart,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { type SparkLineBlockData, type SparkLineData } from '@state/block/models/sparkLineData';
import { useEffect, useState, type FunctionComponent } from 'react';
import './SparkLine.scss';

export interface Props {
  visibleCategories?: boolean;
  data: SparkLineBlockData[];
}

const emptySparkLineBlockData: SparkLineBlockData = {
  categories: [],
  metaData: [],
  option: '',
};

export const Sparkline: FunctionComponent<Props> = ({ data, visibleCategories }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<Filters>({ filter: '' });
  const [filterData, setFilterData] = useState<DataModel[]>([]);
  const [dataSpark, setDataSpark] = useState<SparkLineBlockData>(emptySparkLineBlockData);

  useEffect(() => {
    setFilterData(data.map((d) => ({ name: d.option, value: d.option })));
    setSelectedFilter({ filter: data[0].option });
  }, [data]);

  useEffect(() => {
    const dataSelected = data.find((Item) => Item.option === selectedFilter.filter);
    if (dataSelected === undefined) return;
    setDataSpark(dataSelected);
  }, [selectedFilter]);

  const onChange = (event: Filters) => {
    setSelectedFilter({ filter: event.filter });
  };

  const valueAxisLabels = {
    font: '11px Telefonica Web Regular, Arial, sans-serif',
  };

  const valueLegendLabels = {
    font: '13px Telefonica Web Regular, Arial, sans-serif',
    margin: 5,
  };

  const defaultTooltipRender = (value: Any) => {
    return <ToolTipFormated value={value} />;
  };

  return dataSpark ? (
    <div className="sparkline-container">
      <FilterOptions data={filterData} value={selectedFilter} onChange={onChange} />
      <Chart zoomable={false} style={{ width: '100%', height: 'calc(100% - 50px)', background: 'transparent' }}>
        <ChartLegend align="start" position="top" orientation="horizontal" labels={valueLegendLabels} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={dataSpark?.categories}
            labels={valueAxisLabels}
            visible={visibleCategories}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem labels={valueAxisLabels} />
        </ChartValueAxis>
        <ChartTooltip shared={true} render={defaultTooltipRender} background='#000000' color='#FFFFFF'/>
        <ChartAxisDefaults majorGridLines={{ color: 'rgba(255, 255, 255, 0.08)', step: 1 }} />
        <ChartSeries>
          {dataSpark?.metaData.map((element, index) => (
            <ChartSeriesItem
              key={index}
              type="line"
              color={element.color}
              data={element.data.map((d: SparkLineData) => {
                return {
                  ...d,
                  value: d.value.replace('%', '').replace(/,/g, ''),
                };
              })}
              name={element.country}
              markers={{ visible: false }}
              width={2}
              dynamicHeight={true}
            ></ChartSeriesItem>
          ))}
        </ChartSeries>
      </Chart>
    </div>
  ) : null;
};
