import { type BlockMetadata, type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import {
  type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryCount,
  type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_OptimizationByCountryAndStateResponse,
} from '../services';

export const mapOptimizationbycountryandstateMultiple = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_OptimizationByCountryAndStateResponse[],
): MultipleBlockMetadata[] => {
  const responseData = [
    {
      option: 'Single',
      data: response
        .map((item) => mapOptimizationbycountryandstate(item.state, item.countryCounts))
        .sort((a, b) => b.data.length - a.data.length),
    },
  ];
  return responseData;
};

export const mapOptimizationbycountryandstate = (
  state: string,
  states: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_CountryCount[],
): BlockMetadata => ({
  name: state,
  data: states.map((dataState) => ({ name: dataState.country, value: dataState.count })),
});
