import { DBButton } from '@components/shared/button/button';
import { CustomGrid } from '@components/shared/CustomGrid/CustomGrid';
import { GoBackHeader } from '@components/shared/GoBackHeader/GoBackHeader';
import { type DataResult, process } from '@progress/kendo-data-query';
import { GridColumn, type GridRowClickEvent } from '@progress/kendo-react-grid';
import { type Application } from '@state/permissionsManagement/models/applicacion';
import { usePermissionsManagement } from '@state/permissionsManagement/usePermissionsManagement';
import { observer } from 'mobx-react-lite';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserForm } from '../UserForm/UserForm';
import './AppSelector.scss';

export const AppSelector: FunctionComponent = observer(() => {
  const { getApplications } = usePermissionsManagement();
  const [apps, setApps] = useState<Application[]>([]);
  const [appsFiltered, setAppsFiltered] = useState<DataResult>();
  const [userFormOpen, setUserFormOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'PermissionsManagement.AppSelector' });
  const allAppsRowData = { name: 'All' };

  useEffect(() => {
    getApplications().then((apps) => {
      setApps(apps);
    });
  }, []);

  useEffect(() => {
    setAppsFiltered(process([allAppsRowData, ...apps], {}));
  }, [apps]);

  const appClick = (e: GridRowClickEvent): void => {
    const { id } = e.dataItem as Application;
    const url = id ? `/permissionsManagement/apps/${id}/users` : '/permissionsManagement/users';
    navigate(url);
  };

  const openUserForm = (): void => {
    setUserFormOpen(true);
  };

  const ImageCell = (): JSX.Element => {
    return <td className="db-app-selector__body-cell-img" />;
  };

  return (
    <>
      <div className="db-app-selector">
        <GoBackHeader title={t('Application', { context: 'All' })} />
        <div className="app-selected__actions">
          <div>
            <DBButton
              type="button"
              name="withIcon"
              iconName="add"
              text={t('AddUser')}
              onClick={openUserForm}
            ></DBButton>
          </div>
        </div>

        <CustomGrid filterable={false} data={appsFiltered} onRowClick={appClick}>
          <GridColumn cell={ImageCell} resizable={false} width={65} />
          <GridColumn field="name" title={t('Application')} />
          <GridColumn field="description" title={t('Description')} />
        </CustomGrid>
      </div>
      {userFormOpen ? (
        <UserForm
          onSave={() => {
            setUserFormOpen(false);
          }}
          onClose={() => {
            setUserFormOpen(false);
          }}
        />
      ) : null}
    </>
  );
});
