import { Loader } from '@components/shared/Loader/Loader';
import { useAuth } from '@hooks/useAuth/useAuth';
import { useLayoutEffect, useState, type PropsWithChildren } from 'react';
import './PrivateRoute.scss';

export const PrivateRoute = ({ children }: PropsWithChildren): JSX.Element => {
  const auth = useAuth();
  const [hasAccess, setHasAccess] = useState<boolean>(false);

  useLayoutEffect(() => {
    const access = auth.isAuthenticated();
    if (!access) {
      auth.loginRedirect();
    } else {
      setHasAccess(access);
    }
  }, []);

  return hasAccess ? (
    <>{children}</>
  ) : (
    <main className="loader-bg">
      <Loader />
    </main>
  );
};
