import { AlertMapper } from './mappers/alertMapper';
import { type AlertModel } from './models/alertModel';
import { AlertsClient } from './services';

export class AlertService {
  async getAll(): Promise<AlertModel[]> {
    const content = await alertsClient.alerts();

    return AlertMapper.map(content);
  }

  async markAsRead(id: number): Promise<void> {
    await alertsClient.read({ id });
  }

  async delete(id: number): Promise<void> {
    await alertsClient.delete({ id });
  }
}

const alertsClient = new AlertsClient();
export const alertsService = new AlertService();
