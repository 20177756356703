/* eslint-disable @typescript-eslint/naming-convention */
import { months, tableReportsColumnTitles } from '@shared/utils';
import { type BlockMetadata, type Metadata, type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import { type BlockTableData } from '../../../state/block/models/blockTableData';
import {
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentArrivalsFlightsByCountries,
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightEvolutionInfo,
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths,
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByYearAndCountry,
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightByYear,
  type Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo,
} from '../services';

export const mapArrivalsFlight = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightEvolutionInfo,
): BlockMetadata[] => {
  return [
    ...response.flightEvolutionInfo.map(mapTelefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo),
  ];
};

export const mapPassengerByMonthAndYear = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightEvolutionInfo,
): BlockMetadata[] => {
  return [
    ...response.flightEvolutionInfo.map(mapTelefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo),
  ];
};

export const mapArrivalsFlightBarChar = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightEvolutionInfo,
): MultipleBlockMetadata[] => {
  return [
    {
      option: 'Single',
      data: [
        ...response.flightEvolutionInfo.map(mapTelefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo),
      ],
    },
  ];
};

export const mapArrivalEvolutionFlightsBarChar = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo[],
): MultipleBlockMetadata[] => {
  return [
    {
      option: 'Single',
      data: [...response.map(mapTelefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo)],
    },
  ];
};

export const mapTelefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightEvolutionInfo,
): BlockMetadata => {
  return {
    name: response.year,
    data: response.flightByYears.map(mapTelefonica_DGSI_Dashboard_Domain_Services_Responses_FlightByYear),
  };
};

export const mapTelefonica_DGSI_Dashboard_Domain_Services_Responses_FlightByYear = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_FlightByYear,
): Metadata => {
  return {
    name: months[new Date(response.date).getMonth()],
    value: response.flightsAmount,
  };
};

export const mapFlightsPassengersByCountries = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentArrivalsFlightsByCountries[],
): BlockTableData[] => {
  return [...response.map(mapFlightsPassengersToTable)];
};

export const mapFlightsPassengersToTable = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentArrivalsFlightsByCountries,
): BlockTableData => {
  return {
    columns: [tableReportsColumnTitles.country, tableReportsColumnTitles.flights, tableReportsColumnTitles.passengers],
    rows: response.arrivalsFlightsByCountries.map((value) => {
      return { values: [value.country, value.flights, value.passengers] };
    }),
    option: response.year.toString(),
  };
};

export const mapFlightsbyCountriesaAndMonths = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths[],
): BlockTableData[] => {
  return [...response.map(mapFlightsByCountriesAndMonthsToTable)];
};

export const mapFlightsByCountriesAndMonthsToTable = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByCountriesAndMonths,
): BlockTableData => {
  return {
    columns: [tableReportsColumnTitles.country, ...months, tableReportsColumnTitles.total],
    rows: response.flightsByCountriesAndMonths.map((value) => {
      return {
        values: [
          value.country,
          value.january,
          value.february,
          value.march,
          value.april,
          value.may,
          value.june,
          value.july,
          value.august,
          value.september,
          value.october,
          value.november,
          value.december,
          value.total,
        ],
      };
    }),
    option: response.year.toString(),
  };
};

export const mapFlightsByCountries = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByYearAndCountry[],
): BlockTableData[] => {
  return response.map(mapFlightsByCountriesTableReport);
};

export const mapFlightsByCountriesTableReport = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentFlightsByYearAndCountry,
): BlockTableData => {
  return {
    columns: [
      tableReportsColumnTitles.country,
      tableReportsColumnTitles.nationals,
      tableReportsColumnTitles.internationals,
      tableReportsColumnTitles.total,
    ],
    rows: response.flightsByYearAndCountries.map((value) => {
      return { values: [value.country, value.flightsNational, value.flightsInternational, value.total] };
    }),
    option: response.year.toString(),
  };
};
