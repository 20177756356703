import { donutChartSizes } from '@components/dashboard/shared/chartSizes';
import {
  type DataModel,
  FilterOptions,
  type Filters,
  type FilterKey,
} from '@components/dashboard/shared/filterOptions/FilterOptions';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesDefaults,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import { type DonutDataGrouped, type DonutTableData } from '@state/block/models/donutTableData';
import { type FunctionComponent, useEffect, useState } from 'react';
import './DonutChart.scss';

export interface Props {
  data: DonutTableData[];
  isModal?: boolean;
}

const emptyPDonutData: DonutDataGrouped = {
  group: '',
  data: [],
};

export const DonutChart: FunctionComponent<Props> = ({ data, isModal = false }: Props) => {
  const labelContent = (e: { category: string; value: string }): string => `${e.category}: \n ${e.value}`;
  const [filterData, setFilterData] = useState<DataModel[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<Filters>({ filter: '', subfilter: '' });
  const [donutTableData, setDonutTableData] = useState<DonutDataGrouped>(emptyPDonutData);

  const renderTooltip = (context: any): JSX.Element => {
    const { category, series, value } = context.point || context;
    return (
      <div className="donut-tooltip">
        {category} {series.name && `(${series.name as string | number})`}: {value}
      </div>
    );
  };

  useEffect(() => {
    setFilterData(
      data.map((d) => ({
        name: d.name,
        value: d.name,
        subfilter: d.data.map((s) => ({
          name: s.group as FilterKey,
          value: s.group,
        })),
      })),
    );
    setSelectedFilter({ filter: data[0].name, subfilter: data[0].data[0].group });
  }, [data]);

  useEffect(() => {
    const dataGroupSelected = data.find((Item) => Item.name === selectedFilter.filter);
    if (dataGroupSelected == null) return;
    const dataSelected = dataGroupSelected.data.find((Item) => Item.group === selectedFilter.subfilter);
    if (dataSelected == null) return;
    setDonutTableData(dataSelected);
  }, [selectedFilter]);

  const onChange = (filters: Filters): void => {
    setSelectedFilter({ filter: filters.filter, subfilter: filters.subfilter });
  };

  return donutTableData.data.length > 0 ? (
    <div className="donut-container">
      <FilterOptions data={filterData} value={selectedFilter} onChange={onChange} />

      <Chart zoomable={false} style={{ width: '100%', height: 'calc(100% - 40px)', background: 'transparent' }}>
        <ChartTooltip shared={true} render={renderTooltip} background="#000000" color="#FFFFFF" />
        <ChartLegend visible={false} />
        <ChartSeriesDefaults type="donut" gap={5}></ChartSeriesDefaults>
        <ChartSeries>
          <ChartSeriesItem
            size={isModal ? donutChartSizes.modalHeight : donutChartSizes.defaultHeight}
            type="donut"
            margin={3}
            startAngle={150}
            name={donutTableData?.group}
            data={donutTableData?.data}
            holeSize={isModal ? donutChartSizes.modalHole : donutChartSizes.defaultHole}
            colorField="color"
          >
            <ChartSeriesLabels
              position="outsideEnd"
              background="none"
              content={labelContent}
              font="13px Telefonica Web Regular, Arial, sans-serif"
            />
          </ChartSeriesItem>
        </ChartSeries>
      </Chart>
    </div>
  ) : null;
};
