export const ERRORS: Record<number, { code: number; message: string }> = {
  403: { code: 500, message: "You don't have permissions to access this page" },
  500: {
    code: 500,
    message: 'An error has occurred, please contact the administrator.',
  },
  404: {
    code: 404,
    message: 'Page not found',
  },
};
