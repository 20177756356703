import { getColor } from '@shared/utils';
import { type DonutTableData } from '@state/block/models/donutTableData';
import lodash from 'lodash';
import { type DataGrouped } from '../models/dataGrouped';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountryByModule } from '../services';

export const donutPostsByCountryAndModuleMapper = (
  data: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByCountryByModule[],
): DonutTableData[] => {
  const dataGroupedByCountry = lodash.groupBy(data, (item) => item.countryName);
  const countries = Object.keys(dataGroupedByCountry);
  const dataGroupedByCountryAndNature: DataGrouped = {};
  countries.forEach((country) => {
    dataGroupedByCountryAndNature[country] = {
      All: dataGroupedByCountry[country],
      ...lodash.groupBy(dataGroupedByCountry[country], (item) => item.postNatureName),
    };
  });

  return (countries.length > 0)
    ? countries.map((country) => ({
        name: country,
        data: Object.keys(dataGroupedByCountryAndNature[country]).map((nature) => ({
          group: nature,
          data: dataGroupedByCountryAndNature[country][nature].map((itemMetadata, index) => ({
            category: itemMetadata.moduleNumber,
            value: itemMetadata.postsCount,
            color: getColor(index),
          })),
        })),
      }))
    : [{ name: 'posts', data: [{ group: 'all', data: [{ category: '', value: 0, color: '' }] }] }];
};
