import { AppConfiguration } from '@core/config/appConfiguration';
import { getColor } from '@shared/utils';
import { type TreeMapData } from '@state/block/models/treeMapData';
import lodash from 'lodash';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopPageViewsCount } from './../services';

export const mapPageViewToTreeMap = (
  data: Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_TopPageViewsCount[],
  paths: number,
): TreeMapData[] => {
  return groupByPages(
    data.map((item) => ({
      color: '',
      name: formatPage(item.page, paths),
      value: item.count,
    })),
  );
};

const formatPage = (page: string, paths: number): string => {
  let url = page.split('?')[0];
  url = lodash.replace(url, '#/', '');
  url = lodash.replace(url, AppConfiguration.getInstance().settings.apps.journeyUrl, '');
  url = lodash.replace(url, AppConfiguration.getInstance().settings.apps.arenaUrl, '');
  url = lodash.replace(url, AppConfiguration.getInstance().settings.apps.dataCampusUrl, '');
  if (url === '') {
    url = 'home';
  }
  let responseSection = '';
  const urlArray = url.split('/');
  for (let step = 0; step < paths; step++) {
    responseSection += urlArray[step] ? '/' + lodash.capitalize(urlArray[step]) : '';
  }
  return responseSection.substring(1);
};

const groupByPages = (pages: TreeMapData[]): TreeMapData[] => {
  const pagesGrouped = lodash.groupBy(pages, (p) => p.name);
  const sections = Object.keys(pagesGrouped);

  return sections.map<TreeMapData>((item, index) => ({
    color: getColor(index),
    name: item,
    value: lodash.sum(pagesGrouped[item].map((v) => v.value)),
  }));
};
