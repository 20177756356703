import { getColor } from '@shared/utils';
import { type DonutTableData } from '@state/block/models/donutTableData';
import { type Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByFlightTypeByYear } from '../services';

export const mapDonutPassengerByFlightTypeByYearDonut = (
  data: Telefonica_DGSI_Dashboard_Domain_Services_Responses_ContentPassengersByFlightTypeByYear[],
): DonutTableData[] => {
  return [
    {
      name: '',
      data: data.map((item) => ({
        group: item.year.toString(),
        data: [
          {
            category: 'Only International',
            value: item.internationalPassengers,
            color: getColor(5),
          },
          {
            category: 'Only National',
            value: item.nationalPassengers,
            color: getColor(6),
          },
          {
            category: 'International and National',
            value: item.internationalAndNationalPassengers,
            color: getColor(7),
          },
        ],
      })),
    },
  ];
};
