import { DropdownMenu } from '@components/shared/dropdown-menu/dropdown-menu';
import { Icon } from '@components/shared/Icon/Icon';
import { Application } from '@core/services/models/application';
import { type NotificationItem } from '@core/services/models/notification';
import { useStores } from '@hooks/useStores/useStores';
import { observer } from 'mobx-react-lite';
import { useEffect, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { timeAgo } from '../../../../utils';
import './Notifications.scss';

export const Notifications: FunctionComponent = observer(() => {
  const { t } = useTranslation('translation');
  const { tasksStore } = useStores();

  useEffect(() => {
    tasksStore.loadTasks();
  }, []);

  const markAsRead = async (id: number): Promise<void> => {
    await tasksStore.readNotification(id);
  };

  const markAllAsRead = async (): Promise<void> => {
    await tasksStore.reaAlldNotification();
  };

  const deleteNotification = async (id: number): Promise<void> => {
    await tasksStore.deleteNotification(id);
  };

  const getTitle = (notification: NotificationItem): string => {
    if (!notification?.metaData) {
      return notification.title;
    }

    let response = '';

    if (notification.platform === 'Arena') {
      response = `${notification.section} ${notification.metaData?.payload} ${t(
        'AlertsAndNotifications.PendingAction',
      )}`;
    }

    if (notification.platform === 'Journey') {
      response = t('AlertsAndNotifications.PassengersRisk');
    }

    return response;
  };

  const getMessage = (notification: NotificationItem): string => {
    if (!notification?.metaData) {
      return notification.message;
    }

    const response = '';

    if (notification.platform === 'Arena') {
      return `${t('AlertsAndNotifications.ThereIs')} ${notification.metaData?.type} ${t(
        'AlertsAndNotifications.PendingReview',
      )}`;
    }

    if (notification.platform === 'Journey') {
      const metadataParse = JSON.parse(notification.metaDataJson);

      return `${t('AlertsAndNotifications.ThePassenger')} ${metadataParse.PassengerName} ${
        metadataParse.PassengerSurname
      } ${t('AlertsAndNotifications.WhoFlyingFrom')} ${metadataParse.DepartureCity}, ${
        metadataParse.DepartureCountry
      } ${t('AlertsAndNotifications.On')} ${metadataParse.DepartureDate}, ${t('AlertsAndNotifications.ArrivingIn')} ${
        metadataParse.ArrivalCity
      }, ${metadataParse.ArrivalCountry} ${t('AlertsAndNotifications.On')} ${metadataParse.ArrivalDate} ${t(
        'AlertsAndNotifications.ConsideredRisk',
      )}`;
    }

    return response;
  };

  return (
    <ul className="notifications-panel__list">
      {tasksStore.notificationsFiltered.map((item) => (
        <li className={`notifications-panel__item${item.read ? ' read' : ''}`} key={`notification_${item.id}`}>
          <div className={`notifications-panel__item-ball ${item.platform.toLocaleLowerCase()}`}>
            {Application.getByName(item.platform)?.acronym}
          </div>
          <div className="notifications-panel__info">
            <div className="notifications-panel__item-title-container">
              <p className="notifications-panel__item-title">{getTitle(item)}</p>
              <div className="notifications-panel__item-menu">
                <p className="notifications-panel__item-time">{`${timeAgo(new Date(item.showDate))} ago`}</p>
                <DropdownMenu
                  classes={{
                    menuClassName: 'data-insured__dropdown',
                  }}
                  renderToggle={() => <Icon iconName="dots"></Icon>}
                >
                  <ul className="dropdown-menu__list">
                    <li
                      onClick={() => {
                        markAllAsRead();
                      }}
                      className="dropdown-menu__list-item"
                    >
                      <Icon iconName="tick"></Icon>
                      <span className="dropdown-menu__list-item-text">{t('DropdownMenu.MarkAllAsRead')}</span>
                    </li>
                    {!item.read ? (
                      <li
                        onClick={() => {
                          markAsRead(item.id);
                        }}
                        className="dropdown-menu__list-item"
                      >
                        <Icon iconName="tick"></Icon>
                        <span className="dropdown-menu__list-item-text">{t('DropdownMenu.MarkAsRead')}</span>
                      </li>
                    ) : null}
                    <li
                      onClick={() => {
                        deleteNotification(item.id);
                      }}
                      className="dropdown-menu__list-item"
                    >
                      <Icon iconName="trash"></Icon>
                      <span className="dropdown-menu__list-item-text">{t('DropdownMenu.Delete')}</span>
                    </li>
                  </ul>
                </DropdownMenu>
              </div>
            </div>
            <p className="notifications-panel__item-description">{getMessage(item)}</p>
            <a
              className="notifications-panel__item-detail"
              href={item.url}
              onClick={() => {
                markAsRead(item.id);
              }}
              rel="noreferrer"
              target="_blank"
            >
              {t('AlertsAndNotifications.GoToNotification')}
            </a>
          </div>
        </li>
      ))}
    </ul>
  );
});
