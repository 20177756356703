import { useTranslation } from 'react-i18next';
import './MetricWidget.scss';

export interface MetricWidgetProps {
  value: number | string;
  description: string;
  link?: string;
  urgent?: boolean;
  actionClick?: () => void;
}

export const MetricWidget = ({ description, value, link, urgent, actionClick }: MetricWidgetProps): JSX.Element => {
  const { t } = useTranslation('translation');

  return (
    <div className="metric-widget">
      <h2 className={`metric-widget__value${urgent ? ' metric-widget__value--urgent' : ''}`}>{value}</h2>
      <p className="metric-widget__description">{description}</p>
      {link && (
        <a href={link} className="metric-widget__link" rel="noreferrer" target="_blank">
          {t('Home.View')}
        </a>
      )}
      {actionClick && (
        <a
          href="#"
          className="metric-widget__link"
          onClick={(event) => {
            event.preventDefault();
            actionClick();
          }}
        >
          {t('Home.View')}
        </a>
      )}
    </div>
  );
};
