import {
  useState,
  type ReactNode,
  type HTMLAttributes,
  useRef,
  useEffect,
  Children,
  isValidElement,
  cloneElement,
  type CSSProperties,
  useMemo,
  useCallback,
} from 'react';
import { debounce, groupBy, uniq } from 'lodash';

const ERRORFACTOR = 1.2;

const getCellStyle = (
  stoneColumns: number,
  stoneRows: number,
  columnBreakPoints: Record<number, number>,
  rows: number,
  gutter: number,
  windowWidth: number,
  stoneHeight: number,
): CSSProperties => {
  const columns =
    columnBreakPoints[
      Math.max(
        ...Object.keys(columnBreakPoints)
          .filter((bk) => +bk < windowWidth)
          .map(Number),
      )
    ] ?? 1;

  return {
    width:
      stoneColumns >= columns
        ? '100%'
        : `calc((100% / ${columns} * ${stoneColumns}) - (${gutter}px / ${columns} * ${columns - stoneColumns}))`,
    height: `calc((${stoneHeight}px / ${rows} * ${stoneRows}) - (${gutter}px))`,
  };
};

function doRectanglesOverlap(rect1: DOMRect, rect2: DOMRect, error: number): boolean {
  const overlapsY =
    (rect2.top - error <= rect1.bottom && rect2.top + error >= rect1.top) ||
    (rect2.bottom - error <= rect1.bottom && rect2.bottom + error >= rect1.top);

  const overlapsX =
    (rect2.left - error <= rect1.right && rect2.left + error >= rect1.left) ||
    (rect2.right - error <= rect1.right && rect2.right + error >= rect1.left);

  return overlapsX && overlapsY;
}

const findBestPosition = (
  stonePositions: CSSProperties[],
  wrapperWidth: number,
  gutter: number,
  width: number,
  height: number,
): CSSProperties => {
  const stonesByRow = {
    ...groupBy(stonePositions, (stone) => stone.top),
    ...groupBy(stonePositions, (stone) => +(stone.bottom ?? 0) + gutter),
  };

  const rows = uniq(Object.keys(stonesByRow));
  let leftReferenceStone: CSSProperties | null | undefined = null;
  let topReferenceStone: CSSProperties | null | undefined = null;

  for (const row of rows) {
    const rowElements = stonesByRow[row];
    if (rowElements.length) {
      if (!leftReferenceStone) {
        leftReferenceStone = rowElements
          .sort((a, b) => +(a.left ?? 0) - +(b.left ?? 0))
          .find(
            (position) =>
              +(position.right ?? Infinity) + gutter + width <= wrapperWidth &&
              !stonePositions.some((s) =>
                doRectanglesOverlap(
                  s as DOMRect,
                  new DOMRect(+(position.right ?? 0) + gutter, +(position.top ?? 0), width, height),
                  gutter / 2,
                ),
              ),
          );
      }

      const nextRow = stonesByRow[rows[rows.indexOf(row) + 1]] ?? [];

      if (!topReferenceStone && nextRow.length) {
        topReferenceStone = rowElements
          .sort((a, b) => +(a.bottom ?? 0) - +(b.bottom ?? 0))
          .find(
            (position) =>
              +(position.left ?? 0) + width <= wrapperWidth &&
              !stonePositions.some((s) =>
                doRectanglesOverlap(
                  s as DOMRect,
                  new DOMRect(+(position.left ?? 0), +(position.bottom ?? 0) + gutter, width, height),
                  gutter / 2,
                ),
              ),
          );
      }
    }
  }

  if (
    leftReferenceStone &&
    +(leftReferenceStone?.top ?? 0) <= +(topReferenceStone?.bottom ?? Infinity) + gutter * ERRORFACTOR
  ) {
    const left = +(leftReferenceStone.right ?? 0) + gutter;
    const top = +(leftReferenceStone.top ?? 0);
    return { left, right: left + width, top, bottom: top + height };
  }

  if (topReferenceStone) {
    const left = +(topReferenceStone.left ?? 0);
    const top = +(topReferenceStone.bottom ?? 0) + gutter;
    return { left, right: left + width, top, bottom: top + height };
  }

  const left = 0;
  const top = Math.max(...stonePositions.map((stone) => +(stone.bottom ?? 0) + gutter), 0);
  return { left, right: left + width, top, bottom: top + height };
};

export interface MasonryStone {
  element: ReactNode;
  columns: number;
  rows: number;
  id?: number;
}

export interface MasonryLayoutProps extends HTMLAttributes<HTMLDivElement> {
  stones: MasonryStone[];
  gutter: number;
  columnBreakPoints: Record<number, number>;
  rows: number;
  stoneHeight: number;
}

const normalizeRect = ({ width, height, ...rect }: DOMRect): DOMRect => ({
  width: Math.floor(width),
  height: Math.floor(height),
  ...rect,
});

export const MasonryLayout = ({
  stones,
  gutter,
  columnBreakPoints,
  rows,
  stoneHeight,
}: MasonryLayoutProps): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const stoneRefs = useRef<HTMLElement[]>([]);
  const redispatchEventflag = useRef<boolean>(true);

  const childrenWithRef = useMemo<ReactNode>(() => {
    return stones.map(({ element, columns: blockColmuns, rows: blockRows }, i) => {
      if (element && isValidElement(element)) {
        return cloneElement(element, {
          ...element.props,
          style: {
            ...element.props.style,
            ...getCellStyle(
              blockColmuns,
              blockRows,
              columnBreakPoints,
              rows,
              gutter,
              wrapperRef.current?.offsetWidth ?? 0,
              stoneHeight,
            ),
          },
          ref: (ref: HTMLElement) => {
            stoneRefs.current[i] = ref;
          },
        });
      }
      return element;
    });
  }, [stones, wrapperRef.current?.offsetWidth]);

  const getStonePositions = useCallback(
    (
      stoneRefs: HTMLElement[],
      wrapperRef: HTMLElement,
      gutter: number,
    ): { stonePositions: CSSProperties[]; wrapperHeight: number } => {
      const stonePositions: CSSProperties[] = [];

      stoneRefs.forEach((stone) => {
        const { width, height } = normalizeRect(stone.getBoundingClientRect());
        stonePositions.push(
          findBestPosition(stonePositions, wrapperRef.offsetWidth, gutter, Math.floor(width), Math.floor(height)),
        );
      });

      const wrapperHeight = Math.max(...stonePositions.map((s) => +(s.bottom ?? 0) + gutter), 0);

      return {
        stonePositions,
        wrapperHeight,
      };
    },
    [gutter],
  );

  const [stonePositions, setStonePositions] = useState<{ stonePositions: CSSProperties[]; wrapperHeight: number }>({
    stonePositions: [],
    wrapperHeight: 0,
  });

  useEffect(() => {
    if (childrenWithRef && stoneRefs.current.length && wrapperRef.current) {
      const wrapper = wrapperRef.current;
      const stones = stoneRefs.current.filter((stone) => stone);

      const resizeEventHandler = debounce(() => {
        setStonePositions(getStonePositions(stones, wrapper, gutter));
        if (redispatchEventflag.current) {
          window.dispatchEvent(new Event('resize'));
        }
        redispatchEventflag.current = !redispatchEventflag.current;
      }, 500);

      window.addEventListener('resize', resizeEventHandler);
      window.dispatchEvent(new Event('resize'));

      return () => {
        window.removeEventListener('resize', resizeEventHandler);
      };
    }
  }, [childrenWithRef, getStonePositions, gutter, stoneRefs.current, wrapperRef.current]);

  return (
    <div ref={wrapperRef} style={{ position: 'relative', height: stonePositions.wrapperHeight, width: '100%' }}>
      {Children.map(childrenWithRef, (child, i) => {
        if (child && isValidElement(child)) {
          const style: CSSProperties = {
            ...child.props.style,
            ...stonePositions.stonePositions[i],
            position: 'absolute',
            transitionProperty: 'top left',
            transitionDuration: '400ms',
            transitionTimingFunction: 'ease-out',
          };

          return cloneElement(child, {
            ...child.props,
            style,
            ref: (ref: HTMLElement) => {
              stoneRefs.current[i] = ref;
            },
          });
        }
        return child;
      })}
    </div>
  );
};
