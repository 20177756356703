import { type DonutTableData } from '@state/block/models/donutTableData';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_FacilitiesByType } from './../services';
import lodash from 'lodash';
import { getColor } from '@shared/utils';

export const mapFacilitiesByType = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_FacilitiesByType[],
): DonutTableData[] => {
  const dataGrouped = lodash.groupBy(response, (item) => item.country);
  const countries = Object.keys(dataGrouped);

  return [
    {
      name: '',
      data:
        countries.length > 0
          ? countries.map((item) => ({
              group: item,
              data: dataGrouped[item].map((itemMetaData, index) => ({
                category: lodash.startCase(itemMetaData.typeName),
                value: itemMetaData.facilitiesCount,
                color: getColor(index),
              })),
            }))
          : [{ group: 'facilities', data: [{ category: '', value: 0, color: '' }] }],
    },
  ];
};
