import { type MultipleBlockMetadata } from '@state/block/models/blockMetadata';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByProvider } from './../services';
import { groupBy } from 'lodash';

export const mapPostsByProviderToBarChart = (
  response: Telefonica_DGSI_Dashboard_Domain_Services_ArenaService_Responses_PostsByProvider[],
): MultipleBlockMetadata[] => {
  const dataGroupedByProvider = groupBy(response, (item) => item.providerName);
  const dataGroupedByNature = groupBy(response, (item) => item.postNatureName);
  const natures = Object.keys(dataGroupedByNature);
  const providers = Object.keys(dataGroupedByProvider);

  const allData = {
    name: 'All',
    data: providers.map((provider) => ({
      name: provider,
      value: dataGroupedByProvider[provider].reduce((prev, current) => (prev += current.postsCount), 0),
    })),
  };

  return [
    {
      option: 'Single',
      data: [
        allData,
        ...natures.map((nature) => ({
          name: nature,
          data: dataGroupedByNature[nature].map((item) => ({ name: item.providerName, value: item.postsCount })),
        })),
      ],
    },
  ];
};
