import { type SparkLineBlockData } from '@state/block/models/sparkLineData';
import { formatDate } from '../../../utils';
import { type TotalSessionsModel } from '../models/totalSessionsModel';
import { type Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_SessionsByTimeResponse } from '../services';

export const mapTotalSessionsToSparkline = (totalSessions: TotalSessionsModel): SparkLineBlockData[] => {
  return [
    {
      option: '',
      categories: totalSessions.dates.map((d) => formatDate(d, false)),
      metaData: [
        {
          country: 'Sessions',
          color: 'white',
          data: totalSessions.sessions.map((s) => ({ value: s.toString(), code: null })),
        },
      ],
    },
  ];
};

export const mapTotalSessions = (
  totalSessions: Telefonica_DGSI_Dashboard_Domain_Services_ApplicationInsightsService_Responses_SessionsByTimeResponse,
): TotalSessionsModel => {
  return { dates: totalSessions.dates.map((item) => new Date(item)), sessions: totalSessions.sessions };
};
