import { getAppInsights } from '@core/services/telemetryService';
import { ERRORS } from '@state/constants';
import axios, { type AxiosInstance } from 'axios';
import { showInfoMessage } from './../../utils/toastMessage';

export class AxiosClient {
  public instance: AxiosInstance;

  constructor(apiUrl: string) {
    this.instance = axios.create({
      baseURL: apiUrl,
    });
    this.registerInterceptors();
  }

  private get token(): string {
    return sessionStorage.getItem('token') ?? '';
  }

  private async registerInterceptors(): Promise<void> {
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers.Authorization = this.token;
        return config;
      },
      async (error) => {
        return await Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      async (config) => {
        return config;
      },
      async (error) => {
        const appInsights = getAppInsights();
        appInsights.trackException({ error });

        if (!error.response) {
          return false;
        }

        if (error.response.status === 404 || error.response.status === 400) {
          showInfoMessage(error.response.data.detail);
        }

        if (error.response.status === 401) {
          showInfoMessage(error.response.data.detail);
        }

        if (error.response.status === 500) {
          showInfoMessage(ERRORS[500].message);
        }

        if (error.response.status === 403) {
          showInfoMessage(ERRORS[403].message);
        }

        return await Promise.reject(error);
      },
    );
  }
}
