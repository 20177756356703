import { createStores } from '@state/definition';
import { useLocalObservable } from 'mobx-react-lite';
import { type FunctionComponent } from 'react';
import { storesContext } from '../contexts/storesContext';

interface IProps {
  children: React.ReactNode;
}

export const StoreProvider: FunctionComponent<IProps> = ({ children }) => {
  const store = useLocalObservable(createStores);
  return <storesContext.Provider value={store}>{children}</storesContext.Provider>;
};
