export class ChartType {
  id: number;
  name: string;
  image: string;

  constructor(id: number, name: string, image: string) {
    this.id = id;
    this.name = name;
    this.image = image;
  }

  static sparkline(): ChartType {
    return new ChartType(1, 'SparkLine', 'sparkline');
  }

  static multipleBar(): ChartType {
    return new ChartType(2, 'Multiple Bar Chart', 'bars');
  }

  static bubble(): ChartType {
    return new ChartType(3, 'Map Bubble', 'bubble');
  }

  static heat(): ChartType {
    return new ChartType(4, 'Map heat', 'map');
  }

  static generalRow(): ChartType {
    return new ChartType(5, 'General Row', 'general-row');
  }

  static tableReport(): ChartType {
    return new ChartType(6, 'Table Report', 'table');
  }

  static tableRisks(): ChartType {
    return new ChartType(7, 'Table Risks', 'map');
  }

  static barChart(): ChartType {
    return new ChartType(8, 'Bar Chart', 'bars-percentage');
  }

  static featuredRoute(): ChartType {
    return new ChartType(9, 'FeaturedRoute Chart', 'featured-route');
  }

  static donutChart(): ChartType {
    return new ChartType(10, 'Donut Chart', 'donut');
  }

  static worldMap(): ChartType {
    return new ChartType(11, 'World Map Chart', 'map');
  }

  static barPercentage(): ChartType {
    return new ChartType(12, 'Bar Percentage', 'bars-percentage');
  }

  static verticalBarChart(): ChartType {
    return new ChartType(13, 'Vertical Bar Chart', 'vertical-bars');
  }

  static complexChart(): ChartType {
    return new ChartType(14, 'Donut Chart', 'donut');
  }

  static alphanumericChart(): ChartType {
    return new ChartType(15, 'Alphanumeric Chart', 'single-values');
  }

  static getList(): ChartType[] {
    return [
      this.sparkline(),
      this.multipleBar(),
      this.bubble(),
      this.heat(),
      this.generalRow(),
      this.tableReport(),
      this.tableRisks(),
      this.barChart(),
      this.featuredRoute(),
      this.donutChart(),
      this.worldMap(),
      this.barPercentage(),
      this.verticalBarChart(),
      this.complexChart(),
      this.alphanumericChart(),
    ];
  }

  static getById(id: number): ChartType | undefined {
    return this.getList().find((c) => c.id === id);
  }
}
