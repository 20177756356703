import worldmap from '@highcharts/map-collection/custom/world.geo.json';
import { ChartType } from '@state/block/models/chartType';
import type Highcharts from 'highcharts';
import { type FunctionComponent } from 'react';
import { BubbleMap } from './BubbleMap';
import { HeatMap } from './HeatMap';
interface Props {
  data: Any;
  chartType: ChartType;
}

export const MapChart: FunctionComponent<Props> = ({ data, chartType }) => {
  const options: Highcharts.Options = {
    chart: {
      borderWidth: 0,
      map: worldmap,
      backgroundColor: 'transparent',
    },
    legend: {
      enabled: false,
    },
    title: undefined,
    credits: { enabled: false },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        theme: {
          fill: '#747c85',
          color: '#FFFFFF',
          'stroke-width': 0,
          r: 0,
          states: {
            hover: {
              fill: '#747c85',
              color: '#FFFFFF',
            },
          },
        },
        verticalAlign: 'top',
        align: 'right',
      },
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: 'transparent',
          lineWidth: 2,
        },
      },
    },
    exporting: { enabled: false },
  };

  const Chart = chartType.id === ChartType.heat().id ? HeatMap : BubbleMap;

  return <div className="global-chart-wrapper">{<Chart data={data} options={options} />}</div>;
};
