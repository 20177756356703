import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { type FunctionComponent } from 'react';
import { DBButton } from '../button/button';
import { Icon } from '../Icon/Icon';
import './ModalDialog.scss';

export interface ModalProps {
  title: string;
  message: string;
  icon?: string;
  onCancel?: () => void;
  onAccept?: () => void;
  visible: boolean;
  cancelText: string;
  acceptText: string;
}
export const ModalDialog: FunctionComponent<ModalProps> = (props: ModalProps) => {
  return props.visible ? (
    <div className="db-modal-dialog">
      <Dialog>
        <div className="db-modal-dialog__header">
          <Icon iconName="close" onClick={props.onCancel}></Icon>
        </div>
        <div className="db-modal-dialog__icon-wrapper">
          {props.icon && <div className={`db-modal-dialog__icon ${props.icon}`}></div>}
        </div>
        <span className="db-modal-dialog__title">{props.title}</span>
        <p className="db-modal-dialog__description">{props.message} </p>
        <DialogActionsBar>
          <DBButton name="simple" type="button" onClick={props.onCancel} text={props.cancelText}></DBButton>
          <DBButton name="primary" type="button" text={props.acceptText} onClick={props.onAccept}></DBButton>
        </DialogActionsBar>
      </Dialog>
    </div>
  ) : null;
};
