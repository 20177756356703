export const formatRoleWithPrefix = (permission: string): string => {
  const regex = /^(MOD-|MOD_|MOD|GEO-|GEO_|GEO|CAG-|CAG_|CAG)/;

  return permission.replace(regex, '');
};

const colors: string[] = [
  '#641E16',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#009688',
  '#81C784',
  '#FBC02D',
  '#FF7043',
  '#8D6E63',
  '#BDBDBD',
  '#AD842D',
  '#633974',
  '#2E86C1',
  '#138D75',
  '#B7950B',
];

export const getColor = (index: number): string => {
  let currentIndex = index;
  while (currentIndex > colors.length) {
    currentIndex = currentIndex - colors.length + 1;
  }
  return colors[currentIndex];
};

export const getAllColors = (): string[] => colors;

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const tableReportsColumnTitles = {
  country: 'Country',
  total: 'Total',
  flightsPercentage: 'Flights Percentage',
  passengers: 'Passengers',
  flights: 'Flights',
  nationals: 'Nationals',
  internationals: 'Internationals',
};

export const GetUTCDate = (date: Date): Date => {
  const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

  return new Date(utcDate);
};

export const mapTimeSpan = (hours: number, minutes: number, seconds: number): string =>
  new Intl.DateTimeFormat('es-ES', { hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(
    new Date(0, 0, 0, hours, minutes, seconds),
  );
