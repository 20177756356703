import { LoaderSmall } from '@components/shared/LoaderSmall/LoaderSmall';
import { sidePanelContext } from '@contexts/sidePanelContext';
import { AppConfiguration } from '@core/config/appConfiguration';
import { ApplicationIds } from '@core/services/models/application';
import { useDashboard } from '@hooks/useDashboald/useDashboard';
import { useStores } from '@hooks/useStores/useStores';
import { useDashboardContext } from '@state/dashboard/context/DasboardContext';
import { useContext, useEffect } from 'react';
import { MetricWidget } from '../MetricWidget/MetricWidget';

export interface DatacampusMetrics {
  usersArca: number;
  usersArena: number;
  usersJourney: number;
  taskcount: number;
  alertCount: number;
}

export const DatacampusMetricsGroup = (): JSX.Element => {
  const { getDatacampusData } = useDashboard();
  const { applicationsData } = useDashboardContext();
  const datacampusData = applicationsData[ApplicationIds.DataCampus];
  const { tasksStore } = useStores();
  const arcaUrl = AppConfiguration.getInstance().settings.apps.arcaUrl;
  const sidePanel = useContext(sidePanelContext);

  useEffect(() => {
    if (!applicationsData[ApplicationIds.DataCampus]) {
      getDatacampusData();
    }
  }, [getDatacampusData]);

  return datacampusData ? (
    <>
      <MetricWidget
        value={datacampusData?.data?.usersArca ?? 0}
        description="Total users ARCA"
        link={`${arcaUrl}/#/Admin/Users`}
      />
      <MetricWidget
        value={datacampusData?.data?.usersJourney ?? 0}
        description="Total users JOURNEY"
        link={`${arcaUrl}/#/Admin/Users`}
      />
      <MetricWidget
        value={datacampusData?.data?.usersArena ?? 0}
        description="Total users ARENA"
        link={`${arcaUrl}/#/Admin/Users`}
      />
      <MetricWidget value={tasksStore.taskCount} description="Tasks" actionClick={sidePanel?.toggleMyProfile} />
      <MetricWidget
        value={tasksStore.alertCount}
        description="Alerts"
        actionClick={sidePanel?.toggleAlertsAndNotifications}
      />
    </>
  ) : (
    <LoaderSmall />
  );
};
