import { LoaderSmall } from '@components/shared/LoaderSmall/LoaderSmall';
import { AppConfiguration } from '@core/config/appConfiguration';
import { ApplicationIds } from '@core/services/models/application';
import { useDashboard } from '@hooks/useDashboald/useDashboard';
import { useDashboardContext } from '@state/dashboard/context/DasboardContext';
import { useEffect } from 'react';
import { FlightWidget } from '../MetricWidget/FlightWidget';
import { MetricWidget } from '../MetricWidget/MetricWidget';
import './JourneyMetricsGroup.scss';

export const JourneyMetricsGroup = (): JSX.Element => {
  const { getJoureyData } = useDashboard();
  const { applicationsData } = useDashboardContext();
  const journeyData = applicationsData[ApplicationIds.Journey];
  const journeyUrl = AppConfiguration.getInstance().settings.apps.journeyUrl;

  useEffect(() => {
    if (!applicationsData[ApplicationIds.DataCampus]) {
      getJoureyData();
    }
  }, [getJoureyData]);

  return journeyData ? (
    <>
      <MetricWidget value={journeyData?.data.status.totalFlights ?? 0} description="Total trips" link={journeyUrl} />
      <MetricWidget value={journeyData?.data.status.totalPassengers ?? 0} description="Total PAX" link={journeyUrl} />
      <MetricWidget
        value={`${journeyData?.data.status.flightsFromRiskCountries ?? 0} / ${
          journeyData?.data.status.flightsToRiskCountries ?? 0
        }`}
        description="PAX from/to risky countries"
        link={`${journeyUrl}/#/risks`}
      />
      <MetricWidget
        value={journeyData?.data.alertsCount.alerts ?? 0}
        description="Alerts"
        link={`${journeyUrl}/#/alerts`}
      />
      <FlightWidget
        passengers={journeyData?.data.frequentRoute.passengers ?? 0}
        route={journeyData?.data.frequentRoute.featuredRoute ?? '-'}
        description="Most frequent route (passengers)"
      />
    </>
  ) : (
    <LoaderSmall />
  );
};
