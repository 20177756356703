import { InputToogle } from '@components/form/toogle/toogle';
import { GoBackHeader } from '@components/shared/GoBackHeader/GoBackHeader';
import { type Application } from '@state/permissionsManagement/models/applicacion';
import { type Role } from '@state/permissionsManagement/models/role';
import { type Subject } from '@state/permissionsManagement/models/subject';
import { usePermissionsManagement } from '@state/permissionsManagement/usePermissionsManagement';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './PermissionsManagement.scss';

type UserMapagementParams = 'appId' | 'userSub';

export const PermissionsManagement: FunctionComponent = () => {
  const { appId, userSub } = useParams<UserMapagementParams>();
  const [app, setApp] = useState<Application>();
  const [user, setUser] = useState<Subject>();
  const [roles, setRoles] = useState<Role[]>([]);

  const { getRoles, getApp, getUsers, deleteRole, addRole } = usePermissionsManagement();

  useEffect(() => {
    if (appId) {
      getApp(+appId).then((app) => {
        setApp(app);
      });
    }
  }, [appId]);

  useEffect(() => {
    if (userSub && appId) {
      getUsers(+appId).then((users) => {
        setUser(users.find((user) => user.sub === userSub));
      });
    }
  }, [userSub]);

  useEffect(() => {
    if (app && userSub) {
      getRoles(app.name, userSub).then((roles) => {
        setRoles(roles);
      });
    }
  }, [app]);

  const toogleRole = async (roleName: string, selected: boolean): Promise<void> => {
    if (!app || !user) return;

    selected
      ? await deleteRole(app?.name, user?.sub, roleName, roles).then(setRoles)
      : await addRole(app?.name, user?.sub, roleName, roles).then(setRoles);
  };

  return (
    <>
      <GoBackHeader title={`${app?.name ?? ''} users`} goBackLink={true} />
      {user && (
        <div className="user-selected">
          <div className="user-selected__row">
            <div className="user-selected__cell">
              <div className="user-selected__cell-img-container">
                {user.imageUrl ? (
                  <div
                    className="user-selected__cell-img-uploaded"
                    style={{ backgroundImage: 'url(' + user.imageUrl + ')' }}
                  ></div>
                ) : (
                  <div className="user-selected__cell-img"></div>
                )}
              </div>
              <p>{user.name}</p>
            </div>
            <div className="user-selected__cell">
              <div className="user-selected__separator"></div>
            </div>
            <div className="user-selected__cell">
              <p className="user-selected__cell-text">{user.email}</p>
            </div>
          </div>
        </div>
      )}
      <ul className="user-selected__list">
        <li className="user-selected__list-header">
          <div className="user-selected__list-header-cell">Role name</div>
          <div className="user-selected__list-header-cell description">Description</div>
          <div className="user-selected__list-header-cell">Status</div>
        </li>
        {roles
          .filter(
            (item) =>
              item.canEdit && !item.name.toUpperCase().startsWith('GEO') && !item.name.toUpperCase().startsWith('MOD'),
          )
          .map((item) => {
            return (
              <li className="user-selected__list-row" key={`app_${item.id}`}>
                <div className="user-selected__list-row-cell">
                  <p className={'name'}>{item.name}</p>
                </div>
                <div className="user-selected__list-row-cell description">{item.description}</div>
                <div className="user-selected__list-row-cell">
                  <InputToogle
                    value={item.selected ? 1 : undefined}
                    onChange={() => {
                      toogleRole(item.name, item.selected);
                    }}
                  />
                </div>
              </li>
            );
          })}
        {roles
          .filter((item) => item.canEdit && item.name.toUpperCase().startsWith('GEO'))
          .map((item) => {
            return (
              <li className="user-selected__list-row" key={`app_${item.id}`}>
                <div className="user-selected__list-row-cell">
                  <p className={'name'}>{item.name}</p>
                </div>
                <div className="user-selected__list-row-cell description">{item.description}</div>
                <div className="user-selected__list-row-cell">
                  <InputToogle
                    value={item.selected ? 1 : undefined}
                    onChange={() => {
                      toogleRole(item.name, item.selected);
                    }}
                  />
                </div>
              </li>
            );
          })}
        {roles
          .filter((item) => item.canEdit && item.name.toUpperCase().startsWith('MOD'))
          .map((item) => {
            return (
              <li className="user-selected__list-row" key={`app_${item.id}`}>
                <div className="user-selected__list-row-cell">
                  <p className={'name'}>{item.name}</p>
                </div>
                <div className="user-selected__list-row-cell description">{item.description}</div>
                <div className="user-selected__list-row-cell">
                  <InputToogle
                    value={item.selected ? 1 : undefined}
                    onChange={() => {
                      toogleRole(item.name, item.selected);
                    }}
                  />
                </div>
              </li>
            );
          })}
      </ul>
    </>
  );
};
