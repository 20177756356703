import { type BlockTableData } from '@state/block/models/blockTableData';
import { type FunctionComponent, useEffect, useState } from 'react';
import { type DataModel, FilterOptions, type Filters } from '../shared/filterOptions/FilterOptions';
import './tableReports.scss';

export interface Props {
  data: BlockTableData[];
}

const emptyPDonutData: BlockTableData = {
  columns: [],
  rows: [],
  option: '',
};

export const TableReports: FunctionComponent<Props> = ({ data }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<Filters>({ filter: '' });
  const [blockTableData, setBlockTableData] = useState<BlockTableData>(emptyPDonutData);
  const [filterData, setFilterData] = useState<DataModel[]>([]);

  useEffect(() => {
    setFilterData(data.map((d) => ({ name: d.option, value: d.option })));
    setSelectedFilter({ filter: data[0].option });
  }, [data]);

  useEffect(() => {
    const dataSelected = data.find((Item) => Item.option === selectedFilter.filter);
    if (dataSelected === undefined) return;
    setBlockTableData(dataSelected);
  }, [selectedFilter]);

  const onChange = (event: Filters): void => {
    setSelectedFilter({ filter: event.filter });
  };

  return blockTableData?.columns ? (
    <div className="dashboard-table-reports">
      <FilterOptions data={filterData} value={selectedFilter} onChange={onChange} />
      <div className="dashboard-table-reports__header">
        <div className="dashboard-table-reports__container">
          {blockTableData.columns.map((element, index) => (
            <span key={`indicator_${index}`}>{element}</span>
          ))}
        </div>
      </div>
      <div className="dashboard-table-reports__body">
        {blockTableData.rows.map((element, index) => (
          <div key={`indicator_${index}`} className="dashboard-table-reports__item">
            <div className="dashboard-table-reports__container">
              {element.values.map((item, index) => (
                <span key={`indicator_${index}`}>{item}</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
