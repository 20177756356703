import { Icon } from '@components/shared/Icon/Icon';
import React from 'react';
import './featuredRoute.scss';

interface IFeaturedRouteProps {
  from: string;
  to: string;
  users: number;
}

export const FeaturedRoute: React.FC = () => {
  const featuredRouteProp: IFeaturedRouteProps = {
    from: 'Madrid',
    to: 'Frankfurt',
    users: 97,
  };
  return (
    <div className="dashboard-featured-route">
      <span className="dashboard-featured-route__title">Featured route (and its passengers)</span>
      <a className="dashboard-featured-route__view-detail">View details</a>
      <div className="dashboard-featured-route__info">
        <Icon iconName="plane"></Icon>
        <span className="dashboard-featured-route__info-value">
          {featuredRouteProp.from} - {featuredRouteProp.to}
        </span>
        <Icon iconName="users"></Icon>
        <span className="dashboard-featured-route__info-value">({featuredRouteProp.users})</span>
      </div>
    </div>
  );
};
