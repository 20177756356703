import { InputTextbox } from '@components/form/input-text/input-text';
import { SidePanel } from '@components/shared/SidePanel/SidePanel';
import { useAuth } from '@hooks/useAuth/useAuth';
import { type Subject } from '@state/permissionsManagement/models/subject';
import { usePermissionsManagement } from '@state/permissionsManagement/usePermissionsManagement';
import { observer } from 'mobx-react-lite';
import { type ChangeEvent, type FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UsersAssignment.scss';

interface UsersAssigmentProps {
  onClose: () => void;
  appId: number;
}

export const UsersAssigmentSearch: FunctionComponent<UsersAssigmentProps> = observer(
  ({ onClose, appId }: UsersAssigmentProps) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [users, setUsers] = useState<Subject[]>([]);
    const [usersFiltered, setUsersFiltered] = useState<Subject[]>([]);
    const [sub, setSub] = useState<string>();
    const { getUsers } = usePermissionsManagement();

    useEffect(() => {
      auth.getUser().then((user) => {
        setSub(user?.profile.sub);
      }, auth.loginRedirect);
    }, []);

    useEffect(() => {
      getUsers(appId).then(setUsers);
    }, [appId]);

    useEffect(() => {
      setUsersFiltered(users);
    }, [users]);

    const onFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
      setUsersFiltered(users.filter((item) => item.name.toUpperCase().includes(event.target.value.toUpperCase())));
    };

    const userClick = (selectedSub: string): void => {
      if (sub !== selectedSub) {
        navigate(`/permissionsManagement/apps/${appId}/users/${selectedSub}/permissions`);
      }
    };

    return (
      <SidePanel
        title="Assign user"
        onClose={() => {
          onClose();
        }}
      >
        <>
          <div className="users-assignment-list">
            <InputTextbox onChange={onFilterChange} label="Search" name="title"></InputTextbox>
            <ul className="users-assignment-list__wrapper">
              {usersFiltered
                .filter((u) => u.roles.length === 0)
                .map((u) => (
                  <li
                    key={u.sub}
                    className="users-assignment-list__wrapper--row"
                    onClick={() => {
                      userClick(u.sub);
                    }}
                  >
                    {u.name}
                  </li>
                ))}
            </ul>
          </div>
        </>
      </SidePanel>
    );
  },
);
