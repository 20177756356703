import { type NotificationItem } from '@core/services/models/notification';
import { orderBy } from 'lodash';
import { type Telefonica_DGSI_Dashboard_Domain_Queries_Notifications_Response_NotificationResponse } from '../services';
export const NotificationMapper = {
  map(
    response: Telefonica_DGSI_Dashboard_Domain_Queries_Notifications_Response_NotificationResponse[],
  ): NotificationItem[] {
    const notifications: NotificationItem[] = response.map((item) => ({
      id: item.id,
      title: item.title,
      message: item.message,
      section: item.section,
      showDate: item.showDate,
      url: item.url,
      platform: item.platform,
      read: item.read,
      taskType: item.taskType,
      metaData: item.metaData,
      metaDataJson: item.metaDataJson,
    }));

    return orderBy(notifications, (item) => item.read);
  },
};
