import { type NotificationItem } from '@core/services/models/notification';
import { NotificationMapper } from './mappers/notificationMapper';
import { NotificationsClient } from './services';
export class NotificationsService {
  async getAll(): Promise<NotificationItem[]> {
    const content = await notificationsClient.notifications();

    return NotificationMapper.map(content);
  }

  async markAsRead(id: number): Promise<void> {
    await notificationsClient.read({ id });
  }

  async markAllAsRead(): Promise<void> {
    await notificationsClient.allread();
  }

  async delete(id: number): Promise<void> {
    await notificationsClient.delete({ id });
  }
}

const notificationsClient = new NotificationsClient();
export const notificationService = new NotificationsService();
