import { type RssResponse } from '@core/services/models/newsItemModel';
import { rssServices } from '@core/services/rssServices';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from './Carousel/Carousel';
import './News.scss';
import { RssForm } from './RssForm/RssForm';

export const News: FunctionComponent = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'News' });
  const [rssList, setRssList] = useState<RssResponse[]>([]);
  const [rssFormOpen, setrssFormOpen] = useState<boolean>(false);
  const [newRssResponseToEdit, setRssResponseModelToEdit] = useState<RssResponse | null>(null);

  useEffect(() => {
    loadnewsItems();
  }, []);

  const loadnewsItems = async (): Promise<void> => {
    const rssList = await rssServices.getAll();
    setRssList(rssList);
  };

  const onCloseRssForm = (): void => {
    setrssFormOpen(false);
    setRssResponseModelToEdit(null);
  };

  const onClickConfiguration = (rssResponse: RssResponse): void => {
    setRssResponseModelToEdit(null);
    setRssResponseModelToEdit(rssResponse);
    setrssFormOpen(true);
  };

  return (
    <>
      <section className="news__body">
        <div className="news__actions-row">
          <div className="news__actions-row--container">
            <span className="news__title">{t('NewsCenter')}</span>
          </div>
        </div>
        {rssList.map((item, index) => {
          return <Carousel key={index} rss={item} onClickConfiguration={onClickConfiguration}></Carousel>;
        })}
        {rssFormOpen ? (
          <RssForm
            rss={newRssResponseToEdit}
            onSave={() => {
              onCloseRssForm();
              loadnewsItems();
            }}
            onClose={onCloseRssForm}
          />
        ) : null}
      </section>
    </>
  );
};
