import { type Role } from '../../../state/permissionsManagement/models/role';
import { type Telefonica_DGSI_Dashboard_Application_Applications_GetRoles_RoleDto } from '../services';
export const RolesMapper = {
  map(response: Telefonica_DGSI_Dashboard_Application_Applications_GetRoles_RoleDto[]): Role[] {
    return response.map<Role>((item) => {
      return {
        id: item.id,
        name: item.name,
        selected: item.selected,
        description: item.description,
        enabled: item.enabled,
        canEdit: item.canEdit,
      };
    });
  },
};
