import lodash from 'lodash';
import { mapRss } from './mappers/newsItemMapper';
import { mapUpdateRssModel } from './mappers/rssMapper';
import { type RssResponse } from './models/newsItemModel';
import { type UpdateRssModel } from './models/updateRssModel';
import { RssClient } from './services';
export class RssService {
  async getAll(): Promise<RssResponse[]> {
    const data = await rssClient.rss();
    return lodash.orderBy(data, (r) => r.order).map(mapRss);
  }

  async update(updateRssModel: UpdateRssModel): Promise<void> {
    await rssClient.update({
      body: mapUpdateRssModel(updateRssModel),
    });
  }
}

const rssClient = new RssClient();
export const rssServices = new RssService();
