import { Icon } from '@components/shared/Icon/Icon';
import { type RssResponse } from '@core/services/models/newsItemModel';
import { RssType } from '@state/block/models/rssType';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useTranslation } from 'react-i18next';
import { NewsItem } from '../NewsSection/NewsItem';
import './Carousel.scss';

export interface AliceCarouselProps {
  rss: RssResponse;
  onClickConfiguration: (newsItemModel: RssResponse) => void;
}

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const responsiveSmall = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

export const Carousel: React.FC<AliceCarouselProps> = ({ rss, onClickConfiguration }) => {
  const { t } = useTranslation('translation');

  const getCarouselItems = () =>
    rss.newsItemResponse.map((item, index) => (
      <NewsItem newsItem={item} sizeId={rss.rssSizeId} type={RssType.getById(rss.rssTypeId)} key={index} />
    ));
  return (
    <div className={`db-carousel ${rss.rssSize}`}>
      <div className="db-carousel__header">
        <span className="db-carousel__title">{t(`Rss.${RssType.getById(rss.rssTypeId).name}`)}</span>
        <Icon
          iconName="config"
          onClick={() => {
            onClickConfiguration(rss);
          }}
        ></Icon>
      </div>
      <AliceCarousel
        autoPlay={true}
        autoPlayInterval={rss.autoPlayInterval}
        disableDotsControls={true}
        mouseTracking
        items={getCarouselItems()}
        responsive={rss.rssSize === 'small' ? responsiveSmall : responsive}
        controlsStrategy="alternate"
        infinite={true}
      />
    </div>
  );
};
