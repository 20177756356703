import {
  HomeClient,
  type Telefonica_DGSI_Dashboard_Application_Home_Model_DataCampusInfo,
  type Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGeneralStatusTodayResponse,
  type Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGetAllAlertsResponse,
  type Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentMostFrequentRouteResponse,
  type Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentWorkstreamKpiKpmResponse,
} from './services';

export type GeneralStatusToday =
  Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGeneralStatusTodayResponse;
export type GetAllAlerts =
  Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentGetAllAlertsResponse;
export type MostFrequentRoute =
  Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentMostFrequentRouteResponse;
export type WorkstreamKpiKpm =
  Telefonica_DGSI_Dashboard_Domain_Services_JourneyService_Responses_ContentWorkstreamKpiKpmResponse;
export type DataCampusInfo = Telefonica_DGSI_Dashboard_Application_Home_Model_DataCampusInfo;

export class HomeService {
  async generalStatusToday(): Promise<GeneralStatusToday> {
    const response = await homeClient.generalstatustoday();
    return {
      flightsFromRiskCountries: response.flightsFromRiskCountries,
      flightsToRiskCountries: response.flightsToRiskCountries,
      totalFlights: response.totalFlights,
      totalPassengers: response.totalPassengers,
    };
  }

  async getAllAlerts(): Promise<GetAllAlerts> {
    const response = await homeClient.getallalerts();
    return {
      alerts: response.alerts,
    };
  }

  async mostFrequentRoute(): Promise<MostFrequentRoute> {
    const response = await homeClient.mostfrequentroute();
    return {
      featuredRoute: response.featuredRoute,
      passengers: response.passengers,
    };
  }

  async workstreamkpikpm(): Promise<WorkstreamKpiKpm> {
    const response = await homeClient.workstreamkpikpm();
    return {
      workstreams: response.workstreams,
      kpis: response.kpis,
      kpms: response.kpms,
      pendingKpmForm: response.pendingKpmForm,
      endOfTimeKpmForm: response.endOfTimeKpmForm,
    };
  }

  async getInfo(): Promise<DataCampusInfo> {
    const response = await homeClient.info();
    return response;
  }
}

const homeClient = new HomeClient();
export const homeService = new HomeService();
